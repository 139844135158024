import React from "react";

export const EditIcon = ({
  width = 27.403,
  height = 22.686,
  fill = "#8b5cf1",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.403 22.686"
    >
      <g
        id="Group_42154"
        data-name="Group 42154"
        transform="translate(9330.403 14363.001)"
      >
        <path
          id="gears-solid"
          d="M7.639,11.16a.807.807,0,0,0,.916.227,6.265,6.265,0,0,0,.568-.278L9.32,11c.183-.11.363-.231.535-.359a.8.8,0,0,0,.26-.9L9.774,8.7a4.641,4.641,0,0,0,.765-1.326l1.066-.223a.8.8,0,0,0,.652-.677,6.372,6.372,0,0,0,0-1.494.8.8,0,0,0-.652-.678L10.54,4.074a4.677,4.677,0,0,0-.765-1.326l.341-1.033a.8.8,0,0,0-.26-.9C9.683.682,9.5.562,9.32.448L9.126.338A5.889,5.889,0,0,0,8.555.06a.81.81,0,0,0-.916.227L6.914,1.1a4.736,4.736,0,0,0-1.531,0L4.658.287A.807.807,0,0,0,3.743.06c-.194.084-.385.176-.571.278l-.19.11a5.273,5.273,0,0,0-.535.363.8.8,0,0,0-.26.9l.341,1.033a4.641,4.641,0,0,0-.765,1.326L.7,4.293a.8.8,0,0,0-.652.677,6.372,6.372,0,0,0,0,1.494A.8.8,0,0,0,.7,7.142l1.066.223a4.677,4.677,0,0,0,.765,1.326L2.186,9.724a.8.8,0,0,0,.26.9,5.92,5.92,0,0,0,.535.359l.2.114a6.266,6.266,0,0,0,.568.278.81.81,0,0,0,.916-.227l.725-.813a4.736,4.736,0,0,0,1.531,0l.725.813Zm-1.491-7.2A1.758,1.758,0,1,1,4.391,5.722,1.758,1.758,0,0,1,6.149,3.964Z"
          transform="matrix(-0.53, -0.848, 0.848, -0.53, -9312.707, -14340.314)"
          fill={fill}
        />
        <path
          id="gears-solid-2"
          data-name="gears-solid"
          d="M11.164,4.662a.807.807,0,0,0,.227-.916,6.265,6.265,0,0,0-.278-.568L11,2.981c-.11-.183-.231-.363-.359-.535a.8.8,0,0,0-.9-.26L8.7,2.527a4.641,4.641,0,0,0-1.326-.765L7.154.7A.8.8,0,0,0,6.476.048,5.442,5.442,0,0,0,5.74,0H5.714a6.112,6.112,0,0,0-.736.044A.8.8,0,0,0,4.3.7L4.077,1.762a4.677,4.677,0,0,0-1.326.765L1.715,2.19a.8.8,0,0,0-.9.26c-.128.172-.249.352-.363.535l-.11.194A5.888,5.888,0,0,0,.06,3.75a.81.81,0,0,0,.227.916l.813.725a4.717,4.717,0,0,0-.062.762,4.8,4.8,0,0,0,.062.765l-.813.725a.807.807,0,0,0-.227.916c.084.194.176.385.278.571l.11.19a5.273,5.273,0,0,0,.363.535.8.8,0,0,0,.9.26l1.033-.341a4.641,4.641,0,0,0,1.326.765L4.3,11.605a.8.8,0,0,0,.677.652,6.372,6.372,0,0,0,1.494,0,.8.8,0,0,0,.678-.652L7.37,10.54A4.677,4.677,0,0,0,8.7,9.774l1.033.341a.8.8,0,0,0,.9-.26,5.92,5.92,0,0,0,.359-.535l.114-.2a6.266,6.266,0,0,0,.278-.568.81.81,0,0,0-.227-.916l-.813-.725a4.736,4.736,0,0,0,0-1.531l.813-.725Zm-7.2,1.491A1.758,1.758,0,1,1,5.725,7.91,1.758,1.758,0,0,1,3.967,6.152Z"
          transform="matrix(-0.485, 0.875, -0.875, -0.485, -9314.093, -14357.037)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
