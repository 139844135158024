import React from "react";

export const INTIcon = ({
  width = 14.563,
  height = 14.563,
  fill = "#fff",
  ...rest
}) => {
  return (
    <svg
      id="INT"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14.563 14.563"
    >
      <path
        id="Path_12974"
        data-name="Path 12974"
        d="M7.281,14.562a7.281,7.281,0,1,1,7.281-7.281,7.289,7.289,0,0,1-7.281,7.281m0-14.486a7.2,7.2,0,1,0,7.2,7.2,7.213,7.213,0,0,0-7.2-7.2"
        transform="translate(0 0)"
        fill={fill}
      />
      <path
        id="Path_12975"
        data-name="Path 12975"
        d="M15.6,22.19a6.6,6.6,0,1,1,6.6-6.6,6.6,6.6,0,0,1-6.6,6.6m0-13.114A6.519,6.519,0,1,0,22.114,15.6,6.526,6.526,0,0,0,15.6,9.076"
        transform="translate(-8.314 -8.314)"
        fill={fill}
      />
      <path
        id="Path_12976"
        data-name="Path 12976"
        d="M33.608,38.717a5.108,5.108,0,1,1,5.108-5.108,5.114,5.114,0,0,1-5.108,5.108m0-10.14a5.032,5.032,0,1,0,5.032,5.032,5.038,5.038,0,0,0-5.032-5.032"
        transform="translate(-26.327 -26.327)"
        fill={fill}
      />
      <path
        id="Path_12977"
        data-name="Path 12977"
        d="M40.075,44.649a4.575,4.575,0,1,1,4.575-4.575,4.58,4.58,0,0,1-4.575,4.575m0-9.073a4.5,4.5,0,1,0,4.5,4.5,4.5,4.5,0,0,0-4.5-4.5"
        transform="translate(-32.793 -32.794)"
        fill={fill}
      />
      <path
        id="Path_12978"
        data-name="Path 12978"
        d="M78.146,13.684,77.6,13.1l.268-.752.543.585Z"
        transform="translate(-71.686 -11.405)"
        fill={fill}
      />
      <path
        id="Path_12979"
        data-name="Path 12979"
        d="M31.941,42.442l-.793-.095-.283-.747.793.095Z"
        transform="translate(-28.512 -38.43)"
        fill={fill}
      />
      <path
        id="Path_12980"
        data-name="Path 12980"
        d="M17.609,65.473l-.769.215-.546-.583.769-.215Z"
        transform="translate(-15.051 -59.943)"
        fill={fill}
      />
      <path
        id="Path_12981"
        data-name="Path 12981"
        d="M17.792,116.63l-.52.606-.778-.18.52-.606Z"
        transform="translate(-15.236 -107.571)"
        fill={fill}
      />
      <path
        id="Path_12982"
        data-name="Path 12982"
        d="M31.712,140.309l-.33.727-.8.044.33-.727Z"
        transform="translate(-28.253 -129.613)"
        fill={fill}
      />
      <path
        id="Path_12983"
        data-name="Path 12983"
        d="M78.477,163.184l.211.77-.586.543-.211-.771Z"
        transform="translate(-71.954 -150.743)"
        fill={fill}
      />
      <path
        id="Path_12984"
        data-name="Path 12984"
        d="M104.656,13.684l.543-.586-.268-.752-.543.585Z"
        transform="translate(-96.43 -11.405)"
        fill={fill}
      />
      <path
        id="Path_12985"
        data-name="Path 12985"
        d="M147.651,42.442l.793-.095.283-.747-.793.095Z"
        transform="translate(-136.394 -38.43)"
        fill={fill}
      />
      <path
        id="Path_12986"
        data-name="Path 12986"
        d="M159.079,65.473l.769.215.546-.583-.769-.215Z"
        transform="translate(-146.95 -59.943)"
        fill={fill}
      />
      <path
        id="Path_12987"
        data-name="Path 12987"
        d="M159.109,116.63l.52.606.778-.18-.52-.606Z"
        transform="translate(-146.978 -107.571)"
        fill={fill}
      />
      <path
        id="Path_12988"
        data-name="Path 12988"
        d="M147.252,140.309l.33.727.8.044-.33-.727Z"
        transform="translate(-136.026 -129.613)"
        fill={fill}
      />
      <path
        id="Path_12989"
        data-name="Path 12989"
        d="M104.5,163.184l-.211.77.586.543.211-.771Z"
        transform="translate(-96.342 -150.743)"
        fill={fill}
      />
      <path
        id="Path_12996"
        data-name="Path 12996"
        d="M49.177,46.187l-3.884-2.243V39.46l.019-.011,3.865-2.232,3.884,2.243v4.485l-.019.011ZM45.369,43.9l3.808,2.2,3.808-2.2V39.5l-3.808-2.2-3.808,2.2Z"
        transform="translate(-41.84 -34.38)"
        fill={fill}
      />
      <path
        id="Path_12997"
        data-name="Path 12997"
        d="M51.446,58.921H47.59l-1.928-3.34,1.928-3.34h3.856l1.928,3.34Zm-3.812-.076H51.4l1.884-3.263L51.4,52.318H47.634L45.75,55.582Z"
        transform="translate(-42.181 -48.26)"
        fill={fill}
      />
      <path
        id="Path_12998"
        data-name="Path 12998"
        d="M52.023,43.624H44.068L48.046,36.8ZM44.2,43.548h7.69l-3.845-6.6Z"
        transform="translate(-40.709 -33.996)"
        fill={fill}
      />
      <path
        id="Path_12999"
        data-name="Path 12999"
        d="M48.046,72.617l-3.978-6.823h7.955ZM44.2,65.87l3.845,6.6,3.845-6.6Z"
        transform="translate(-40.709 -60.778)"
        fill={fill}
      />
      <path
        id="Path_13000"
        data-name="Path 13000"
        d="M45.681,66.255l-1.3-.062-.4-.912,1.043-.095Z"
        transform="translate(-40.628 -60.217)"
        fill={fill}
      />
      <path
        id="Path_13001"
        data-name="Path 13001"
        d="M88.865,37.93l-.617-1.151.579-.808.617.846Z"
        transform="translate(-81.52 -33.229)"
        fill={fill}
      />
      <path
        id="Path_13002"
        data-name="Path 13002"
        d="M45.681,112.97l-1.3.062-.4.912,1.043.095Z"
        transform="translate(-40.628 -104.357)"
        fill={fill}
      />
      <path
        id="Path_13003"
        data-name="Path 13003"
        d="M88.865,130.512l-.617,1.151.579.808.617-.846Z"
        transform="translate(-81.52 -120.562)"
        fill={fill}
      />
      <path
        id="Path_13004"
        data-name="Path 13004"
        d="M126.679,66.255l1.3-.062.4-.912-1.043-.095Z"
        transform="translate(-117.021 -60.217)"
        fill={fill}
      />
      <path
        id="Path_13005"
        data-name="Path 13005"
        d="M126.679,112.97l1.3.062.4.912-1.043.095Z"
        transform="translate(-117.021 -104.357)"
        fill={fill}
      />
      <path
        id="Path_13006"
        data-name="Path 13006"
        d="M68.988,71.042a2.249,2.249,0,1,1,2.249-2.249,2.252,2.252,0,0,1-2.249,2.249m0-4.422a2.173,2.173,0,1,0,2.173,2.173,2.175,2.175,0,0,0-2.173-2.173"
        transform="translate(-61.651 -61.471)"
        fill={fill}
      />
      <path
        id="Path_13007"
        data-name="Path 13007"
        d="M73.573,73.707a1.327,1.327,0,0,1-1.353-1.353,1.25,1.25,0,0,1,1.237-1.272,1.906,1.906,0,1,0,1.356,1.825c0-.017,0-.035,0-.052a1.353,1.353,0,0,1-1.238.853"
        transform="translate(-65.587 -65.587)"
        fill={fill}
      />
      <path
        id="Path_13008"
        data-name="Path 13008"
        d="M8.205,1a7.2,7.2,0,1,0,7.2,7.205A7.213,7.213,0,0,0,8.205,1m0,13.8a6.6,6.6,0,1,1,6.6-6.6,6.6,6.6,0,0,1-6.6,6.6"
        transform="translate(-0.924 -0.924)"
        fill={fill}
      />
      <path
        id="Path_13009"
        data-name="Path 13009"
        d="M34.532,29.5a5.032,5.032,0,1,0,5.032,5.032A5.038,5.038,0,0,0,34.532,29.5m0,9.607a4.575,4.575,0,1,1,4.575-4.575,4.58,4.58,0,0,1-4.575,4.575"
        transform="translate(-27.251 -27.251)"
        fill={fill}
      />
      <path
        id="Path_13010"
        data-name="Path 13010"
        d="M44.982,13.991A.991.991,0,1,1,43.991,13a.991.991,0,0,1,.991.991"
        transform="translate(-39.722 -12.009)"
        fill={fill}
        opacity="0.85"
      />
      <path
        id="Path_13011"
        data-name="Path 13011"
        d="M3.982,83.991A.991.991,0,1,1,2.991,83a.991.991,0,0,1,.991.991"
        transform="translate(-1.847 -76.671)"
        fill={fill}
        opacity="0.85"
      />
      <path
        id="Path_13012"
        data-name="Path 13012"
        d="M43.982,153.991a.991.991,0,1,1-.991-.991.991.991,0,0,1,.991.991"
        transform="translate(-38.798 -141.335)"
        fill={fill}
        opacity="0.85"
      />
      <path
        id="Path_13013"
        data-name="Path 13013"
        d="M123,13.991a.991.991,0,1,0,.991-.991.991.991,0,0,0-.991.991"
        transform="translate(-113.623 -12.009)"
        fill={fill}
        opacity="0.85"
      />
      <path
        id="Path_13014"
        data-name="Path 13014"
        d="M164,83.991a.991.991,0,1,0,.991-.991.991.991,0,0,0-.991.991"
        transform="translate(-151.496 -76.671)"
        fill={fill}
        opacity="0.85"
      />
      <path
        id="Path_13015"
        data-name="Path 13015"
        d="M124,153.991a.991.991,0,1,0,.991-.991.991.991,0,0,0-.991.991"
        transform="translate(-114.546 -141.335)"
        fill={fill}
        opacity="0.85"
      />
    </svg>
  );
};
