import { createSlice } from "@reduxjs/toolkit";

const titleSlice = createSlice({
  name: "titles",
  initialState: {
    titles: [],
    selectedTitle: null,
  },
  reducers: {
    appendTitle(state, action) {
      state.titles.push(action.payload);
    },
    setTitles(state, action) {
      state.titles = state.titles.concat(action.payload);
    },
    setSelectTitle(state, action) {
      state.selectedTitle = action.payload;
    },
  },
});

export const { appendTitle, setTitles, setSelectTitle } = titleSlice.actions;

export default titleSlice.reducer;
