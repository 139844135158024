import React from "react";

export const ArrowIcon = ({
  width = 21.401,
  height = 10.633,
  fill = "#ff996a",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 21.401 10.633"
    >
      <g id="_01" data-name="01" transform="translate(0 0)">
        <path
          id="Path_4956"
          data-name="Path 4956"
          d="M0,0,4.8,5.316,0,10.633H4.273l4.8-5.316L4.273,0Z"
          fill={fill}
        />
        <path
          id="Path_4957"
          data-name="Path 4957"
          d="M40.554,0l4.8,5.316-4.8,5.316h4.272l4.8-5.316L44.826,0Z"
          transform="translate(-34.391)"
          fill={fill}
        />
        <path
          id="Path_4958"
          data-name="Path 4958"
          d="M81.107,0l4.8,5.316-4.8,5.316h4.272l4.8-5.316L85.379,0Z"
          transform="translate(-68.781)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
