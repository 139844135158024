import React from "react";

export const ImgPlaceholderIcon = ({
  width = 75.609,
  height = 62.658,
  fill = "#9d9d9d",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 75.609 62.658"
    >
      <g id="icon" transform="translate(-237.58 -243.402)">
        <path
          id="Path_13966"
          data-name="Path 13966"
          d="M245.92,301.184V288.6l-5.069-30.968a1.627,1.627,0,0,1,1.341-1.866l4.488-.732a4.866,4.866,0,0,1,4.112-2.267h9.744l37.2-6.09a1.739,1.739,0,0,1,.264-.021,1.626,1.626,0,0,1,1.6,1.362l.778,4.749h3.295l-.862-5.273a4.886,4.886,0,0,0-5.6-4.024l-55.546,9.089a4.884,4.884,0,0,0-4.024,5.6l7.034,42.979a4.872,4.872,0,0,0,2.869,3.675A4.847,4.847,0,0,1,245.92,301.184Z"
          transform="translate(0 0)"
          fill={fill}
        />
        <path
          id="Path_13967"
          data-name="Path 13967"
          d="M302.346,299.358H244.821a4.881,4.881,0,0,1-4.872-4.876V250.933a4.878,4.878,0,0,1,4.872-4.872h57.525a4.878,4.878,0,0,1,4.872,4.872v43.549A4.881,4.881,0,0,1,302.346,299.358ZM244.821,249.31a1.626,1.626,0,0,0-1.623,1.623v43.549a1.626,1.626,0,0,0,1.623,1.623h57.525a1.626,1.626,0,0,0,1.623-1.623V250.933a1.626,1.626,0,0,0-1.623-1.623Z"
          transform="translate(5.971 6.702)"
          fill={fill}
        />
        <path
          id="Path_13968"
          data-name="Path 13968"
          d="M242.349,274.2l11.537-11.537,5.525,5.525,18.2-18.2,14.949,14.949V281.02h-50.21Z"
          transform="translate(12.02 16.589)"
          fill={fill}
        />
        <circle
          id="Ellipse_1835"
          data-name="Ellipse 1835"
          cx="6.337"
          cy="6.337"
          r="6.337"
          transform="translate(255.019 261.861)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
