import { React } from "react";

const GridTableHeader = ({ tableHeaderItem }) => {
  return (
    <thead>
      <tr className="text-secondary text-center font-hiragino-sans header-font">
        {tableHeaderItem &&
          tableHeaderItem.map((HeaderItem, i) => (
            <th
              className={`fw-normal ${HeaderItem.className} fs-17-px`}
              style={{
                width: `${HeaderItem.width}`,
              }}
              key={i}
            >
              {HeaderItem.headerName}
            </th>
          ))}
      </tr>
    </thead>
  );
};
export default GridTableHeader;
