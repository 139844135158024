import React from "react";

export const UploadIcon = ({
  width = 23.399,
  height = 22.501,
  fill = "#f27a43",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 23.399 22.501"
    >
      <g id="icon" transform="translate(-1162 -726.586)">
        <path
          id="upload-solid"
          d="M2.925,19.528h5.85a2.925,2.925,0,1,0,5.85,0h5.85A2.928,2.928,0,0,1,23.4,22.453v1.462a2.928,2.928,0,0,1-2.925,2.925H2.925A2.928,2.928,0,0,1,0,23.915V22.453A2.928,2.928,0,0,1,2.925,19.528Zm16.818,4.753a1.1,1.1,0,1,0-1.1-1.1A1.1,1.1,0,0,0,19.743,24.281Z"
          transform="translate(1162 722.246)"
          fill={fill}
          opacity="0.6"
        />
        <path
          id="angles-up-solid"
          d="M11.153,47.011,6.358,42.216a.96.96,0,0,0-1.357,0L.206,47.011a.96.96,0,0,0,1.357,1.357l4.117-4.117L9.8,48.366a.96.96,0,1,0,1.357-1.357Z"
          transform="translate(1168.019 684.65)"
          fill={fill}
          opacity="0.6"
        />
        <path
          id="angles-up-solid-2"
          data-name="angles-up-solid"
          d="M6.358,32.306a.96.96,0,0,0-1.357,0L.206,37.1a.96.96,0,0,0,1.357,1.357l4.117-4.117L9.8,38.455A.96.96,0,1,0,11.156,37.1L6.361,32.3Z"
          transform="translate(1168.019 701.275)"
          fill={fill}
          opacity="0.6"
        />
      </g>
    </svg>
  );
};
