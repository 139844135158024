import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  group: {},
};

export const typeSlice = createSlice({
  name: "type",
  initialState,
  reducers: {
    setGroup: (state, action) => {
      state.group = action.payload;
    },
  },
});

export const { setGroup } = typeSlice.actions;
export default typeSlice.reducer;
