import React from "react";

export const PenIcon = ({
  width = 18.824,
  height = 18.824,
  fill = "#f27a43",
  className = "",
  ...rest
}) => {
  return (
    <svg
      id="鉛筆icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18.824 18.824"
    >
      <path
        id="Path_5725"
        data-name="Path 5725"
        d="M109.353,711.8,99.218,721.939,91.579,723.9a.525.525,0,0,1-.64-.64l1.956-7.639,10.134-10.134a1.351,1.351,0,0,1,1.907,0l4.415,4.415A1.351,1.351,0,0,1,109.353,711.8ZM98.76,721.133l9.961-9.96a.455.455,0,0,0,0-.642l-4.417-4.417a.454.454,0,0,0-.642,0l-9.96,9.96-1.742,6.8Z"
        transform="translate(-90.923 -705.088)"
        fill={fill}
      />
      <path
        id="Path_5727"
        data-name="Path 5727"
        d="M95.385,717.917l2.478,2.478.138-.035,8.185-8.185-2.529-2.529Z"
        transform="translate(-90.164 -704.314)"
        fill={fill}
        opacity="0.846"
      />
      <path
        id="Path_5728"
        data-name="Path 5728"
        d="M93.268,715.806l2.478,2.478,8.272-8.272-2.529-2.529L93.3,715.668Z"
        transform="translate(-90.524 -704.681)"
        fill={fill}
      />
      <path
        id="Path_5730"
        data-name="Path 5730"
        d="M91.809,720.586l2.032-.521-1.511-1.511Z"
        transform="translate(-90.772 -702.799)"
        fill={fill}
      />
    </svg>
  );
};
