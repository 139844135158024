import React from "react";
import MessageBox from "../layouts/MessageBox";
import { TextInput } from "../components/Form";

const ChooseGroup = () => {
  return (
    <div
      className="h-100-vh d-flex justify-content-center"
      style={{ backgroundColor: "#343536", paddingTop: "300px" }}
    >
      <MessageBox className="mt-2" width="592px" height="473px">
        <div class="container-box mt-5">
          <div
            class="row text-white justify-content-center"
            style={{ fontSize: "25px" }}
          >
            グループ選択
          </div>
          <div class="row mt-3 justify-content-center">
            <TextInput
              placeholder="グループ名やグループ番号にて検索"
              width="399px"
              height="40px"
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div class="row mt-4 text-primary header-font">
            <div class="col-2"></div>
            <div class="col-4 d-flex justify-content-center">グループ名</div>
            <div class="col-4 d-flex justify-content-center">グループ番号</div>
            <div class="col-2"></div>
          </div>
          {[...Array(6)].map((e, i) => (
            <div class="row mt-3 text-white body-font" key={i}>
              <div class="col-2"></div>
              <div class="col-4 d-flex justify-content-center">Groupname 12</div>
              <div class="col-4 d-flex justify-content-center">12345</div>
              <div class="col-2"></div>
            </div>
          ))}
        </div>
      </MessageBox>
    </div>
  );
};

export default ChooseGroup;
