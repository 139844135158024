import { React, useContext } from "react";
import { CrossIcon, UpdateIcon } from "../assets/icons";
import { AppContext } from "../context/appContext";

/** Default Button */
export const Button = (props) => {
  const {
    width,
    text,
    height,
    className,
    textClassName = "ml-15-px",
    onClick,
    buttonClass,
    marginRight,
    isRightIcon = false,
    rightIcon,
    disabled,
  } = props;

  const { language } = useContext(AppContext);

  return (
    <button
      className={`${buttonClass ? buttonClass : "button-primary"} ${className}`}
      style={{
        width: width,
        height: height,
        marginRight: marginRight,
        lineHeight: language === "English" ? "15px" : "normal",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="left-wrapper" />
      <div className="inner" />
      <div className="right-wrapper" />
      <div className="text">
        {isRightIcon && rightIcon}
        <span className={`${isRightIcon ? textClassName : ""}`}>{text}</span>
      </div>
    </button>
  );
};

export const CancelButton = ({
  width,
  text = "Cancel",
  height,
  className,
  onClick,
  buttonClass,
  marginRight,
}) => {
  return (
    <div
      className={`${buttonClass ? buttonClass : "button-cancel"} ${className}`}
      style={{ width: width, height: height, marginRight: marginRight }}
      onClick={onClick}
    >
      <CrossIcon />
      <div className="text fs-18-px">{text}</div>
    </div>
  );
};

/** Menu Button */
export const MenuButton = (props) => {
  const {
    width,
    height,
    text,
    className,
    onClick,
    isSelected,
    textClassName = "",
  } = props;
  var buttonClass = isSelected ? "selected-button-menu" : "button-menu";
  return (
    <div
      className={`${className} ${buttonClass}`}
      style={{ width: width, height: height }}
      onClick={onClick}
    >
      <div className="left-wrapper" />
      <div className="inner" />
      <div className="right-wrapper" />
      <div className={`${textClassName} text`}>{text}</div>
    </div>
  );
};

export const LoginButton = (props) => {
  const { width, text, height, className, onClick } = props;

  return (
    <div
      className={`button-login ${className}`}
      style={{ width: width, height: height }}
      onClick={onClick}
    >
      <div className="left-wrapper" />
      <div className="inner" />
      <div className="right-wrapper" />
      <div className="text-login">{text}</div>
    </div>
  );
};

export default Button;
