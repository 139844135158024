import React from "react";

export const MNDIcon = ({
  width = 17.048,
  height = 14.279,
  fill = "#fff",
  ...rest
}) => {
  return (
    <svg
      id="MND"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17.048 14.279"
    >
      <path
        id="Path_13052"
        data-name="Path 13052"
        d="M7.9,12.872a13.216,13.216,0,0,1-5.35-1.016C1.083,11.184.275,10.276.275,9.3S1.083,7.42,2.551,6.748A13.206,13.206,0,0,1,7.9,5.732a13.206,13.206,0,0,1,5.35,1.016c1.467.672,2.276,1.579,2.276,2.553s-.808,1.882-2.276,2.554A13.216,13.216,0,0,1,7.9,12.872m0-6.849C3.856,6.023.566,7.494.566,9.3S3.856,12.58,7.9,12.58s7.334-1.47,7.334-3.278S11.945,6.023,7.9,6.023"
        transform="translate(-0.105 -2.186)"
        fill={fill}
      />
      <path
        id="Path_13053"
        data-name="Path 13053"
        d="M8.26,14.4A7.042,7.042,0,1,1,15.3,7.357,7.05,7.05,0,0,1,8.26,14.4M8.26.605a6.751,6.751,0,1,0,6.751,6.751A6.759,6.759,0,0,0,8.26.605"
        transform="translate(-0.464 -0.12)"
        fill={fill}
      />
      <path
        id="Path_13054"
        data-name="Path 13054"
        d="M.729,7.981a.364.364,0,1,1-.364-.364.365.365,0,0,1,.364.364"
        transform="translate(0 -2.906)"
        fill={fill}
      />
      <path
        id="Path_13055"
        data-name="Path 13055"
        d="M4.452,4.5A.656.656,0,1,1,3.8,3.848a.656.656,0,0,1,.656.656"
        transform="translate(-1.198 -1.468)"
        fill={fill}
      />
      <path
        id="Path_13056"
        data-name="Path 13056"
        d="M21.545,13.452a.656.656,0,1,1-.656-.656.656.656,0,0,1,.656.656"
        transform="translate(-7.718 -4.881)"
        fill={fill}
      />
      <path
        id="Path_13057"
        data-name="Path 13057"
        d="M26.995,11.472a.459.459,0,1,1-.459-.459.459.459,0,0,1,.459.459"
        transform="translate(-9.947 -4.201)"
        fill={fill}
      />
      <path
        id="Path_13058"
        data-name="Path 13058"
        d="M25.292,13.842a.312.312,0,1,1-.312-.312.312.312,0,0,1,.312.312"
        transform="translate(-9.41 -5.161)"
        fill={fill}
      />
      <path
        id="Path_13059"
        data-name="Path 13059"
        d="M13.594,6.7a1.765,1.765,0,0,1-.684-.194.993.993,0,0,1-.547-.972L11.956.064V0l0,.032,0-.032V.064l-.405,5.472A1,1,0,0,1,11,6.508a1.765,1.765,0,0,1-.685.194L7.3,7.011a15.99,15.99,0,0,1,1.784.177,11.31,11.31,0,0,1,1.78.388h0a.428.428,0,0,1,.308.3l.778,4.254v.026l0-.013,0,.013v-.026l.779-4.254a.426.426,0,0,1,.308-.3h0a11.261,11.261,0,0,1,1.779-.388,16.01,16.01,0,0,1,1.784-.177Z"
        transform="translate(-4.094)"
        fill={fill}
      />
    </svg>
  );
};
