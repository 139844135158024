import { React, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { Button } from "../components/Button";
import { SearchInput, TextInput } from "../components/Form";
import QuestionIcon from "../assets/icons/question.svg";
import QuestionTitleOfGuidance from "../assets/icons/question-title-of-guidance.svg";
import { ConfirmModal, Modal, LoadingModal } from "../components/Modal";
import HexagonCheckBoxes from "../components/HexagonCheckBoxes";
import PaginationBottomSpace from "../components/PaginationBottomSpace";
import { fetchData } from "../services/fetch";
import { useQuery } from "react-query";
import { storeErrorModal } from "../services/storage";
import Pagination from "../components/common/Pagination";
import { AppContext } from "../context/appContext";

/** Content Body */
const Group = () => {
  const [guidanceModal, setGuidanceModal] = useState(false);
  const [selectIds, setSelectIds] = useState([]);
  const [publishData, setPublishData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedPublishes, setSelectedPublishes] = useState([]);
  const [errorOrders, setErrorOrders] = useState();
  const [errorAmounts, setErrorAmounts] = useState();

  const { data, refetch } = useQuery({
    queryKey: "get-publishes",
    queryFn: () =>
      fetchData({ url: `api/publish?${filter ? "filter=" + filter : ""}` }),
    onSuccess: (response) => {
      paginatedData.total_count = response.data.total_count;
      paginatedData.page_count = response.data.page_count;
      if (response.data.current_page * 10 > response.data.total_count) {
        paginatedData.current_page_amount = response.data.total_count;
      } else {
        paginatedData.current_page_amount = response.data.current_page * 10;
      }
      paginatedData.current_page = response.data.current_page;
      setPublishData(response.data.publish);
    },
  });

  useEffect(() => {
    if (selectedPublishes) {
      selectedPublishes.forEach((selectedPublish) => {
        setPublishData((publishData) =>
          publishData.map((item) => {
            if (item.id === selectedPublish.id) {
              return {
                ...item,
                order_count: selectedPublish.order_count,
                order_amount: selectedPublish.order_amount,
              };
            }
            return item;
          })
        );
      });
    }
  }, [selectedPublishes, paginatedData]);

  const handleSelect = (publish) => {
    const obj = publishData.find((item) => item.id === publish.id);
    const isSelected = selectedPublishes.find((item) => item.id === obj.id);
    let errOrders = {};
    let errAmounts = {};
    if (!isSelected) {
      //add to selected array
      setSelectedPublishes((current) => [...current, obj]);
    } else {
      //remove from selected
      setSelectedPublishes((current) =>
        current.filter((item) => item.id !== publish.id)
      );
      if (errorOrders) {
        errOrders = errorOrders;
        if (errorOrders.order.length != 0) {
          let errorOrdersArray = errorOrders.order;
          errorOrdersArray.forEach((ordersarray, i) => {
            if (ordersarray.id == publish.id) {
              errorOrdersArray.splice(i, 1);
            }
          });
          errOrders["order"] = errorOrdersArray;
        }
        setErrorOrders(errOrders);
      }
      if (errorAmounts) {
        errAmounts = errorAmounts;
        if (errorAmounts.amount.length != 0) {
          let errorAmountsArray = errorAmounts.amount;
          errorAmountsArray.forEach((amountarray, i) => {
            if (amountarray.id == publish.id) {
              errorAmountsArray.splice(i, 1);
            }
          });
          errAmounts["amount"] = errorAmountsArray;
        }
        setErrorAmounts(errAmounts);
      }
    }
    // console.log("Selected Publishes => ", selectedPublishes);
  };

  const handleOrderCountInput = (publish, total_order) => {
    if (!isNaN(total_order)) {
      if(total_order<=publish.token_count){
        let olderrorOrders=errorOrders;
        let errs = {};
        errs["order"] = [];
        if(olderrorOrders?.order?.length>0){
          errs["order"]=olderrorOrders?.order;
          let errorarraylist=olderrorOrders["order"];
          errorarraylist.forEach((errorarray, i) => {
            if(errorarray.id==publish.id){
              errorarraylist.splice(i,1);
            }
          })
          errs["order"]=errorarraylist;
        }
        setErrorOrders(errs);
        setPublishData((publishData) =>
          publishData.map((item) => {
            if (item.id === publish.id) {
              return { ...item, order_count: total_order };
            }
            return item;
          })
        );

        //updated in selectedPublishList
        const selectedPublish = selectedPublishes.find(
          (item) => item.id === publish.id
        );
        if (selectedPublish) {
          setSelectedPublishes((selectedPublish) =>
            selectedPublish.map((item) => {
              if (item.id === publish.id) {
                return { ...item, order_count: total_order };
              }
              return item;
            })
          );
        }
      }
    }
    
  };

  const handleOrderAmountInput = (publish, amount) => {
    if (!isNaN(amount)) {
      let olderrorAmounts=errorAmounts;
      let errs = {};
      errs["amount"] = [];
      if(olderrorAmounts?.amount?.length>0){
        errs["amount"]=olderrorAmounts?.amount;
        let errorarraylist=olderrorAmounts["amount"];
        errorarraylist.forEach((errorarray, i) => {
          if(errorarray.id==publish.id){
            errorarraylist.splice(i,1);
          }
        })
        errs["amount"]=errorarraylist;
      }
      setErrorAmounts(errs);
      setPublishData((publishData) =>
        publishData.map((item) => {
          if (item.id === publish.id) {
            return { ...item, order_amount: amount };
          }
          return item;
        })
      );
      //updated in selectedPublishList
      const selectedPublish = selectedPublishes.find(
        (item) => item.id === publish.id
      );
      if (selectedPublish) {
        setSelectedPublishes((selectedPublish) =>
          selectedPublish.map((item) => {
            if (item.id === publish.id) {
              return { ...item, order_amount: amount };
            }
            return item;
          })
        );
      }
    }
  };

  //validation
  const hasValidateError = (isremove) => {
    let newerrorAmounts = {};
    let newerrorOrders = {};
    if (errorOrders) {
      if (errorOrders?.order.length != 0) {
        newerrorOrders["order"] = errorOrders.order;
      } else {
        newerrorOrders["order"] = [];
      }
    } else {
      newerrorOrders["order"] = [];
    }
    if (errorAmounts) {
      if (errorAmounts.amount.length != 0) {
        newerrorAmounts["amount"] = errorAmounts.amount;
      } else {
        newerrorAmounts["amount"] = [];
      }
    } else {
      newerrorAmounts["amount"] = [];
    }
    for (const selectId of selectedPublishes) {
      if (!selectId.order_amount) {
        var error = {
          id: "",
        };
        error.id = selectId.id;
        newerrorAmounts["amount"].push(error);
      }
      if (!selectId.order_count) {
        var error = {
          id: "",
        };
        error.id = selectId.id;
        newerrorOrders["order"].push(error);
      }
    }
    if (newerrorAmounts["amount"].length === 0) {
      delete newerrorAmounts.amount;
    }
    if (newerrorOrders["order"].length === 0) {
      delete newerrorOrders.order;
    }
    setErrorOrders(newerrorOrders);
    setErrorAmounts(newerrorAmounts);
    if (
      Object.keys(newerrorOrders).length > 0 ||
      Object.keys(newerrorAmounts).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkValidation = (id, type) => {
    if (type == "order") {
      if (
        errorOrders &&
        errorOrders.order &&
        errorOrders.order.find((v) => v.id === id)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (type == "amount") {
      if (
        errorAmounts &&
        errorAmounts.amount &&
        errorAmounts.amount.find((v) => v.id === id)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handlePaginateData = (paginatedData) => {
    fetchData({
      url: filter
        ? "api/publish?page=" + paginatedData.current_page + "&filter=" + filter
        : "api/publish?page=" + paginatedData.current_page,
      method: "get",
    })
      .then((response) => {
        setPaginatedData({
          total_count: response.data.total_count,
          page_count: response.data.page_count,
          current_page: response.data.current_page,
          current_page_amount:
            response.data.current_page * 10 > response.data.total_count
              ? response.data.total_count
              : response.data.current_page * 10,
        });
        setPublishData(response.data.publish);
      })
      .catch((error) => {
        console.log(error);
        storeErrorModal(error.message);
      });
  };
  const enterSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      refetch();
    }
  };
  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        className="content-wide-title"
        title={
          <div className="d-flex gap-0 mt-1" style={{ marginLeft: "3%" }}>
            <div>ペルリア発行</div>
            <div className="mt-n1" style={{ marginLeft: "5%" }}>
              <img
                src={QuestionIcon}
                alt="Question Icon"
                style={{ cursor: "pointer" }}
                onClick={() => setGuidanceModal(true)}
              />
            </div>
          </div>
        }
        topSpace={
          <TopSpace
            enterSearch={enterSearch}
            setFilter={setFilter}
            publishData={publishData}
            selectedPublishes={selectedPublishes}
            refetch={refetch}
            hasValidateError={hasValidateError}
          />
        }
        bottomSpace={
          <Pagination
            onChange={handlePaginateData}
            paginate_data={paginatedData}
          />
        }
      >
        <Modal
          isOpen={guidanceModal}
          height="308px"
          width="574px"
          onClick={() => setGuidanceModal(false)}
        >
          <div
            className="body-text font-europa-regular"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "25px",
              marginBottom: "20px",
              marginTop: "13px",
              marginLeft: "10px",
            }}
          >
            <img
              src={QuestionTitleOfGuidance}
              alt="Question Icon"
              height="auto"
              width="55px"
              style={{ marginRight: "2%" }}
            />
            Title of guidance
          </div>
          <div
            style={{
              lineHeight: 1,
              fontSize: "20px",
              height: "180px",
              width: "520px",
              paddingLeft: "20px",
              color: "#FFFFFF",
              // paddingRight: "20px",
            }}
            className="font-europa-regular overflow-scroll hide-scroll"
          >
            {/* message box */}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat.
          </div>
        </Modal>
        {/* Table */}
        <div className="h-100 ms-2">
          {/* Header */}
          <div className=" row text-primary header-font text-center mt-2">
            <div
              className="w-60-px font-ethno-light"
              style={{ fontWeight: 900 }}
            >
              {selectedPublishes.length}/100
              {/* <br /> {selectIds} <br />
              <div className="d-flex">
                {data &&
                  publishData.map((publish, i) => (
                    <div style={{ fontFamily: "Times New Roman" }}>
                      [id=>{publish.id},order_count=>{publish?.order_count}
                      ],&nbsp;
                    </div>
                  ))}
              </div> */}
            </div>
            <div className="col-3 ">グループ名</div>
            <div
              className="col-3"
              // style={{ marginLeft: "3%", marginRight: "6%" }}
            >
              グループタイプ
            </div>
            <div className="col-3">ペルリア数</div>
            <div className="col-2">価格 (円)</div>
          </div>

          {/*End Header */}
          {/* Body */}
          <div className="mt-4 text-white body-font overflow-auto hide-scroll h-90 pt-2">
            {/* Test Data Loop */}
            {data &&
              publishData.map((publish, i) => (
                <div className="row" key={i} style={{ marginBottom: "1%" }}>
                  <div className="w-60-px">
                    <HexagonCheckBoxes
                      onSelect={() => handleSelect(publish)}
                      // isCheck={selectIds.includes(publish.id)}
                      isCheck={selectedPublishes.some(
                        (item) => item.id === publish.id
                      )}
                    />
                  </div>
                  <div
                    className="col-3 text-center  d-flex justify-content-center" /*style={{ marginLeft: "0%" }}*/
                  >
                    {publish.group_name}
                  </div>
                  <div
                    className="col-3 text-center  d-flex justify-content-center"
                    /*style={{ marginLeft: "2%", marginRight: "0%" }}*/
                  >
                    {publish.type_name}
                  </div>
                  <div
                    className="col-3 d-flex justify-content-center" /*style={{ marginLeft: "5%" }}*/
                  >
                    <TextInput
                      className="fs-15-px"
                      inputClass=" w-80 fs-20-px"
                      required
                      textboxClass="w-80-px"
                      height="30px"
                      errorMessage={checkValidation(publish.id, "order")}
                      onChange={(e) =>
                        handleOrderCountInput(publish, e.target.value)
                      }
                      value={publish?.order_count ? publish.order_count : ""}
                    />
                    <div style={{ marginLeft: "-10px" }}>
                      / {publish.token_count}
                    </div>
                  </div>
                  <div
                    className="col-2 d-flex justify-content-center"
                    style={{ marginRight: "0%" }}
                  >
                    <TextInput
                      onChange={(e) =>
                        handleOrderAmountInput(publish, e.target.value)
                      }
                      errorMessage={checkValidation(publish.id, "amount")}
                      className="fs-15-px"
                      required
                      width="120px"
                      height="30px"
                      value={publish?.order_amount ? publish.order_amount : ""}
                    />
                  </div>
                </div>
              ))}
            {/*End Test Data Loop */}
          </div>
          {/* End Body */}
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Top Space */
const TopSpace = ({
  selectedPublishes,
  publishData,
  enterSearch,
  setFilter,
  refetch,
  hasValidateError,
}) => {
  const navigate = useNavigate();
  const { setUnexpectedErrorModal, unexpectedErrorModal } =
    useContext(AppContext);
  /** States */
  const [issueConfirmModal, setIssueConfirmModal] = useState(false);
  const [issueLoadingModal, setIssueLoadingModal] = useState(false);
  const [progress, setProgress] = useState(10);

  /** Functions */
  const handleIssue = async () => {
    setIssueConfirmModal(false);
    // order api will implement here
    let validate_check = hasValidateError();
    if (validate_check) {
      return true;
    } else {
      setIssueLoadingModal(true);
      try {
        await orderToken();
        refetch();
      } catch (e) {
        console.log(e);
        setIssueLoadingModal(false);
        setUnexpectedErrorModal(true);
      }
    }
  };

  /** Functions */
  const issueConfirmClick = async () => {
    // debugger;
    if (selectedPublishes.length > 0) {
      setIssueConfirmModal(true);
    } else {
      return true;
    }
  };
  const orderToken = async () => {
    let totalOrderTokenCount = getTotalOrderCount();
    let progress_percentage = 0;

    for (const publish of selectedPublishes) {
      //find selected publish
      let publish_token_ids = publish.token_ids.split(",");

      let count = 0;

      for (const token_id of publish_token_ids) {
        if (count === Number(publish.order_count)) {
          count = 0;
          break;
        }

        try {
          await fetchData({
            url: "api/persona/order",
            method: "post",
            data: {
              type: "buy",
              token_id: token_id,
              amount: publish.order_amount,
              currency: 1,
              payment_type: "web",
            },
          });
        } catch (error) {
          setIssueLoadingModal(false);
          setUnexpectedErrorModal(true);
          return;
        }
        count++;
        progress_percentage += 100 / totalOrderTokenCount;
        setProgress(progress_percentage);
      }
    }
    setProgress(100);
  };

  const getTotalOrderCount = () => {
    let totalTokenCount = 0;
    selectedPublishes.forEach((publish) => {
      let publish_token_ids = publish.token_ids.split(",");
      console.log(publish_token_ids);
      if (publish.order_count <= publish_token_ids.length) {
        totalTokenCount += Number(publish.order_count);
      } else {
        totalTokenCount = publish_token_ids.length;
      }
    });

    return totalTokenCount;
  };

  const redirectIssue = () => {
    navigate("/management");
  };
  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-between h-100">
      {/* Search Box */}
      <SearchInput
        placeholder="グループ名やグループ番号にて検索"
        width="415px"
        required
        onChange={(e) => setFilter(e)}
        onKeyDown={enterSearch}
      />
      {/* Create Button */}
      <Button
        onClick={() => issueConfirmClick()}
        text="ペルリア発行"
        width="235px"
        marginRight="3px"
      />
      {/* Guidance Modal */}
      {/*Issue Modals */}
      <ConfirmModal
        isOpen={issueConfirmModal}
        onConfirm={() => handleIssue()}
        onCancel={() => setIssueConfirmModal(false)}
      >
        ペルリア発行しますか？
      </ConfirmModal>
      <LoadingModal
        isOpen={issueLoadingModal}
        onAction={() => redirectIssue()}
        progress={progress}
      ></LoadingModal>
      {/* End Issue Modals */}
    </div>
  );
};

export default Group;
