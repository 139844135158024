import { React, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { TextInput } from "../components/Form";
import Button from "../components/Button";
import { ConfirmModal, InfoModal } from "../components/Modal";
import InputImage from "../assets/images/input_name.png";
import { fetchData, fetchFormData } from "../services/fetch";
import { useQuery } from "react-query";
import { storeErrorModal } from "../services/storage";
import ChooseGroupModal from "../components/common/ChooseGroupModal";
import { useDispatch, useSelector } from "react-redux";
import {  setGroupId, setTypeId } from "../slices/publish";

// import { setGroup as setTypeGroup } from "../slices/type";
import { truncateString } from "../utils/truncate";
const GroupSetting = () => {
  // React

  const dispatch = useDispatch();
  const [typeNo, setTypeNo] = useState();
  const [typeName, setTypeName] = useState();
  const [creator, setCreator] = useState();
  const [chooseGroupModal, setChooseGroupModal] = useState(false);
  //const { group } = useSelector((state) => state.type);

  const [errors, setErrors] = useState({});
  const [errorTypes, setErrorTypes] = useState({});
  const [groupID, setGroupID] = useState(0);
  const [oldtypeID, setOldTypeID] = useState(0);
  const [group, setGroup] = useState([]);
  const blank_src =
    "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
    const { typeId} = useSelector(
      (state) => state.publish
    );
  const handleUpload = () => {
    if(oldtypeID)
    {
      dispatch(setTypeId(oldtypeID));
    }
    // setuploadImage(!uploadImage);
    document.getElementById("input_file").click();
  };
  const onchangetype = (group) => {
    if(typeId){
      setOldTypeID(typeId);
    }
    setGroupID(group.id);
  };
  const handleChangeImage = () => {
    const img = document.getElementById("img");
    const [file] = document.getElementById("input_file").files;
    if (file) {
      img.src = URL.createObjectURL(file);
    }
  };

  const clearInput = () => {
    setGroup([]);
    //dispatch(setTypeGroup({}));
    setTypeName("");
    setTypeNo("");
    // document.getElementById("input_file").value = "";
    // document.getElementById("img").src = blank_src;
  };

  const hasValidateError = () => {
    let errors = {};
    let error_types = {};
    if (!group || Object.keys(group).length === 0) {
      // errors["group_id"] = "Group is required";
      error_types["group_id"] = "required";
    }

    if (!typeName) {
      // errors["persona_type_name"] = "Type Name is required";
      error_types["persona_type_name"] = "required";
    }

    if (!creator) {
      error_types["creator"] = "required";
    }

    if (!typeNo) {
      // errors["persona_type_no"] = "Type No is required";
      error_types["persona_type_no"] = "required";
    } else {
      if (isNaN(typeNo)) {
        errors["persona_type_no"] = "Type No must be number";
      }
    }
    const [img_file] = document.getElementById("input_file").files;
    if (!img_file) {
      error_types["input_file"] = "required";
    }
    setErrors(errors);
    setErrorTypes(error_types);
    if (Object.keys(errors).length > 0 || Object.keys(error_types).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        title={<Title />}
        topSpace={
          <TopSpace
            oldtypeID={oldtypeID}
            group={group}
            typeName={typeName}
            typeNo={typeNo}
            creator={creator}
            clearInput={clearInput}
            setErrors={setErrors}
            errors={errors}
            hasValidateError={hasValidateError}
          />
        }
      >
        <div className="text-white " style={{ marginLeft: "12px" }}>
          <div className="d-flex" style={{ marginTop: "10px" }}>
            <div className="w-50 d-flex" style={{ gap: "30px" }}>
              <div className="fs-20-px font-notosans-jp d-flex align-items-center">
                所属グループ
              </div>
              <div className="ms-2">
                <div className="position-relative">
                  <TextInput
                    errorMessage={errors && errors.group_id && errors.group_id}
                    isError={
                      errorTypes &&
                      errorTypes.group_id &&
                      errorTypes.group_id === "required" &&
                      true
                    }
                    onClick={() => setChooseGroupModal(true)}
                    placeholder="グループ選択"
                    required
                    width="230px"
                    height="40px"
                    inputClass="w-87 fs-18 center-placeholder"
                    value={group ? group.name : ""}
                    //   defaultValue={typeDetail.group_name}
                    // onChange={(e) =>
                    //   setTypeDetail((value) => ({
                    //     ...value,
                    //     group_id: selectedGroupId,
                    //   }))
                    // }
                  />
                  <div
                    onClick={() => setChooseGroupModal(true)}
                    className="dropdown-icon position-absolute top-50 translate-middle"
                    style={{ right: 15 }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="w-50 d-flex" style={{ gap: "30px" }}>
              <div className=" fs-20-px font-notosans-jp d-flex align-items-center ms-10-px">
                タイプ名
              </div>
              <div className="">
                <TextInput
                  errorMessage={
                    errors &&
                    errors.persona_type_name &&
                    errors.persona_type_name
                  }
                  isError={
                    errorTypes &&
                    errorTypes.persona_type_name &&
                    errorTypes.persona_type_name === "required" &&
                    true
                  }
                  width="230px"
                  height="40px"
                  // value={typeDetail.persona_type_name}
                  inputClass="ms-2 fs-18-px"
                  required
                  value={typeName}
                  onChange={(e) => setTypeName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex mt-40-px">
            <div className="w-50">
              <div className="d-flex" style={{ width: "300.46px" }}>
                <div
                  className="fs-20-px font-notosans-jp d-flex align-items-center"
                  style={{ width: "27%" }}
                >
                  イメージ
                </div>
                <div
                  className="d-flex justify-content-end"
                  style={{ width: "73%" }}
                >
                  <Button
                    onClick={() => handleUpload()}
                    height="40px"
                    width="173px"
                    text="アップロード"
                  />
                  <input
                    onChange={() => handleChangeImage()}
                    type="file"
                    name=""
                    id="input_file"
                    hidden
                  ></input>
                </div>
              </div>
              <div style={{ marginTop: "12px" }}>
                <div
                  className={`image-box  ${
                    errorTypes.input_file ? "error-border" : ""
                  } h-350-px`}
                  style={{ width: "300.46px", height: "350px" }}
                >
                  <div className={`w-100 h-100 upload-image`}>
                    <img
                      id="img"
                      width="100%"
                      height="100%"
                      src={blank_src}
                      //   src={typeDetail.image_uri ?? InputImage}
                      alt="type"
                    />
                  </div>
                  {/* {uploadImage && (
                      <div
                        className={`w-100 h-100 ${
                          uploadImage ? "upload-image" : ""
                        }`}
                      >
                        <img
                          width="100%"
                          height="100%"
                          src={typeDetail.image_uri}
                          alt="type"
                        />
                      </div>
                    )} */}

                  <div className={`dark-bg `} />
                </div>
              </div>
            </div>
            <div className="w-50 d-flex flex-column">
              {/* Type No */}
              <div className=" d-flex" style={{ gap: "30px" }}>
                <div className=" h-45-px fs-20-px font-notosans-jp d-flex align-items-center ms-10-px">
                  タイプ番号
                </div>
                <div className="">
                  <TextInput
                    value={typeNo}
                    // errorMessage="既に使用されている番号です"
                    errorMessage={
                      errors && errors.persona_type_no && errors.persona_type_no
                    }
                    isError={
                      errorTypes &&
                      errorTypes.persona_type_no &&
                      errorTypes.persona_type_no === "required" &&
                      true
                    }
                    width="230px"
                    height="40px"
                    required
                    inputClass="ms-2 fs-18-px"
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setTypeNo(e.target.value);
                        setErrors((value) => ({
                          ...value,
                          persona_type_no: "",
                        }));
                      } else {
                        if (typeNo) {
                          setTypeNo(typeNo);
                        } else {
                          setTypeNo("");
                        }
                        setErrorTypes({
                          persona_type_no: "required",
                        });
                        // if (errors && errors.persona_type_no) {
                        setErrors((value) => ({
                          ...value,
                          persona_type_no: "",
                        }));
                        // }
                      }
                    }}
                  />
                </div>
              </div>
              {/* Creator */}
              <div
                className="d-flex mt-35-px"
                style={{ gap: "30px", marginLeft: "-10px" }}
              >
                <div className="h-45-px fs-20-px font-notosans-jp d-flex align-items-center">
                  クリエター名
                </div>
                <div className="">
                  <TextInput
                    // value={creator}
                    // errorMessage="既に使用されている番号です"
                    errorMessage={errors && errors.creator && errors.creator}
                    isError={
                      errorTypes &&
                      errorTypes.creator &&
                      errorTypes.creator === "required" &&
                      true
                    }
                    width="230px"
                    height="40px"
                    required
                    inputClass="ms-2 fs-18-px"
                    onChange={(e) => {
                      setCreator(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Choose Group Modal */}
          <ChooseGroupModal
            isChooseGroup={true}
            isOpen={chooseGroupModal}
            onClick={() => {
              setChooseGroupModal(false);
            }}
            groupID={groupID}
            onchangetype={onchangetype}
            setGroup={setGroup}
            onClose={() => setChooseGroupModal(false)}
          />
          {/* End Choose Group Modal */}
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
const Title = () => {
  return <div className="ms-2 mt-5-px">タイプ設定</div>;
};

/** Top Space */
const TopSpace = (props) => {
  const {
    oldtypeID,
    group,
    typeName,
    typeNo,
    clearInput,
    setErrors,
    errors,
    hasValidateError,
    creator,
  } = props;
  //const { group } = useSelector((state) => state.type);
  /** react */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** States */
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateInfoModal, setUpdateInfoModal] = useState(false);

  const [publishConfirmModal, setPublishConfirmModal] = useState(false);
  const [publishInfoModal, setPublishInfoModal] = useState(false);

  const [createdTypeId, setCreatedTypeId] = useState("");

  /** Functions */
  const handleUpdate = async (id, ispublic) => {
    let validate_check = hasValidateError();
    if (validate_check) {
      setUpdateConfirmModal(false);
      setPublishConfirmModal(false);
      return;
    }
    // upload Image and get url
    let formData = new FormData();
    let img_url = "";
    let hash = "";
    const [img_file] = document.getElementById("input_file").files;
    if (img_file) {
      formData.append("file", img_file);
      formData.append("type", "ipfs");
      await fetchFormData({
        url: "api/upload",
        data: formData,
      }).then((res) => {
        img_url = res.data.url;
        hash = res.data.ipfs.Hash;
      });
    }

    // end upload image

    let createObject = {
      group_id: group.id,
      persona_type_no: typeNo,
      persona_type_name: typeName,
      creator: creator,
      image_uri: img_url,
      hash,
    };
    console.log(createObject);
    fetchData({
      url: "api/persona_type",
      method: "post",
      data: createObject,
    })
      .then((res) => {
        console.log(res);
        setUpdateConfirmModal(false);
        setCreatedTypeId(res.data.id);
        if (!ispublic) {
          redirectList();
          //setUpdateInfoModal(true);
        } else {
          redirectPublish(res.data.id);
          setPublishConfirmModal(false);
          //setPublishInfoModal(true);
        }
        setErrors(null);
        clearInput();
      })
      .catch((e) => {
        console.log(e);
        setUpdateConfirmModal(false);
        setPublishConfirmModal(false);
        if (e.response.data.error) {
          storeErrorModal(e.response.data.error);
          setErrors(e.response.data.errors);
        } else {
          storeErrorModal(e.message);
        }
      });
  };

  const handlePublish = (id) => {
    handleUpdate(null, true);
    //setPublishConfirmModal(false);
  };

  const redirectPublish = (id) => {
    setPublishInfoModal(false);
    if(id){
      dispatch(setTypeId(id));
    }
    dispatch(setGroupId(group.id));
    //dispatch(setTypeGroup(""));
    navigate("/publish");
  };

  const redirectList = () => {
    setUpdateInfoModal(false);
    if(oldtypeID)
    {
      dispatch(setTypeId(oldtypeID));
    }
    //dispatch(setGroupId(group.id));
    //dispatch(setTypeGroup(""));
    navigate("/types");
  };

  // const checkValidate = () => {
  //   let validate_check = hasValidateError();
  //   console.log(validate_check);

  //   if (!validate_check) {
  //     setUpdateConfirmModal(true);
  //   }
  // };

  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-end h-100 gap-4">
      {/* Token Update Button */}
      <Button
        onClick={() => setUpdateConfirmModal(true)}
        text="保存"
        width="173px"
        // height="40px"
      />
      <Button
        onClick={() => setPublishConfirmModal(true)}
        text="発行"
        width="173px"
        // height="40px"
      />

      {/*Update Modals */}
      <ConfirmModal
        isOpen={updateConfirmModal}
        onConfirm={() => handleUpdate()}
        onCancel={() => setUpdateConfirmModal(false)}
      >
        変更項目を保存しますか？
      </ConfirmModal>
      <InfoModal isOpen={updateInfoModal} onAction={() => redirectList()}>
        redirect
        変更項目が正常に保存されました。
      </InfoModal>
      {/* End Update Modals */}

      {/*Publish Modals */}
      <ConfirmModal
        isOpen={publishConfirmModal}
        onConfirm={() => handlePublish()}
        onCancel={() => setPublishConfirmModal(false)}
      >
        一部の変更は保存されていません。
        <br /> 変更を保存しますか？
      </ConfirmModal>
      <InfoModal isOpen={publishInfoModal} onAction={() => redirectPublish()}>
        publish
        変更項目が正常に保存されました。
      </InfoModal>
      {/* End Publish Modals */}
    </div>
  );
};

export default GroupSetting;
