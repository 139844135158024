import React from "react";
import MessageBox from "../layouts/MessageBox";
import ErrorImg from "../assets/images/error-img.png";
import { Button } from "../components/Button";

const ErrorMsg = () => {
  return (
    <div
      className="h-100-vh d-flex justify-content-center"
      style={{ backgroundColor: "#343536", paddingTop: "300px" }}
    >
      <MessageBox className="mt-2" width="572px" height="330px">
        <div class="container-box mt-4 px-2">
          <div class="row">
            <div class="mb-3 d-flex justify-content-center">
              <img src={ErrorImg} alt="Error" />
            </div>
            <div class="mt-3 mb-3">
              <h5 class="fs-20-px px-4 text-white d-flex justify-content-center">
                複数のトークンが既に存在しています。
              </h5>
              <h5 class="fs-20-px px-5 text-white d-flex justify-content-center">
                再度登録を行ってみてください。
              </h5>
            </div>
            <div class="mt-4 d-flex justify-content-center">
              <Button text="OK" width="173px" height="40px"></Button>
            </div>
          </div>
        </div>
      </MessageBox>
    </div>
  );
};

export default ErrorMsg;
