import React from "react";

export const EverGreenTreeIcon = ({
  width = 24.461,
  height = 22.672,
  fill = "#fff",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24.461 22.672"
    >
      <g
        id="Eternal_tree"
        data-name="Eternal tree"
        transform="translate(-2876.058 -9904.446)"
      >
        <path
          id="Path_4941"
          data-name="Path 4941"
          d="M41.679,37.043A11.279,11.279,0,0,0,37.247,35.6a10.644,10.644,0,0,1,3.277,1.231,10.778,10.778,0,0,1,2.515,16.629c-.167.175-.337.343-.507.5a6.076,6.076,0,0,1-9.392-1.14c-.084-.187-.158-.374-.227-.564a12.591,12.591,0,0,1,4.53-1.608,6.449,6.449,0,0,1,4.767,1.9,6.6,6.6,0,0,0-3.832-2.753c-2.362-.494-3.856.382-5.838,1.046-.007-.038-.014-.077-.019-.113a4.3,4.3,0,0,1,2.751-3.542s-1.893,1.159-2.058,2.649A1.655,1.655,0,0,0,35.189,48.8c.777-1.461,1.365-1.6,1.814-1.49a2.911,2.911,0,0,0-4.2.972,3.745,3.745,0,0,0-.371.873,7.3,7.3,0,0,1,1.8-4.54,3.634,3.634,0,0,1,3.858-.293,3.818,3.818,0,0,0-2.811.17,1.4,1.4,0,0,0,1.75.677c1.351-.322,1.767-.027,1.946.322a2.455,2.455,0,0,0-2.947-2.119,2.974,2.974,0,0,0-.624.192,6.863,6.863,0,0,1,.889-.542,7.216,7.216,0,0,1,2.773-.78,1.593,1.593,0,0,0,1.037.526,1.719,1.719,0,0,0,1.128-.624,1.719,1.719,0,0,0-1.128-.624,1.719,1.719,0,0,0-1.128.624,7.493,7.493,0,0,0-2.378.408,2.765,2.765,0,0,0,.61-.713,2.046,2.046,0,0,0-.834-2.9c.222.238.335.643-.287,1.615a1.16,1.16,0,0,0,.043,1.557,3.191,3.191,0,0,0,.908-2.157,3.041,3.041,0,0,1-1.3,2.952c-.05.024-.1.046-.148.072a7.625,7.625,0,0,0-.916.557c-.069.031-.11.046-.11.046l.076-.022a7.6,7.6,0,0,0-2.113,2.3,5.215,5.215,0,0,0-.292-1.68c-1.422-3.921-5.285-2.347-5.285-2.347.622-.033,1.36.3,2.015,2.421s2.34,1.862,2.34,1.862c.155-2.006-2.044-4.013-2.044-4.013a5.641,5.641,0,0,1,2.725,4.852,7.671,7.671,0,0,0-.421,4.173c-.265.067-.543.13-.832.182-3.024.538-5.747-1.963-5.747-1.963A11.317,11.317,0,0,0,41.942,56.6h0a11.392,11.392,0,0,0-.263-19.564ZM28.256,52.721a4.268,4.268,0,0,0,3.753-.053c.081.2.17.4.268.588a9.242,9.242,0,0,0,2.2,2.949,12.4,12.4,0,0,1-6.218-3.487Z"
          transform="translate(2853.151 9868.851)"
          fill={fill}
        />
        <path
          id="Path_4942"
          data-name="Path 4942"
          d="M46.193,46.576A11.425,11.425,0,0,0,42.88,38.92c-.026-.027-.053-.051-.076-.077l-.072-.072a11.054,11.054,0,0,0-2.312-1.735A11.176,11.176,0,0,0,35.345,35.6c-.06,0-.117,0-.177,0h-.033a11.314,11.314,0,0,0-9.99,5.556,11.362,11.362,0,0,0,5.4,16.3.613.613,0,0,1,.06.026c.05.022.1.041.153.062s.093.036.139.055l.1.036c.062.024.127.046.189.07.024.01.05.017.074.026.069.024.136.046.206.067l.093.029.179.055.033.01a11.427,11.427,0,0,0,1.905.374,10.635,10.635,0,0,1-3.392-1.3,12.7,12.7,0,0,1-1.135-.773l-.045-.036c-.019-.017-.041-.031-.06-.048l-.148-.122c-.187-.156-.366-.317-.538-.48a.159.159,0,0,0-.021-.019c-.086-.084-.172-.166-.253-.252a.032.032,0,0,1-.007-.01c-.072-.074-.141-.149-.21-.223l-.074-.082-.151-.173c-.022-.027-.043-.05-.067-.077-.065-.077-.127-.153-.189-.233l-.065-.082c-.055-.069-.108-.139-.16-.209l-.029-.038a11.291,11.291,0,0,1-1.489-2.837,10.983,10.983,0,0,1-.313-1.07,12.8,12.8,0,0,1-.294-2.69,10.73,10.73,0,0,1,2.847-7.108c.289-.3.583-.578.88-.833.033-.029.069-.058.1-.084.368-.29.763-.588,1.179-.859a10.144,10.144,0,0,1,2.228-1.049c.048-.017.1-.031.146-.046a.38.38,0,0,1,.041-.012,10.461,10.461,0,0,1,3.509-.437.607.607,0,0,1,.067,0,.3.3,0,0,1,.045,0c.418.038.791.077,1.143.127a10.871,10.871,0,0,1,2.7.821,11.03,11.03,0,0,1,4.353,3.588,10.833,10.833,0,0,1,1.915,4.951Z"
          transform="translate(2852.5 9868.851)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
