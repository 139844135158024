import React from "react";

export const CrimsonFlameIcon = ({
  width = 19.416,
  height = 23.099,
  fill = "#fff",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 19.416 23.099"
    >
      <g id="Group_309" data-name="Group 309" transform="translate(0)">
        <path
          id="Path_4820"
          data-name="Path 4820"
          d="M35.647,83.366c-1.39-1.118-3.725-1.81-4.81-3.345-1.269-1.8-1.276-4.714.644-6.141a4.274,4.274,0,0,0,.539,3.141A6.414,6.414,0,0,0,34.068,79a19.715,19.715,0,0,1,2.28,1.571,4.16,4.16,0,0,1,1.228,1.733,2.964,2.964,0,0,1,.1,2.09c-.286.625-1.533,2.014-2.368,1.93a1.779,1.779,0,0,0,.331-2.962Z"
          transform="translate(-21.506 -63.524)"
          fill={fill}
        />
        <path
          id="Path_4821"
          data-name="Path 4821"
          d="M31.335,80.476a26.238,26.238,0,0,0,2.516,1.365c3.425,1.523,1.879,2.708.885,3.129,1.109-1.18-.444-1.417-2.371-1.875a4.4,4.4,0,0,1-2.588-1.568,2.908,2.908,0,0,1-.394-3.158A4.177,4.177,0,0,0,31.335,80.476Z"
          transform="translate(-21.787 -62.083)"
          fill={fill}
        />
        <path
          id="Path_4822"
          data-name="Path 4822"
          d="M35.546,88.658c1.886-.139,4.1-1.42,4.71-3.213a6.731,6.731,0,0,0-.622-5.428c-1.455-2.509-3.139-2.988-3.7-4.789.01.012.019.024.029.038-.036-.1-.067-.194-.1-.287-.01-.041-.019-.084-.026-.127a2.706,2.706,0,0,1,.174-1.389,1.239,1.239,0,0,0,.253,1.066,2.487,2.487,0,0,1,.964-1.719c-.343.685.122,1.422.455,2.162a3.294,3.294,0,0,1,.064,1.812c.5-.192,1.178-1.666,1.178-1.666.565-.06.93-.907.935-1.97s-1.381-3.055-1.381-3.055c-1.111-2.1-.253-3.211-.253-3.211-1.13.67-.644,3.6-.644,3.6a5.885,5.885,0,0,0-2.8-1.679,8.5,8.5,0,0,1,2.156,2.083c-.3.239-2.046-.673-2.046-.673-.1.208.606.907.606.907a3.387,3.387,0,0,0-1.326.266,3.294,3.294,0,0,1-2.564.1c.305.881,1.55,1.1,1.55,1.1a2.947,2.947,0,0,1-2.115.1c.243.46,1.882.788,1.882.788s.007.022.017.057a3.808,3.808,0,0,0-.58,1.954c-.141,4.588,5.2,4.616,5.8,9.972.122,1.068-.909,2.4-2.614,3.2ZM38.2,73.94a4.356,4.356,0,0,0,.172-1.233,2.743,2.743,0,0,1,.115,1.693.5.5,0,0,0-.024.192,4.154,4.154,0,0,1-.26-.651Z"
          transform="translate(-21.156 -65.77)"
          fill={fill}
        />
        <path
          id="Path_4823"
          data-name="Path 4823"
          d="M37.641,66.973a3.314,3.314,0,0,0-.291,2.37,9.453,9.453,0,0,1,.775,2.617c-.463-1.257-.756-1-1.464-2.533,0,0-.362-.907.98-2.457Z"
          transform="translate(-19.366 -65.742)"
          fill={fill}
        />
        <path
          id="Path_4824"
          data-name="Path 4824"
          d="M29.966,88.32A6.866,6.866,0,0,1,26.4,85.4a6.22,6.22,0,0,1-.725-4.176c.258-3.168,1.457-6.682-.312-10.137.715,5.715-2.139,7.4-1.767,12.01.207,3.481,3.706,4.99,3.706,4.99A14.686,14.686,0,0,0,33.658,89.1,20.522,20.522,0,0,1,29.966,88.32Zm6.232.524c-6.6-1.386-6.8-5.564-5.161-9.546,1.734-4.209,3.5-9.429-.31-13.257,1.655,3.96.327,7.561-1.152,10.875a8.134,8.134,0,0,0-.46,1.185v.007c-.224.735-.441,1.48-.6,2.241a7.84,7.84,0,0,0,.646,5.787c.971,1.544,3.458,2.315,5.013,2.495m.93.3c-7.529-1.027-8.078-4.056-7-8.89.882-3.946,3.232-7.616.215-12.074.892,3.68-.3,6.4-1.378,9.264a15.132,15.132,0,0,0-.928,4.01v.022c-.391,4.844,2.926,6.816,6.4,7.37"
          transform="translate(-23.558 -66.041)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
