import React from "react";

export const ProgressBar = (props) => {
  const { width, height, progress = "10", delay = "2" } = props;
  return (
    <div className="mt-2">
      <div className="loader-border">
        <div className="loader" style={{ width: width, height: height }}>
          <div
            className="progress"
            style={{
              width: progress + "%",
              transition: "width " + delay + "s",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
