import React from "react";

export const DFTIcon = ({
  width = 13.489,
  height = 14.136,
  fill = "#fff",
  ...rest
}) => {
  return (
    <svg
      id="DFT"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13.489 14.136"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_6609"
            data-name="Rectangle 6609"
            width={width}
            height={height}
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_8889" data-name="Group 8889" clipPath="url(#clip-path)">
        <path
          id="Path_13016"
          data-name="Path 13016"
          d="M3.615,63.29a19.289,19.289,0,0,0-1.28-1.666,6.486,6.486,0,0,1-1.056-1.816C.857,58.915.446,58.018,0,57.056L2.216,55.59c.042.126.066.192.086.26q.883,2.977,1.761,5.956a.606.606,0,0,1-.009.349c-.129.368-.28.728-.439,1.134"
          transform="translate(0 -52.86)"
          fill={fill}
        />
        <path
          id="Path_13017"
          data-name="Path 13017"
          d="M144.987,87.011c-.116.84-.225,1.64-.338,2.44-.172,1.22-.353,2.44-.516,3.661-.023.171-.07.274-.241.306-.024,0-.046.019-.07.022a.521.521,0,0,0-.484.584.512.512,0,0,1-.016.1c0,.007-.011.012-.073.077,0-.269-.01-.481,0-.691a.432.432,0,0,0-.208-.429.352.352,0,0,1-.133-.254c.006-1.8.021-3.6.035-5.4a.887.887,0,0,1,.015-.1l2.025-.315"
          transform="translate(-135.894 -82.738)"
          fill={fill}
        />
        <path
          id="Path_13018"
          data-name="Path 13018"
          d="M52.19,59.188l-.645-.472-.206.486L49.529,53.1l2.108-.106.554,6.2"
          transform="translate(-47.097 -50.388)"
          fill={fill}
        />
        <path
          id="Path_13019"
          data-name="Path 13019"
          d="M113.5,94.481h1.237v5.466c-.367-.277-.683-.512-.993-.755a.237.237,0,0,1-.048-.158q-.108-2.215-.21-4.43a.884.884,0,0,1,.015-.123"
          transform="translate(-107.912 -89.841)"
          fill={fill}
        />
        <path
          id="Path_13020"
          data-name="Path 13020"
          d="M173.712,77.858c-.139.574-.271,1.11-.4,1.647-.351,1.467-.719,2.93-1.038,4.4a.708.708,0,0,1-.694.627c.057-.415.112-.824.17-1.232.205-1.463.417-2.925.614-4.39.087-.645.073-.648.694-.848.2-.065.4-.128.655-.207"
          transform="translate(-163.153 -74.035)"
          fill={fill}
        />
        <path
          id="Path_13021"
          data-name="Path 13021"
          d="M197.516,143.972l-.236-1.454-.446.121,1.536-3.224,1.231,1.568-2.084,2.988"
          transform="translate(-187.167 -132.57)"
          fill={fill}
        />
        <path
          id="Path_13022"
          data-name="Path 13022"
          d="M72.758,234.1l.417,2.64c-.456-.1-.88-.2-1.3-.295a.2.2,0,0,1-.116-.076c-.415-.652-.827-1.307-1.254-1.984l2.256-.285"
          transform="translate(-67.04 -222.608)"
          fill={fill}
        />
        <path
          id="Path_13023"
          data-name="Path 13023"
          d="M6.97,212.681l-3.914-2.086c.5-.286.964-.557,1.435-.82a.2.2,0,0,1,.169.031c.484.361.963.728,1.392,1.118a1.64,1.64,0,0,0,.117.376c.271.453.558.9.84,1.344Z"
          transform="translate(-2.906 -199.465)"
          fill={fill}
        />
        <path
          id="Path_13024"
          data-name="Path 13024"
          d="M2.295,4.569l-2.16,1.4,1.026-3.8.077-.021L2.295,4.569"
          transform="translate(-0.128 -2.037)"
          fill={fill}
        />
        <path
          id="Path_13025"
          data-name="Path 13025"
          d="M33.085,12.451a8.151,8.151,0,0,0,.84-.125.408.408,0,0,1,.456.174c.419.47.849.93,1.274,1.4.053.058.1.121.18.219-.662.031-1.28.063-1.9.086a.186.186,0,0,1-.146-.082c-.237-.544-.465-1.092-.707-1.667"
          transform="translate(-31.46 -11.705)"
          fill={fill}
        />
        <path
          id="Path_13026"
          data-name="Path 13026"
          d="M146.488,25.252l.519,2.818-1.974.31,1.409-3.135.046.008"
          transform="translate(-137.911 -24.004)"
          fill={fill}
        />
        <path
          id="Path_13027"
          data-name="Path 13027"
          d="M121.984,219.434c0,.853,0,1.646,0,2.438a.245.245,0,0,1-.067.168c-.254.216-.516.422-.8.656l-.306-1.931c0-.016,0-.033,0-.049-.028-.2-.138-.447-.06-.591s.343-.223.528-.323c.208-.111.419-.216.717-.368"
          transform="translate(-114.784 -208.658)"
          fill={fill}
        />
        <path
          id="Path_13028"
          data-name="Path 13028"
          d="M94.9,179.547c-.354-.444-.706-.855-1.017-1.294a1.31,1.31,0,0,1-.124-.534c-.036-.225-.056-.453-.09-.737l2.523,1.891-1.292.674"
          transform="translate(-89.073 -168.291)"
          fill={fill}
        />
        <path
          id="Path_13029"
          data-name="Path 13029"
          d="M214.366,177.475q.824-1.183,1.649-2.365c.14-.2.26-.517.439-.558.211-.048.477.147.718.237.03.011.06.025.119.051-.153.179-.3.35-.446.52-.423.487-.843.976-1.274,1.455-.062.069-.183.086-.275.129a1.408,1.408,0,0,0-.192.1c-.234.157-.465.319-.7.479l-.043-.046"
          transform="translate(-203.839 -165.973)"
          fill={fill}
        />
        <path
          id="Path_13030"
          data-name="Path 13030"
          d="M179.473,21.377l-1.246.406-.57-3.083.034-.018,1.782,2.7"
          transform="translate(-168.933 -17.765)"
          fill={fill}
        />
        <path
          id="Path_13031"
          data-name="Path 13031"
          d="M117.593,26.25c-.1.235-.209.471-.314.706-.334.744-.672,1.486-1,2.232a.232.232,0,0,1-.251.171c-.334-.011-.669,0-1.063,0l2.6-3.124.033.019"
          transform="translate(-109.317 -24.943)"
          fill={fill}
        />
        <path
          id="Path_13032"
          data-name="Path 13032"
          d="M232.217,123.172l-1.165-1.482,1.964-.763-.8,2.246"
          transform="translate(-219.706 -114.988)"
          fill={fill}
        />
        <path
          id="Path_13033"
          data-name="Path 13033"
          d="M157.17,247.8l-.757.81a5.221,5.221,0,0,0-.579.63c-.282.425-.695.407-1.155.416.308-.466.629-.889.88-1.351a.861.861,0,0,1,.937-.509c.207.021.417,0,.674,0"
          transform="translate(-147.083 -235.62)"
          fill={fill}
        />
        <path
          id="Path_13034"
          data-name="Path 13034"
          d="M38.25,180.67c-.489-.372-.952-.721-1.408-1.078a.227.227,0,0,1-.076-.182c.083-.4.179-.793.283-1.241l.416.549c.291.385.574.775.876,1.151a.34.34,0,0,1,.055.436,2.735,2.735,0,0,0-.146.364"
          transform="translate(-34.959 -169.42)"
          fill={fill}
        />
        <path
          id="Path_13035"
          data-name="Path 13035"
          d="M182.994,253.868c-.812.242-1.588.48-2.371.7-.093.026-.222-.078-.345-.126.534-.751,1.393-.914,2.08-1.392l.636.822"
          transform="translate(-171.424 -240.62)"
          fill={fill}
        />
        <path
          id="Path_13036"
          data-name="Path 13036"
          d="M69.728,206.25c.061-.788.7-1.086,1.19-1.529l.995,1.252-2.185.277"
          transform="translate(-66.304 -194.668)"
          fill={fill}
        />
        <path
          id="Path_13037"
          data-name="Path 13037"
          d="M146.5,228.623l-1.241,1.871-.287-.315c.319-.125.262-.384.285-.635.056-.61.151-1.217.229-1.819l1.014.9"
          transform="translate(-137.855 -216.542)"
          fill={fill}
        />
        <path
          id="Path_13038"
          data-name="Path 13038"
          d="M188.6,125.174a16.61,16.61,0,0,1,1.61-4.246,1.244,1.244,0,0,1,.039,1.075c-.509.975-.957,1.981-1.432,2.973a2.023,2.023,0,0,1-.159.24l-.058-.043"
          transform="translate(-179.34 -114.99)"
          fill={fill}
        />
        <path
          id="Path_13039"
          data-name="Path 13039"
          d="M.739,187.647l.121-1.836,1.325,1-1.446.833"
          transform="translate(-0.703 -176.686)"
          fill={fill}
        />
        <path
          id="Path_13040"
          data-name="Path 13040"
          d="M160.145,209.645l-.928,1.53-1.012-.885,1.9-.686.044.041"
          transform="translate(-150.436 -199.311)"
          fill={fill}
        />
        <path
          id="Path_13041"
          data-name="Path 13041"
          d="M258.882,126.951v2.411l-.816-.33.748-2.092.068.01"
          transform="translate(-245.393 -120.707)"
          fill={fill}
        />
        <path
          id="Path_13042"
          data-name="Path 13042"
          d="M183.708,211.47H182.44l1.008-1.658.26,1.658"
          transform="translate(-173.481 -199.509)"
          fill={fill}
        />
        <path
          id="Path_13043"
          data-name="Path 13043"
          d="M5.007,176.148l1.659-.3-.3,1.33-1.356-1.031"
          transform="translate(-4.761 -167.214)"
          fill={fill}
        />
        <path
          id="Path_13044"
          data-name="Path 13044"
          d="M225.337,115.947l-.2-.868,2.061.1.01.041-1.875.726"
          transform="translate(-214.086 -109.428)"
          fill={fill}
        />
        <path
          id="Path_13045"
          data-name="Path 13045"
          d="M228.484,241.5l-.941,1.269-.633-.814,1.54-.507.033.052"
          transform="translate(-215.768 -229.589)"
          fill={fill}
        />
        <path
          id="Path_13046"
          data-name="Path 13046"
          d="M226.733,228.509l.313-.978,1.475.345,0,.045-1.783.588"
          transform="translate(-215.599 -216.358)"
          fill={fill}
        />
        <path
          id="Path_13047"
          data-name="Path 13047"
          d="M200.713,234.137c.143-.156.3-.305.427-.47a1.791,1.791,0,0,0,.156-.307.315.315,0,0,1,.076-.115c.191-.137.387-.268.581-.4.034.857-.635,1.047-1.24,1.294"
          transform="translate(-190.857 -221.409)"
          fill={fill}
        />
        <path
          id="Path_13048"
          data-name="Path 13048"
          d="M245.174,243l-.579,1.472-.418-.194.962-1.3.035.02"
          transform="translate(-232.186 -231.049)"
          fill={fill}
        />
        <path
          id="Path_13049"
          data-name="Path 13049"
          d="M78.079,184.661l.563-1.428c.233.742.187.861-.563,1.428"
          transform="translate(-74.245 -174.235)"
          fill={fill}
        />
        <path
          id="Path_13050"
          data-name="Path 13050"
          d="M25.572,0,26.6.4c0,.014,0,.029,0,.043-.219.032-.437.071-.657.089-.051,0-.125-.057-.157-.108C25.718.311,25.666.186,25.572,0"
          transform="translate(-24.316)"
          fill={fill}
        />
        <path
          id="Path_13051"
          data-name="Path 13051"
          d="M215.408,273.379c.393-.119.782-.256,1.182-.345.128-.028.284.073.427.115l-.007.064-1.592.209-.01-.042"
          transform="translate(-204.83 -259.621)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
