import React from "react";

export const CopyIcon = ({
  width = 18.034,
  height = 18,
  fill = "#b4b4b4",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.034"
      height="18"
      viewBox="0 0 18.034 18"
      className={className}
    >
      <g id="copy" transform="translate(103.376 -97.906)" opacity="0.997">
        <path
          id="Path_149"
          data-name="Path 149"
          d="M-96.809,101.286A30.1,30.1,0,0,1-94.643,99a4.288,4.288,0,0,1,5.867.135,4.224,4.224,0,0,1,.084,5.783,68.375,68.375,0,0,1-4.861,4.887,4.3,4.3,0,0,1-5.56.222c-.591-.485-.915-1.071-.393-1.733.477-.6,1.113-.5,1.692-.06a1.974,1.974,0,0,0,2.718-.047c1.634-1.511,3.213-3.089,4.731-4.715a1.966,1.966,0,0,0-.013-2.788,1.869,1.869,0,0,0-2.684-.088,2.365,2.365,0,0,1-2.906.745A2.568,2.568,0,0,0-96.809,101.286Z"
          transform="translate(2.246)"
          fill="#b4b4b4"
        />
        <path
          id="Path_150"
          data-name="Path 150"
          d="M-94.379,110.873c-1.144,1.214-2.145,2.471-3.782,2.88a4.16,4.16,0,0,1-4.545-1.759,4.181,4.181,0,0,1,.3-4.952,58.629,58.629,0,0,1,4.864-4.89,4.3,4.3,0,0,1,5.32-.146c.608.462.967,1.03.511,1.717s-1.1.582-1.752.133a2.023,2.023,0,0,0-2.807.105c-1.472,1.378-2.9,2.807-4.279,4.282a1.982,1.982,0,0,0-.032,2.87,1.921,1.921,0,0,0,2.775-.006,1.863,1.863,0,0,1,2.223-.449A8.828,8.828,0,0,0-94.379,110.873Z"
          transform="translate(0 2.022)"
          fill="#b4b4b4"
        />
      </g>
    </svg>
  );
};
