import axios from "axios";
import { getAccessToken } from "../services/storage";
import { history } from "./history";
// import { useNavigate } from "react-router-dom";

const base_url = process.env.REACT_APP_BASE_URL;
// const base_url = "https://dummyjson.com/";

// const history = createBrowserHistory();

const axiosClient = axios.create({
  baseURL: base_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization: "Bearer " + getAccessToken(),
  },
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response.ok = true;
    return response;
  },
  function (error) {
    let res = error.response;
    /** Context */
    // const { errorModal, setErrorModal } = useContext(AppContext);
    // console.log(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let errorMessage = "Error Occur";
    if (error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      //eslint-disable-next-line
      errorMessage = error.message;
    }
    let errorStatus = "404";
    if (error.response.status) {
      errorStatus = error.response.status;
    } else {
      errorStatus = error.status;
    }
    if (errorStatus !== "401") {
      // user not found and user password 8 character
      if (errorStatus !== "422") {
        //storeErrorModal(errorMessage); //remove error dialog
      }
    }
    //storeErrorStatus(errorStatus);
    // window.dispatchEvent(new Event("storage"));
    // setErrorModal(true);

    if (error.response.status === 401) {
      history.push("/login");
      // const navigate = useNavigate();
      // navigate("/login");
      // return false;
    }
    // else {
    //   res.ok = false;
    //   return res;
    // }
    return Promise.reject(error);
  }
);

export async function fetchData(param) {
  // try { // temporary-update 
  const { method = "get", url, data = {}, params = {} } = param;
  return await axiosClient({
    method,
    url,
    withCredentials: false,
    data,
    params,
    headers: {
      Authorization: "Bearer " + getAccessToken(),
    },
  });
  // } catch (error) {
  //   throw new Error(error.message, { cause: error.response });
  // }
}

export async function getData(url = "") {
  return await axiosClient({
    method: "get",
    url,
    withCredentials: false,
    headers: {
      Authorization: "Bearer " + getAccessToken(),
    },
  });
}

export async function fetchFormData(param) {
  const { method = "post", url, data } = param;
  return await axiosClient({
    method,
    url,
    withCredentials: false,
    data,
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      "Content-Type": "multipart/form-data",
    },
  });
}
