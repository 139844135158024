import { useEffect,React, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { Button } from "../components/Button";
import { TextInput } from "../components/Form";
import InputImage from "../assets/images/input_name.png";
import Union from "../assets/icons/union.svg";
import HeavenlyDrew from "../assets/icons/elements/heavenly_dew.svg";
import CrimsonFlame from "../assets/icons/elements/crimson_flame.svg";
import GolddenLight from "../assets/icons/elements/goldden_light.svg";
import EternalTree from "../assets/icons/elements/eternal_tree.svg";
import PeerlessSteel from "../assets/icons/elements/peerless_steel.svg";
import UnfathomableAbyss from "../assets/icons/elements/unfathomable_abyss.svg";
import AdamantineRock from "../assets/icons/elements/adamantine_rock.svg";
import { ConfirmModal, InfoModal, LoadingModal } from "../components/Modal";
import ChooseGroupModal from "../components/common/ChooseGroupModal";
import ChooseTypeModal from "../components/common/ChooseTypeModal";
import { useQuery } from "react-query";
import { fetchData,getData } from "../services/fetch";
import { useDispatch, useSelector } from "react-redux";
import { setGroup, setGroupId, setType } from "../slices/publish";
import { AppContext } from "../context/appContext";

/** Content Body */
const Group = () => {
  const dispatch = useDispatch();
  /** States */
  const [copyInfoModal, setCopyInfoModal] = useState(false);
  const [chooseGroupModal, setChooseGroupModal] = useState(false);
  const [chooseTypeModal, setChooseTypeModal] = useState(false);
  const [numTokens, setNumTokens] = useState("");
  const [animaAmount, setAnimaAmount] = useState("");
  const [element, setElement] = useState(1);
  const [weights, setWeights] = useState(["", "", "", "", ""]);
  const [group, setGroup] = useState([]);
  const [type, setType] = useState([]);
  const { typeId, groupId } = useSelector(
    (state) => state.publish
  );
  const [parameters, setParameters] = useState([]);
  const [tokenIds, setTokenIds] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [myAnimaAmount, setMyAnimaAmount] = useState();

  const [errors, setErrors] = useState({});
  const [errorTypes, setErrorTypes] = useState({});
  const [dataTypeErrors, setDataTypeErrors] = useState([]);

  const [attributes, setAttributes] = useState([
    {
      id: 1,
      icon: HeavenlyDrew,
      text: "天の雫",
      is_selected: true,
    },
    {
      id: 2,
      icon: CrimsonFlame,
      text: "紅蓮の焔",
      is_selected: false,
    },
    {
      id: 3,
      icon: GolddenLight,
      text: "黄金の光",
      is_selected: false,
    },
    {
      id: 4,
      icon: EternalTree,
      text: "常磐なる樹",
      is_selected: false,
    },
    {
      id: 5,
      icon: PeerlessSteel,
      text: "無双の鋼",
      is_selected: false,
    },
    {
      id: 6,
      icon: UnfathomableAbyss,
      text: "底知れぬ深淵",
      is_selected: false,
    },
    {
      id: 7,
      icon: AdamantineRock,
      text: "堅牢な岩",
      is_selected: false,
    },
  ]);
  const handleSelectAttribute = (index) => {
    attributes[index].is_selected = true; //change true status to selected attribute
    //change false to not selected attributes
    attributes.forEach((attribute, i) => {
      if (i !== index) {
        attribute.is_selected = false;
      }
    });
    //update attributes array
    setAttributes([...attributes]);

    //set element by selected attribute id for request body
    setElement(attributes[index].id);
  };
  
  useQuery({
    queryKey: "get-my-anima-balance",
    queryFn: () =>
      fetchData({ url: "api/mybalance" }).then((response) => response.data),
    onSuccess: (response) => {
      setMyAnimaAmount(response.balance);
    },
    error:()=>{
      setMyAnimaAmount();
    }
  });

  const fetchGroupData = async () => {
    const response = await getData( "api/persona_group/" + groupId);
    if (response.ok){
      setGroup(response.data);
      setParameters(
        response.data.parameters.map((p) => {
          return {
            parameter_name: p.parameter_name,
            data_type: p.data_type,
            parameter_no: p.parameter_no,
            value: "",
          };
        })
      );
    }
    else {
      setGroup([]);
    }
  }
  const fetchTypeData = async () => {
    const response = await getData( "api/persona_type/" + typeId);
    if (response.ok){
      setType(response.data);
      dispatch(setGroupId(response.data.group_id));
    }
    else {
      setType([]);
    }
  }
  useEffect(() => {
    if(groupId){
      fetchGroupData();
    }
    if(typeId){
      fetchTypeData();
    }
    else {
      setType([]);
    }
  }, [groupId, typeId]);
  /** Functions */
  const onchangetype = (group) => {
    setType([]);
    console.log(typeId);
    dispatch(setGroupId(group.id));
  };

  const handleUpdateWeight = (index, value) => {
    const updatedWeights = [...weights];
    updatedWeights[index] = +value;
    setWeights(updatedWeights);
  };

  const includedKeysForIntegerInput = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "Backspace",
  ];
  const includedKeysForFloatInput = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    ".",
    "Backspace",
  ];
  const handleOnChangeParameter = (event, index, value) => {
    const updatedParameters = [...parameters];
    const updatedDataTypeErrors = [...dataTypeErrors];
    let error_types = {};
    error_types["parameters"] = [];
    var obj = {
      id: "",
      parameter_name: "",
    };
    if (value === "") {
      obj.id = index;
      obj.parameter_name = "required";
      error_types["parameters"].push(obj);
      updatedDataTypeErrors[index] = "";
      setDataTypeErrors(updatedDataTypeErrors);
    }
    else {
      if (updatedParameters[index].data_type === "string") {
        updatedParameters[index].value = value;
        updatedDataTypeErrors[index] = "";
        setDataTypeErrors(updatedDataTypeErrors);
      } else if (updatedParameters[index].data_type === "float") {
        const floatnumber = +value;
        if (floatnumber) {
        //if (!isNaN(value)) {
          console.log(floatnumber);
          updatedParameters[index].value = +floatnumber;
          updatedDataTypeErrors[index] = "";
          setDataTypeErrors(updatedDataTypeErrors);
        } else {
          updatedDataTypeErrors[index] = `実数 で入力してください。`; // remove type because japanese keyboard number error
          setDataTypeErrors(updatedDataTypeErrors);
        }
      } else {
        // integer condition
        updatedDataTypeErrors[index] = "";
        updatedParameters[index].value = +value;
        setDataTypeErrors(updatedDataTypeErrors);
      }
    }
    setParameters(updatedParameters);
  };

  //validation
  const hasValidateError = () => {
    let errors = {};
    let error_types = {};
    errors["weights"] = [];
    error_types["weights"] = [];
    errors["parameters"] = [];
    error_types["parameters"] = [];
    parameters.forEach((paramter, i) => {
      var obj = {
        id: "",
        parameter_name: "",
      };
      if (paramter.value === "" || paramter.value === 0) {
        obj.id = i;
        obj.parameter_name = "required";
        error_types["parameters"].push(obj);
      }
    });
    weights.forEach((weight, i) => {
      if (weight === "") {
        error_types.weights[i] = "required";
      } else if (weight > 1000) {
        errors.weights[i] = "スコアは0-0000まで設定可能です";
      }
    });

    if (!type) {
      error_types.type = "required";
    }

    if (!numTokens) {
      error_types.numTokens = "required";
    }

    if (!animaAmount) {
      error_types.animaAmount = "required";
    }

    if (errors.weights.length === 0) {
      delete errors.weights;
    }
    if (errors.parameters.length === 0) {
      delete errors.parameters;
    }
    if (error_types.weights.length === 0) {
      delete error_types.weights;
    }

    if (error_types.parameters.length === 0) {
      delete error_types.parameters;
    }

    setErrors(errors);
    setErrorTypes(error_types);
    if (Object.keys(errors).length > 0 || Object.keys(error_types).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkParameterValidation = (id) => {
    if (dataTypeErrors[id]) {
      return true;
    } else if (
      errorTypes &&
      errorTypes.parameters &&
      errorTypes.parameters.find((v) => v.id === id)
    ) {
      return errorTypes.parameters.find((v) => v.id === id);
    } else {
      return false;
    }
  };
  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        setpaddingtop={20}
        setheighttitle={'true'}
        title={<Title />}
        // title={"ペルリア生成"}
        topSpace={
          <TopSpace
            type={type}
            numTokens={numTokens}
            animaAmount={animaAmount}
            element={element}
            weights={weights}
            setTokenIds={setTokenIds}
            tokenIds={tokenIds}
            hasValidateError={hasValidateError}
            errors={errors}
            group={group}
            parameters={parameters}
          />
        }
      >
        {/* body */}
        <div className="text-white ps-2" style={{ paddingBottom: 10 }}>
          {/* Top Select Box */}
          <div className="d-flex" style={{  gap: "110px" }}>
            {/* Group Select */}
            <div className="d-flex" style={{ gap: "32px" }}>
              <div className="fs-20-px font-notosans-jp d-flex align-items-center">
                グループ
              </div>
              <div className="position-relative">
                <TextInput
                  onClick={() => setChooseGroupModal(true)}
                  className="h-40-px w-230-px "
                  placeholder="グループ選択"
                  required
                  value={
                    group.group ? group?.group?.name : group?.name
                  }
                  inputClass="w-90 pe-4 center-placeholder"
                />
                <div
                  className="dropdown-icon position-absolute top-50 translate-middle"
                  style={{ right: 15 }}
                ></div>
              </div>
            </div>
            {/* Top Select */}
            <div className="d-flex" style={{ gap: "32px" }}>
              <div className="fs-20-px font-notosans-jp d-flex align-items-center">
                タイプ
              </div>
              <div className="position-relative">
                <TextInput
                  className="h-40-px w-230-px "
                  placeholder="タイプ選択"
                  required
                  inputClass=" w-90 pe-4 h-95 center-placeholder"
                  onClick={() => setChooseTypeModal(true)}
                  value={type?.persona_type_name ? type.persona_type_name :""}
                  isError={errorTypes && errorTypes.type && true}
                />
                <div
                  className="dropdown-icon position-absolute top-50 translate-middle"
                  style={{ right: 15 }}
                ></div>
              </div>
            </div>
          </div>
          {/* End Top Select Box */}
          {/* Custom Parameter */}
          {(group.group?.name || type.persona_type_name) && (
            <>
              <div className="d-flex" style={{ marginTop: "42px" }}>
                <div style={{ width: "68%" }}>
                  <div className="fs-20-px font-notosans-jp">
                    カスタムパラメーターリスト
                  </div>
                  <div className="mt-30-px" style={{ marginLeft: "62px" }}>
                    {group
                      ? group?.parameters?.map((parameter, i) => (
                          <div
                            className="d-flex w-100"
                            style={{ marginBottom: "30px" }}
                            key={i}
                          >
                            <div
                              className="text-word-wrap w-20 font-notosans-jp fs-18-px"
                              style={{ marginTop: "-1%" }}
                            >
                              {parameter.parameter_name}
                            </div>
                            <div className="w-40 me-4">
                              <TextInput
                                className="h-40-px w-230-px mt-n2 text-start ms-4"
                                borderColor="hsla(0, 3%, 46%, 0.48)"
                                value={parameter.value}
                                onKeyDown={(e) => {
                                  // check if the data type is not string
                                  if (parameter.data_type !== "string") {
                                    // only allow [1-9] keys
                                    let check = /^\d+(\.\d+)?$/.test(e.key);
                                    const updatedDataTypeErrors = [
                                      ...dataTypeErrors,
                                    ];
                                    if (parameter.data_type === "integer") {
                                      (!includedKeysForIntegerInput.includes(
                                        e.key
                                      ) ||
                                        e.key === ".") &&
                                        e.preventDefault();
                                    }
                                    if (parameter.data_type === "float") {
                                      !includedKeysForFloatInput.includes(
                                        e.key
                                      ) && e.preventDefault();
                                    }
                                    if (!check) {
                                      updatedDataTypeErrors[
                                        i
                                      ] = `${ParameterType({
                                        type: parameters[i].data_type,
                                      })} で入力してください。`;
                                      setDataTypeErrors(updatedDataTypeErrors);
                                    } else {
                                      updatedDataTypeErrors.splice(i, 1);
                                      setDataTypeErrors(updatedDataTypeErrors);
                                    }
                                  }
                                }}
                                onChange={(e) =>
                                  handleOnChangeParameter(e, i, e.target.value)
                                }
                                required
                                // pattern={
                                //   parameter.data_type === "integer"
                                //     ? /^[1-9]+\d*$/
                                //     : parameter.data_type === "float"
                                //     ? /^\d+(\.\d+)?$/
                                //     : ""
                                // }
                                isError={checkParameterValidation(i)}
                                errorMessage={
                                  dataTypeErrors[i] && (
                                    <div className="fs-15-px">
                                      {dataTypeErrors[i]}
                                    </div>
                                  )
                                }
                              />
                            </div>
                            <div
                              className="w-20  font-notosans-jp fs-18-px ps-2"
                              style={{ marginTop: "-1%" }}
                            >
                              <ParameterType
                                type={parameter.data_type}
                              ></ParameterType>
                            </div>
                            <div
                              className="w-15  font-notosans-jp fs-18-px ps-2"
                              style={{ marginTop: "-1%" }}
                            >
                              {parameter.unit}
                            </div>
                          </div>
                        ))
                      : [...Array(6)].map((e, i) => (
                          <div
                            className="d-flex w-100"
                            style={{ marginBottom: "30px" }}
                            key={i}
                          >
                            <div
                              className="w-20 font-notosans-jp fs-18-px"
                              style={{ marginTop: "-1%" }}
                            >
                              Parameter {i + 1}
                            </div>
                            <div className="w-40 me-4">
                              <TextInput
                                className="h-40-px w-230-px mt-n2 text-start ms-4"
                                borderColor="hsla(0, 3%, 46%, 0.48)"
                                required
                              />
                            </div>
                            <div
                              className="w-20  font-notosans-jp fs-18-px ps-2"
                              style={{ marginTop: "-1%" }}
                            >
                              Data type
                            </div>
                            <div
                              className="w-15  font-notosans-jp fs-18-px ps-2"
                              style={{ marginTop: "-1%" }}
                            >
                              Unit
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
                <div className="" style={{ width: "32%" }}>
                  <div className="fs-20-px font-notosans-jp">プレビュー</div>
                  <div
                    className="mt-3 image-box"
                    style={{ height: "350px", width: "300px" }}
                  >
                    <img
                      height="100%"
                      width="100%"
                      src={type ? type.image_uri : InputImage}
                      alt="InputImage"
                      className="upload-image"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {/* End Custom Parameter */}
          {/* Base Parameter */}
          <div
            className={`${
              group.group?.name || type.persona_type_name ? "mt-4" : "mt-5"
            }`}
          >
            <div className="fs-20-px font-notosans-jp">ベースパラメーター</div>
            <div
              className="d-flex gap-4 mt-3 font-europa-regular fs-20-px"
              style={{ marginLeft: "33px" }}
            >
              <div>
                <div style={{ marginLeft: "13%" }}>FOR</div>
                <TextInput
                  onChange={async (e) => {
                    if (!isNaN(e.target.value)) {
                      handleUpdateWeight(0, e.target.value);
                    }
                  }}
                  isError={
                    errorTypes &&
                    errorTypes.weights &&
                    errorTypes.weights[0] &&
                    true
                  }
                  errorClass="error-left-align"
                  errorMessage={
                    errors &&
                    errors.weights &&
                    errors.weights[0] && (
                      <div className="fs-15-px">{errors.weights[0]}</div>
                    )
                  }
                  value={weights[0]}
                  className="h-40-px"
                  required
                  width="110px"
                />
              </div>
              <div>
                <div style={{ marginLeft: "13%" }}>ABS</div>
                <TextInput
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleUpdateWeight(1, e.target.value);
                    }
                  }}
                  className="h-40-px"
                  required
                  width="110px"
                  errorClass="error-left-align"
                  isError={
                    errorTypes &&
                    errorTypes.weights &&
                    errorTypes.weights[1] &&
                    true
                  }
                  errorMessage={
                    errors &&
                    errors.weights &&
                    errors.weights[1] && (
                      <div className="fs-15-px">{errors.weights[1]}</div>
                    )
                  }
                  value={weights[1]}
                />
              </div>
              <div>
                <div style={{ marginLeft: "13%" }}>DFT</div>
                <TextInput
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleUpdateWeight(2, e.target.value);
                    }
                  }}
                  isError={
                    errorTypes &&
                    errorTypes.weights &&
                    errorTypes.weights[2] &&
                    true
                  }
                  className="h-40-px"
                  required
                  width="110px"
                  errorClass="error-left-align"
                  errorMessage={
                    errors &&
                    errors.weights &&
                    errors.weights[2] && (
                      <div className="fs-15-px">{errors.weights[2]}</div>
                    )
                  }
                  value={weights[2]}
                />
              </div>
              <div>
                <div style={{ marginLeft: "13%" }}>MND</div>
                <TextInput
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleUpdateWeight(3, e.target.value);
                    }
                  }}
                  value={weights[3]}
                  className="h-40-px"
                  required
                  width="110px"
                  isError={
                    errorTypes &&
                    errorTypes.weights &&
                    errorTypes.weights[3] &&
                    true
                  }
                  errorMessage={
                    errors &&
                    errors.weights &&
                    errors.weights[3] && (
                      <div className="fs-15-px">{errors.weights[3]}</div>
                    )
                  }
                />
              </div>
              <div>
                <div style={{ marginLeft: "13%" }}>INT</div>
                <TextInput
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      handleUpdateWeight(4, e.target.value);
                    }
                  }}
                  value={weights[4]}
                  className="h-40-px"
                  errorClass="error-left-align"
                  isError={
                    errorTypes &&
                    errorTypes.weights &&
                    errorTypes.weights[4] &&
                    true
                  }
                  errorMessage={
                    errors &&
                    errors.weights &&
                    errors.weights[4] && (
                      <div className="fs-15-px">{errors.weights[4]}</div>
                    )
                  }
                  width="110px"
                  // required
                />
              </div>
            </div>
          </div>
          {/* End Base Parameter */}
          {/* Issued */}
          <div className="" style={{ marginTop: "45px" }}>
            <div className="fs-22-px font-notosans-jp">発行数</div>
            <div
              className="d-flex font-notosans-jp fs-20-px mt-n3"
              style={{ marginLeft: "33px" }}
            >
              <div className="d-flex align-items-end">
                <TextInput
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setNumTokens(e.target.value);
                    }
                  }}
                  isError={errorTypes && errorTypes.numTokens && true}
                  className="w-230-px h-40-px"
                  required
                  value={numTokens}
                />
              </div>
              <div className="d-flex align-items-end">
                <img src={Union} alt="Union" />
              </div>
              <div className="d-flex flex-column gap-2">
                <div style={{ marginLeft: "5.5%" }}>必要アニマ数</div>
                <TextInput
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setAnimaAmount(e.target.value);
                    }
                  }}
                  isError={errorTypes && errorTypes?.animaAmount && true}
                  className="w-230-px h-40-px"
                  required
                  value={animaAmount}
                />
              </div>
              <div className="d-flex flex-column ms-60-px gap-2">
                <div style={{ marginLeft: "5.5%" }}>保有アニマ数</div>
                <TextInput
                  className="w-230-px h-40-px"
                  value={myAnimaAmount}
                  isError={false}
                  errorMessage={
                    myAnimaAmount === 0 ? (
                      <div className="fs-15-px">アニマは不足しています。</div>
                    ) : (
                      ""
                    )
                  }
                  // required
                />
              </div>
            </div>
          </div>
          {/* End Issued */}
          {/* Attributes/Elements */}
          <div className="mt-5">
            <div className="fs-20-px font-notosans-jp">属性</div>
            <div
              className="mt-4 font-notosans-jp"
              style={{ marginLeft: "33px" }}
            >
              <div className="row">
                {attributes.map((attribute, index) => (
                  <div
                    className="col-4 d-flex gap-2"
                    style={{ marginBottom: "28px" }}
                  >
                    <div
                      onClick={() => handleSelectAttribute(index)}
                      className={`${
                        attribute.is_selected ? "check-icon" : "uncheck-icon"
                      } `}
                      style={{ marginTop: "0.7%" }}
                    ></div>
                    <div>
                      <img src={attribute.icon} alt={attribute.text} />
                    </div>
                    <div className="fs-20-px ms-2">{attribute.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End Attributes */}
          {/* Commented this because the tester sis asked me to */}
          {/* Ability */}
          {/* <div className="mt-2">
          //   <div className="fs-20-px font-notosans-jp">能力</div>
          //   <div className="mt-4" style={{ marginLeft: "35px" }}>
          //     <div className="row">
          //       <div className="col-4">
          //         <TextInput className="w-300-px h-40-px" required />
          //       </div>
          //       <div className="col-4">
          //         <TextInput className="w-300-px h-40-px" required />
          //       </div>

          //       <div className="col-4">
          //         <TextInput className="w-300-px h-40-px" required />
          //       </div>
          //     </div>
          //     <div className="row mt-4">
          //       <div className="col-4">
          //         <TextInput className="w-300-px h-40-px" required />
          //       </div>
          //       <div className="col-4">
          //         <TextInput className="w-300-px h-40-px" required />
          //       </div>

          //       <div className="col-4">
          //         <TextInput className="w-300-px h-40-px" required />
          //       </div>
          //     </div>
          //     <div className="row mt-80-px d-flex justify-content-center">
          //       <Button text="登録" className="w-230-px h-45-px ms-2" />
          //     </div>
          //   </div>
          // </div> */}
          {/* End Ability */}
          {/* // Copy Boxes */}
          {tokenIds.length > 0 && (
            <div style={{ marginLeft: "35px", marginTop: 80 }}>
              <div className="d-flex font-notosans-jp fs-22-px gap-4">
                <div className="fs-20-px w-300-px ms-2">トークンID</div>
              </div>
              <div
                className="overflow-auto hide-scroll dynamic-grid-container"
                style={{ height: "195px", marginTop: "2%" }}
              >
                {[...Array(9)].map((tokenId, i) => (
                  <div
                    className={`d-flex gap-4`}
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="position-relative w-300-px">
                      <TextInput value={tokenId} className="h-40-px" required />
                      <div
                        onClick={() => {
                          navigator.clipboard.writeText(tokenId); //copy to clipboard
                          setCopyInfoModal(true); //show copy sucessful dialog
                        }}
                        className="copy-icon position-absolute top-50 translate-middle"
                        style={{ right: "10px" }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* End Copy Boxes */}
          {/*Copy Modals */}
          <InfoModal
            isOpen={copyInfoModal}
            onAction={() => setCopyInfoModal(false)}
          >
            クリップボードにコピーしました。
          </InfoModal>
          {/* End Copy Modals */}

          {/* Choose Group Modal */}
          <ChooseGroupModal
            isChooseGroup={true}
            onchangetype={onchangetype}
            oldgroup={group}
            setGroup={setGroup}
            isOpen={chooseGroupModal}
            setType={setType}
            onClick={() => {
              setChooseGroupModal(false);
            }}
            onClose={() => setChooseGroupModal(false)}
          />
          {/* End Choose Group Modal */}
          {/* Choose Type Modal */}
          <ChooseTypeModal
            isChooseType={true}
            setType={setType}
            isOpen={chooseTypeModal}
            onClick={() => {
              setChooseTypeModal(false);
            }}
            onClose={() => setChooseTypeModal(false)}
          />
          {/* End Choose Type Modal */}
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
const Title = () => {
  return <div className="ms-10-px mt-1">ペルリア生成</div>;
};

export const ParameterType = (props) => {
  const type = props.type;
  if (type === "integer") {
    return "数値";
  } else if (type === "string") {
    return "文字列";
  } else if (type === "float") {
    return "実数";
  }
};
/** Top Space */
const TopSpace = (props) => {
  const {
    type,
    numTokens,
    animaAmount,
    element,
    weights,
    setTokenIds,
    // tokenIds,
    hasValidateError,
    errors,
    // group,
    parameters,
  } = props;
  /** react */
  // const navigate = useNavigate();

  /** States */
  const [generateConfirmModal, setGenerateConfirmModal] = useState(false);
  const [generateLoadingModal, setGenerateLoadingModal] = useState(false);
  const { setUnexpectedErrorModal } = useContext(AppContext);
  const [progress, setProgress] = useState(10);

  /** Functions */
  const handleGenerate = async (id) => {
    // console.log("Parameters=========");
    // console.log(parameters);
    // return;
    let hasError = hasValidateError();
    console.log(errors);
    if (hasError) {
      setGenerateConfirmModal(false);
      return;
    }
    setGenerateConfirmModal(false);
    setGenerateLoadingModal(true);

    // alert("action generate");
    console.log("numTokens => ", numTokens);
    console.log("animaAmount => ", animaAmount);
    console.log("element => ", element);
    console.log("weights => ", weights);

    //set progress
    setProgress(10);
    // setTimeout(() => setProgress(40), 2000); //2s

    const hash = await getMetaData(); // call metadata api and get hash value
    setProgress(30);
    const mint_persona_token_response = await mintPersonaToken(hash); // call mint api and get token ids
    setProgress(70); //set 80% to progress
    await updateParameterValue(mint_persona_token_response.tokenIds.toString()); //update parameter value by custom metadata api
    setProgress(100);

    //end set progress
  };

  const getMetaData = async () => {
    let hash = "";
    await fetchData({
      url: "api/persona/metadata",
      method: "post",
      data: {
        name: type.persona_type_name,
        creator: "popo",
        image: type.hash,
        description: "",
      },
    })
      .then((res) => {
        hash = res.data.hash;
      })
      .catch((e) => {
        console.log(e);
        setGenerateConfirmModal(false);
        setGenerateLoadingModal(false);
        setUnexpectedErrorModal(true);
      });

    return hash;
  };

  const mintPersonaToken = async (hash) => {
    console.log("Hash => ", hash);
    const metaArray = [];
    let response = "";
    for (let i = 0; i < numTokens; i++) {
      metaArray.push(hash);
    }
    await fetchData({
      url: "api/persona/mint",
      method: "post",
      data: {
        persona_type_id: type.id,
        numTokens,
        animaAmount,
        element,
        weights,
        metadata: metaArray,
      },
    })
      .then((res) => {
        setGenerateLoadingModal(true);
        console.log(res.data.tokenIds);
        // setTokenIds([]);
        setTokenIds(res.data.tokenIds);
        response = res.data;
      })
      .catch((e) => {
        console.log(e);
        setGenerateConfirmModal(false);
        setGenerateLoadingModal(false);
        setUnexpectedErrorModal(true);
      });
    return response;
  };

  const updateParameterValue = (token_ids) => {
    fetchData({
      url: "api/persona/custom_update_multi",
      method: "put",
      data: {
        token_ids,
        version: 1,
        total_value_add: null,
        achievements: null,
        parameters,
      },
    }).catch((e) => {
      console.log(e);
      setGenerateConfirmModal(false);
      setGenerateLoadingModal(false);
      setUnexpectedErrorModal(true);
    });
  };

  const redirectGenerate = () => {
    setGenerateLoadingModal(false);
    // navigate("/unreleases");
  };

  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-end h-100">
      {/* Token Generation Button */}
      <Button
        onClick={() => setGenerateConfirmModal(true)}
        // onClick={() => setGenerateLoadingModal(true)}
        // onClick={() => {
        //   console.log("numTokens => ", numTokens);
        //   console.log("animaAmount => ", animaAmount);
        //   console.log("element => ", element);
        //   console.log("weights => ", weights);
        // }}
        text="トークン生成"
        // className="w-250-px"
        width="233px"
      />

      {/*Generate Modals */}
      <ConfirmModal
        isOpen={generateConfirmModal}
        onConfirm={() => handleGenerate()}
        onCancel={() => setGenerateConfirmModal(false)}
      >
        トークンを生成しますか?
      </ConfirmModal>
      <LoadingModal
        isOpen={generateLoadingModal}
        onAction={() => redirectGenerate()}
        progress={progress}
      ></LoadingModal>
      {/* End Generate Modals */}
    </div>
  );
};

export default Group;
