import { React } from "react";
import MaskIcon from "../assets/icons/mask_shading.svg";
import MaskVideo from "../assets/videos/mask_background.mp4";

const Mask = (props) => {
  const { crop, className, width, height, style, id, maskHeight, maskWidth } =
    props;
  return (
    <div
      className={`text-white position-relative ${className} mt-2`}
      style={style}
    >
      <video
        autoPlay
        muted
        loop
        id={id}
        width={width}
        height={height}
        className="position-absolute"
      >
        <source src={MaskVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div
        className={`text-white position-absolute left-0 top-0 ${
          crop ? "crop" : ""
        }`}
      >
        <img src={MaskIcon} alt="mask" width={maskWidth} height={maskHeight} />
      </div>
    </div>
  );
};

export default Mask;
