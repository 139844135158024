import React from "react";
import { Button } from "../components/Button";
import MessageBox from "../layouts/MessageBox";

const DeleteGp = () => {
    return (
        <div className="h-100-vh d-flex justify-content-center" style={{backgroundColor: "#343536", paddingTop: "300px"}}>
            <MessageBox className="mt-2" width="574px" height="330px">
            <div class="container-box mt-5 py-5">
                    <div class="row">
                        <h3 class="text-white px-2 d-flex fs-20-px justify-content-center">〇〇グループを削除しますか？</h3>
                    </div>
                    <div class="row button-row gap-5">
                        <div class="col-6 con-button">
                            <Button text="YES" width="173px" height="40px"/>
                        </div>
                        <div class="col-6 con-button">
                            <Button text="NO" width="173px" height="40px"/>
                        </div>
                    </div>
                </div>
            </MessageBox>
        </div>
    );
};

export default DeleteGp;