import React from "react";

export const PersonaIcon = ({
  width = 29.815,
  height = 21.579,
  stroke = "#f17b1b",
  fill = "#f17b1b",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 29.815 21.579"
    >
      <g
        id="Group_42166"
        data-name="Group 42166"
        transform="translate(-495 -935.21)"
      >
        <path
          id="Path_37481"
          data-name="Path 37481"
          d="M-7085.139-13009.684h8.828"
          transform="translate(7601.126 13962.059)"
          fill="none"
          stroke={stroke}
          strokeWidth="1.5"
          opacity="0.6"
        />
        <path
          id="Path_37482"
          data-name="Path 37482"
          d="M0,0H8.828"
          transform="translate(520.401 956.789) rotate(-90)"
          fill="none"
          stroke={stroke}
          strokeWidth="1.5"
          opacity="0.6"
        />
        <path
          id="persona"
          d="M23.14.5c-.021-.173-.048-.332-.069-.463-.007-.055-.1-.042-.1.014.014.6.235,1.486-.1,2.039-.511.822-1.575-.021-2.079-.428C19.837.885,18.9.014,17.585.014a5.227,5.227,0,0,0-3.44,1.555,5.889,5.889,0,0,0-1.361,1.417,1.916,1.916,0,0,1-.67.892,1.326,1.326,0,0,1-.345.131,1.324,1.324,0,0,1-.346-.131,2.013,2.013,0,0,1-.67-.892A6.027,6.027,0,0,0,9.391,1.569,5.257,5.257,0,0,0,5.951.014c-1.313,0-2.245.871-3.206,1.645-.5.408-1.568,1.251-2.079.428C.327,1.534.548.65.562.048c0-.055-.09-.069-.1-.014C.445.173.424.332.4.5a17.1,17.1,0,0,0,1.23,10.843,12.567,12.567,0,0,0,1.465,2.522,7.05,7.05,0,0,0,1.872,1.631c1.437.891,3.068,1.417,4.491,2.342a13.458,13.458,0,0,1,1.3,1.044,1.5,1.5,0,0,0,2.045,0,13.423,13.423,0,0,1,1.3-1.044c1.423-.919,3.054-1.444,4.491-2.342a7.05,7.05,0,0,0,1.872-1.631,12.565,12.565,0,0,0,1.465-2.522A17.19,17.19,0,0,0,23.14.5M14.255,12.93a2.367,2.367,0,0,1-.18-1.465,2.114,2.114,0,0,1,.891-1.527,5.906,5.906,0,0,1,2.984-1.285c1.016-.035,2.107.615,3.109.256a1.935,1.935,0,0,0,.815-.567,5.236,5.236,0,0,1-1.009,1.486,14.525,14.525,0,0,1-2.1,1.8,7.453,7.453,0,0,1-2.632,1.016,10.421,10.421,0,0,1-1.879.283m-4.967,0A9.452,9.452,0,0,1,7.4,12.64,7.452,7.452,0,0,1,4.77,11.624a14.933,14.933,0,0,1-2.1-1.8A5.05,5.05,0,0,1,1.661,8.334a1.875,1.875,0,0,0,.815.567c1,.359,2.093-.29,3.109-.256A5.99,5.99,0,0,1,8.569,9.931a2.159,2.159,0,0,1,.891,1.527,2.333,2.333,0,0,1-.173,1.472"
          transform="translate(495 935.211)"
          fill={fill}
          opacity="0.598"
        />
      </g>
    </svg>
  );
};
