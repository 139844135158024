import React from "react";
import MessageBox from "../layouts/MessageBox";
import { ProgressBar } from "../components/ProgressBar";
import { Button } from "../components/Button";

const LoadingBox = (props) => {
  const { children } = props;
  return (
    <div
      className="h-100-vh d-flex justify-content-center"
      style={{ backgroundColor: "#343536", paddingTop: "300px" }}
    >
      <MessageBox className="mt-2" width="572px" height="330px">
        <div class="container-box mt-5 px-2">
          <div class="row loading-row">
            <div class="mt-3 mb-3 title-text">
              <h5 class="app-text fs-20-px px-4 text-white d-flex justify-content-center">
                {children}
              </h5>
            </div>
            <div class="mb-4 d-flex justify-content-center">
              <ProgressBar width="370px" height="25px" />
            </div>
            <div className="row opacity-button mt-2 d-flex justify-content-center">
              <Button text="OK" width="173px" height="40px"></Button>
            </div>
          </div>
        </div>
      </MessageBox>
    </div>
  );
};

export default LoadingBox;
