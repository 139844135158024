export const isFloat = (value) => {
  if (typeof value === "number" && !isNaN(value)) {
    // return !Number.isInteger(value);
    return true; // aslo allow to enter integer.
  } else {
    return false;
  }
};

// Main function to distribute abilities
export function distributeAbilities(totalAbility, weights, MAX_ABILITY) {
  weights = weights.map((w) => Number(w)); // Change to number type
  let abilities = {
    values: [0, 0, 0, 0, 0, 0],
    totalAbility: 0,
    cachedTimestamp: Date.now(),
  };

  let sortedWeights = sortWeights(weights);
  abilities.values[0] = Math.floor(totalAbility / 6); // Initial FOR allocation
  totalAbility -= abilities.values[0];

  for (let i = 0; i < 4; i++) {
    let sumOfWeights = 0;
    for (let j = i; j < 5; j++) {
      sumOfWeights += sortedWeights[j].value;
    }

    if (sumOfWeights === 0) {
      abilities.values[sortedWeights[i].idx] = Math.floor(
        totalAbility / (5 - i)
      );
    } else {
      abilities.values[sortedWeights[i].idx] = Math.floor(
        (totalAbility * sortedWeights[i].value) / sumOfWeights
      );
    }

    if (abilities.values[sortedWeights[i].idx] > MAX_ABILITY) {
      abilities.values[sortedWeights[i].idx] = MAX_ABILITY;
    }
    totalAbility -= abilities.values[sortedWeights[i].idx];
  }

  abilities.values[sortedWeights[4].idx] = totalAbility;
  if (abilities.values[sortedWeights[4].idx] > MAX_ABILITY) {
    abilities.values[sortedWeights[4].idx] = MAX_ABILITY;
  }

  abilities.totalAbility = computeWeightedAbility(abilities.values, weights);
  return abilities;
}

// Helper function to sort weights
function sortWeights(weights) {
  let temp = [];
  let used = 0;
  for (let i = 0; i < 5; i++) {
    let v = 0;
    let ix = 0;
    let mask = 1;

    for (let j = 0; j < 5; j++) {
      if ((used & mask) === 0 && weights[j] >= v) {
        v = weights[j];
        ix = j;
      }
      mask <<= 1;
    }
    temp.push({ idx: ix + 1, value: v });
    used |= 1 << ix;
  }
  return temp;
}

// Helper function to compute weighted ability total
function computeWeightedAbility(abilities, abilityWeights) {
  let total = 0;
  for (let i = 0; i < 6; i++) {
    total += abilities[i] * abilityWeights[i];
  }
  return total;
}
