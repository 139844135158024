import React from "react";

export const UnrivaledSteelIcon = ({
  width = 22.605,
  height = 26.771,
  fill = "#fff",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 22.605 26.771"
    >
      <g id="Group_310" data-name="Group 310" transform="translate(0)">
        <path
          id="Path_4797"
          data-name="Path 4797"
          d="M31.626,104.762a.258.258,0,0,0-.019.043c.017.031.034.065.05.1a4.013,4.013,0,0,1,.4,1.755v3.17a2.571,2.571,0,0,1-.2,1c-.014.034-.029.065-.043.1,0,.008.007.017.012.025a2.133,2.133,0,0,1,.231.967l.074,4.645a5.248,5.248,0,0,0,.155,1.732,5.273,5.273,0,0,0,.15-1.741c0-1.169.074-4.3.074-4.641a2.106,2.106,0,0,1,.236-.977s0-.006,0-.006,0,0,0-.006a2.546,2.546,0,0,1-.236-1.078v-3.19a3.942,3.942,0,0,1,.332-1.606l.057-.122c.019-.042.04-.08.06-.118-.007-.014-.014-.028-.022-.045a.716.716,0,0,0-1.308,0Z"
          transform="translate(-20.842 -93.877)"
          fill={fill}
        />
        <path
          id="Path_4798"
          data-name="Path 4798"
          d="M33.152,103.206a.712.712,0,0,0-.057-.18.877.877,0,0,1-.1-.409.991.991,0,0,1,.062-.343.6.6,0,0,0,0-.412,1.038,1.038,0,0,1-.064-.349.988.988,0,0,1,.062-.343.589.589,0,0,0,0-.409,1.047,1.047,0,0,1-.064-.35.991.991,0,0,1,.062-.343.589.589,0,0,0,0-.41,1.052,1.052,0,0,1-.064-.35.873.873,0,0,1,.1-.41.618.618,0,0,0,.069-.283l-.535.391a.49.49,0,0,1-.575,0l-.534-.391a.611.611,0,0,0,.069.283.882.882,0,0,1,.1.41,1.048,1.048,0,0,1-.064.35.619.619,0,0,0,0,.41.987.987,0,0,1,.062.343,1.051,1.051,0,0,1-.064.35.616.616,0,0,0,0,.409.991.991,0,0,1,.062.343,1.049,1.049,0,0,1-.064.349.626.626,0,0,0,0,.412.988.988,0,0,1,.062.343.868.868,0,0,1-.1.409.63.63,0,0,0-.057.183c.229.026.491.062.795.108.317-.048.589-.085.826-.111Z"
          transform="translate(-20.893 -96.957)"
          fill={fill}
        />
        <path
          id="Path_4799"
          data-name="Path 4799"
          d="M32.732,103.1l-.019.032-.019-.032a2.211,2.211,0,0,0-.959-.86,6.82,6.82,0,0,1-.931,3.812l-.012.017c.706-.051,1.549,4.359.291,5.991l-.007.009.01.012a4.4,4.4,0,0,1,.749,2.376c.193,1.794-.074,4.479.895,7.319.962-2.823.666-5.5.861-7.3a4.392,4.392,0,0,1,.759-2.387l.017-.022-.017-.022c-1.277-1.646-.41-6.03.3-5.982l-.019-.026a6.811,6.811,0,0,1-.938-3.8,2.212,2.212,0,0,0-.959.86Zm-.04.391a.178.178,0,1,1-.177.177A.177.177,0,0,1,32.691,103.491Zm.021.738a.275.275,0,0,1,0,.551.275.275,0,1,1,0-.551Zm.659,1.66c.007.014.014.031.022.046-.019.038-.041.077-.06.117s-.038.078-.057.122a4,4,0,0,0-.332,1.606v3.19a2.507,2.507,0,0,0,.236,1.078.015.015,0,0,0,0,.008s0,0,0,0a2.107,2.107,0,0,0-.236.977c0,.34-.074,3.472-.074,4.641a5.266,5.266,0,0,1-.15,1.741,5.235,5.235,0,0,1-.155-1.732l-.074-4.645a2.1,2.1,0,0,0-.231-.967c0-.006-.007-.017-.012-.023l.043-.1a2.56,2.56,0,0,0,.2-1v-3.169a4.018,4.018,0,0,0-.227-1.343,4.124,4.124,0,0,0-.172-.414c-.017-.034-.033-.068-.05-.1a.349.349,0,0,0,.019-.043.715.715,0,0,1,1.307,0Z"
          transform="translate(-21.281 -95.006)"
          fill={fill}
        />
        <path
          id="Path_4800"
          data-name="Path 4800"
          d="M37.015,101.61c-1.5.278-.773-.223-2.832.012a.565.565,0,0,1,.014.111v.189H32.55v-.189a.8.8,0,0,1,.012-.108c-2.1-.243-1.358.265-2.863-.015a2.884,2.884,0,0,0,.9,2.93c-.348-1.431-.234-2.032.656-2.127a2.111,2.111,0,0,1,1.121.191v-.172c0-.275.255-.026.255-.155v-.029a2.574,2.574,0,0,0-.818-.255,12.774,12.774,0,0,1,1.305.142h.473c.535-.08.969-.125,1.305-.142a2.606,2.606,0,0,0-.821.255v.028c0,.126.255-.131.255.135v.192a2.112,2.112,0,0,1,1.121-.192c.89.1,1,.7.656,2.127a2.887,2.887,0,0,0,.9-2.929ZM30.1,103.3a2.05,2.05,0,0,1-.088-1.2,2.917,2.917,0,0,0,.394.028,2.054,2.054,0,0,0,.258-.017A.954.954,0,0,0,30.1,103.3Zm5.939-1.191a2.089,2.089,0,0,0,.258.017,3.567,3.567,0,0,0,.394-.028,2.05,2.05,0,0,1-.088,1.2A.954.954,0,0,0,36.044,102.112Z"
          transform="translate(-21.924 -95.373)"
          fill={fill}
        />
        <path
          id="Path_4801"
          data-name="Path 4801"
          d="M31.517,101.9h1.646v-.189a.536.536,0,0,0-.014-.109c-.236.026-.508.062-.826.109-.3-.045-.565-.082-.795-.108a.571.571,0,0,0-.012.108Z"
          transform="translate(-20.891 -95.35)"
          fill={fill}
        />
        <path
          id="Path_4802"
          data-name="Path 4802"
          d="M31.517,101.9h1.646v-.189a.536.536,0,0,0-.014-.109c-.236.026-.508.062-.826.109-.3-.045-.565-.082-.795-.108a.571.571,0,0,0-.012.108Z"
          transform="translate(-20.891 -95.35)"
          fill={fill}
        />
        <path
          id="Path_4803"
          data-name="Path 4803"
          d="M32.122,101.966l.064-.009c.06-.009.114-.017.171-.023h-.471c.057.009.112.014.171.023Z"
          transform="translate(-20.692 -95.171)"
          fill={fill}
        />
        <path
          id="Path_4804"
          data-name="Path 4804"
          d="M32.122,101.966l.064-.009c.06-.009.114-.017.171-.023h-.471c.057.009.112.014.171.023Z"
          transform="translate(-20.692 -95.171)"
          fill={fill}
        />
        <path
          id="Path_4805"
          data-name="Path 4805"
          d="M33.106,102.036v-.026a2.667,2.667,0,0,0-.723.537,2.6,2.6,0,0,0-.723-.537v.028c0,.131-.255-.118-.255.157v.172a2.2,2.2,0,0,1,.959.86l.019.031.019-.031a2.214,2.214,0,0,1,.959-.86v-.192c0-.268-.255-.012-.255-.137Z"
          transform="translate(-20.951 -95.131)"
          fill={fill}
        />
        <path
          id="Path_4806"
          data-name="Path 4806"
          d="M33.106,102.036v-.026a2.667,2.667,0,0,0-.723.537,2.6,2.6,0,0,0-.723-.537v.028c0,.131-.255-.118-.255.157v.172a2.2,2.2,0,0,1,.959.86l.019.031.019-.031a2.214,2.214,0,0,1,.959-.86v-.192c0-.268-.255-.012-.255-.137Z"
          transform="translate(-20.951 -95.131)"
          fill={fill}
        />
        <path
          id="Path_4807"
          data-name="Path 4807"
          d="M31.611,99.149h1.646v.043l.072-.053a.489.489,0,0,0,.177-.549l-.231-.716a.488.488,0,0,0-.465-.338h-.749a.491.491,0,0,0-.465.338l-.231.716a.5.5,0,0,0,.177.549l.072.053v-.043Zm.3-.568.122-.374a.221.221,0,0,1,.21-.153h.391a.223.223,0,0,1,.21.153l.122.374a.223.223,0,0,1-.079.247l-.317.232a.222.222,0,0,1-.258,0l-.317-.232a.218.218,0,0,1-.079-.247Z"
          transform="translate(-20.986 -97.537)"
          fill={fill}
        />
        <path
          id="Path_4808"
          data-name="Path 4808"
          d="M32.629,99.018l.534-.39v-.043H31.517v.043l.534.39a.489.489,0,0,0,.575,0Z"
          transform="translate(-20.891 -96.973)"
          fill={fill}
        />
        <path
          id="Path_4809"
          data-name="Path 4809"
          d="M32.629,99.018l.534-.39v-.043H31.517v.043l.534.39a.489.489,0,0,0,.575,0Z"
          transform="translate(-20.891 -96.973)"
          fill={fill}
        />
        <path
          id="Path_4810"
          data-name="Path 4810"
          d="M24.608,101.594s5.746,5.157,8.356,6.5a7.614,7.614,0,0,0-.4,5.9s-1.389-2.077-.422-5.708c0,0-6.342-5.007-7.535-6.688Z"
          transform="translate(-24.609 -95.354)"
          fill={fill}
        />
        <path
          id="Path_4811"
          data-name="Path 4811"
          d="M31.438,107.969l-.119.577a59.759,59.759,0,0,1-6.442-5.907,63.3,63.3,0,0,0,6.559,5.33Z"
          transform="translate(-24.465 -94.791)"
          fill={fill}
        />
        <path
          id="Path_4812"
          data-name="Path 4812"
          d="M31.141,108.633l-.072.583a56.779,56.779,0,0,1-5.941-5.525,53.772,53.772,0,0,0,6.013,4.945Z"
          transform="translate(-24.329 -94.226)"
          fill={fill}
        />
        <path
          id="Path_4813"
          data-name="Path 4813"
          d="M30.906,109.367l.024.611a51.946,51.946,0,0,1-5.564-5.231,52.422,52.422,0,0,0,5.541,4.619Z"
          transform="translate(-24.201 -93.658)"
          fill={fill}
        />
        <path
          id="Path_4814"
          data-name="Path 4814"
          d="M31.054,110.324l.215.791a55.9,55.9,0,0,1-5.753-5.41,52.431,52.431,0,0,0,5.54,4.619Z"
          transform="translate(-24.12 -93.142)"
          fill={fill}
        />
        <path
          id="Path_4815"
          data-name="Path 4815"
          d="M42.128,101.379s-5.519,5.4-8.07,6.854a7.624,7.624,0,0,1,.649,5.876s1.3-2.135.179-5.724C34.886,108.385,41.009,103.111,42.128,101.379Z"
          transform="translate(-19.523 -95.47)"
          fill={fill}
        />
        <path
          id="Path_4816"
          data-name="Path 4816"
          d="M34.969,108.042l.143.574a59.644,59.644,0,0,0,6.182-6.18,63.73,63.73,0,0,1-6.326,5.61Z"
          transform="translate(-19.033 -94.901)"
          fill={fill}
        />
        <path
          id="Path_4817"
          data-name="Path 4817"
          d="M35.107,108.694l.1.577a56.924,56.924,0,0,0,5.7-5.776A53.746,53.746,0,0,1,35.107,108.694Z"
          transform="translate(-18.959 -94.331)"
          fill={fill}
        />
        <path
          id="Path_4818"
          data-name="Path 4818"
          d="M35.213,109.416v.612a52,52,0,0,0,5.338-5.467A52.2,52.2,0,0,1,35.213,109.416Z"
          transform="translate(-18.902 -93.757)"
          fill={fill}
        />
        <path
          id="Path_4819"
          data-name="Path 4819"
          d="M35.168,110.38l-.179.8a55.69,55.69,0,0,0,5.517-5.654A52.2,52.2,0,0,1,35.168,110.38Z"
          transform="translate(-19.022 -93.238)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
