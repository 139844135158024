import React from "react";
import MessageBox from "../layouts/MessageBox";
import { Button } from "../components/Button";

const DeleteGpSucc = () => {
    return (
        <div className="h-100-vh d-flex justify-content-center"
        style={{ backgroundColor: "#343536", paddingTop: "300px" }}>
            <MessageBox className="mt-2" width="572px" height="330px">
                <div class="container-box mt-5 px-2">
                    <div class="row">
                        <div class="mt-5 mb-4">
                            <h5 class="fs-20-px px-4 text-white d-flex justify-content-center">〇〇グループが正常に削除されました。</h5>
                        </div>
                        <div class="mt-5 d-flex justify-content-center">
                            <Button text="OK" width="173px" height="40px"></Button>
                        </div>
                    </div>
                </div>
            </MessageBox>
        </div>
    );
};

export default DeleteGpSucc;