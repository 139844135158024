import { React, useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { TextInput } from "../components/Form";
import Button from "../components/Button";
import { ConfirmModal, InfoModal } from "../components/Modal";
import { fetchData } from "../services/fetch";
import { useQuery } from "react-query";
import { DeleteIcon } from "../components/common/Icon";
import { storeErrorModal } from "../services/storage";
import { AppContext } from "../context/appContext";
import { useNavigate } from "react-router-dom";

const GroupSetting = () => {
  // React
  const params = useParams();

  const _ = require("lodash");

  /** States */
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState(false);
  const [groupDetail, setGroupDetail] = useState();
  const [group, setGroup] = useState();
  const [parameters, setParameters] = useState();
  const [newParameters, setNewParameters] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [errors, setErrors] = useState();
  const [errorTypes, setErrorTypes] = useState();
  const [groupBorderColor, setGroupBorderColor] = useState();
  const [allselectboxclose, setAllselectboxclose] = useState(false);
  // const [validationStatus, setValidationStatus] = useState(false);
  // const validationStatusRef = useRef(validationStatus);

  // react-query
  // TODO: IMPLEMENT A LOADING STATUS
  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["get-group-detail"],
    queryFn: () => fetchData({ url: "api/persona_group/" + params.id }),
    onSuccess: (response) => {
      setGroupDetail(response.data);
      setGroup(response.data.group);
      setParameters(response.data.parameters);
      // alert("refetch");
      console.log("refetch");
      console.log(group);
    },
  });

  /** Functions */
  const handleDelete = () => {
    fetchData({
      url: "api/persona_group/" + group.id + "/group_parameter/" + deleteId,
      method: "delete",
    })
      .then((res) => {
        setDeleteConfirmModal(false);
        setDeleteInfoModal(true);
        // setErrors();
        // setErrorTypes();
        // refetch();
        const updated_parameters = parameters.filter(
          (parameter) => parameter.id !== deleteId
        );
        setParameters(updated_parameters);
      })
      .catch((error) => {
        console.log(error);
        storeErrorModal(error.message);
      });
  };

  const handleParameterChange = (e, index, id, field, value = null) => {
    let newParameters = [...parameters];
    if (value) {
      newParameters[index][field] = value;
    } else {
      newParameters[index][field] = e.target.value;
    }
    newParameters[index]["show_select_box"] = false;
    setParameters(newParameters);
    // console.log(parameters);
    // alert("hello");
  };

  const handleAddNewParameter = () => {
    let obj = {
      parameter_name: "",
      show_select_box: false,
      group_id: group.id,
      parameter_no: 0,
      min_value: 0,
      max_value: 0,
      data_type: "",
      unit: "",
    };
    // alert(newParameters.length + parameters.length);
    if (parameters.length + newParameters.length < 8) {
      setNewParameters((parameters) => [...parameters, obj]);
    }

    console.log(newParameters);
  };

  const handleNewParameterChange = (e, index, id, field, value = null) => {
    let parameters = [...newParameters];
    if (value) {
      parameters[index][field] = value;
    } else {
      parameters[index][field] = e.target.value;
    }
    parameters[index]["show_select_box"] = false;
    //setNewParameters(parameters);
    setNewParameters(parameters);
    // hasValidateError();
    // alert("hello");
  };

  const handleDeleteNewParameter = async (index) => {
    let parameters = [...newParameters];
    parameters.splice(index, 1);
    setNewParameters(parameters);
    errors?.new_parameters?.splice(index, 1);
    errorTypes?.new_parameters.splice(index, 1);

    console.log("DELETE ERRORS", parameters);
  };

  //Select Functions
  //show select box for old and new parameters
  const showParameterSelectBox = (i, isNewParameter = null) => {
    let parameter_array = isNewParameter ? [...newParameters] : [...parameters];
    let selected_box_condition = parameter_array[i]["show_select_box"];
    closeAllSelectBox();
    //set show select field true to array
    parameter_array[i]["show_select_box"] = !selected_box_condition;

    if (isNewParameter) {
      setNewParameters(parameter_array);
    } else {
      setParameters(parameter_array);
    }
  };

  const closeAllSelectBox = () => {
    let oldParas = [...parameters];
    let newParas = [...newParameters];

    oldParas.forEach((data) => (data["show_select_box"] = false));
    newParas.forEach((data) => (data["show_select_box"] = false));

    setNewParameters(newParas);
    setParameters(oldParas);
  };

  //select box component
  const DataTypeSelectBox = (props) => {
    const { i, isNew = false, id = null, isOld } = props;
    // if (isOld) {
    //   let oldparam = newParameters;
    //   oldparam.forEach((param, index) => {
    //     if (param.data_type) {
    //       oldparam[i]["show_select_box"] = false;
    //     }
    //     if (i != index) {
    //       oldparam[i]["show_select_box"] = false;
    //     }
    //   });
    //   setNewParameters(oldparam);
    // } else {
    //   let params = parameters;
    //   params.forEach((param, index) => {
    //     if (param.data_type) {
    //       params[i]["show_select_box"] = false;
    //     }
    //     if (i != index) {
    //       params[i]["show_select_box"] = false;
    //     }
    //   });
    //   setParameters(params);
    // }
    if (allselectboxclose) {
      return <></>;
    } else {
      return (
        <>
          <div className="position-absolute top-0 mt-40-px select-box text-center">
            <div
              onClick={() => handleSelect(i, id, isNew, "integer")}
              className="select-row"
            >
              数値
            </div>
            <div
              onClick={() => handleSelect(i, id, isNew, "string")}
              className="select-row"
            >
              文字列
            </div>
            <div
              onClick={() => handleSelect(i, id, isNew, "float")}
              className="select-row"
            >
              実数
            </div>
          </div>
        </>
      );
    }
  };

  //click select each list
  const handleSelect = (i, id, isNew, value) => {
    if (isNew) {
      handleNewParameterChange(null, i, id, "data_type", value);
      //showParameterSelectBox(i, true);
    } else {
      handleParameterChange(null, i, id, "data_type", value);
      //showParameterSelectBox(i);
    }
  };
  //End Select Functions

  // useEffect(() => {
  //   validationStatusRef.current = validationStatus;
  //   if (validationStatusRef.current) {
  //     hasValidateError(parameters, newParameters);
  //   }
  // }, [parameters, newParameters]);

  const constructErrorObject = () => {
    const err_obj = {};
    err_obj.parameter_name = "";
    err_obj.parameter_no = "";
    err_obj.parameters = [];
    err_obj.new_parameters = [];

    parameters?.forEach((parameter, i) => {
      const obj = {
        id: parameter.id,
        parameter_name: "",
        data_type: "",
        unit: "",
      };
      err_obj["parameters"].push(obj);
    });

    newParameters?.forEach((parameter, i) => {
      const obj = {
        id: i,
        parameter_name: "",
        data_type: "",
        unit: "",
      };
      err_obj["new_parameters"].push(obj);
    });

    return err_obj;
  };

  //validation
  const hasValidateError = () => {
    var err_obj = constructErrorObject();

    const errors = _.cloneDeep(err_obj);
    const errorTypes = _.cloneDeep(err_obj);

    // errors.new_parameters[0] = { a: "abc", b: "def" };
    // console.log("AA", errors);
    // return true;

    //group detail validation
    if (!group?.name) {
      errorTypes["name"] = "required";
    }
    if (!group?.group_no) {
      // errors["group_no"] = "Group No is required";
      errorTypes["group_no"] = "required";
    }
    //end group detail validation

    //parameter validations
    parameters?.forEach((parameter, i) => {
      //create object
      var obj = {
        id: parameter.id,
        parameter_name: "",
        data_type: "",
        unit: "",
      };
      //parameter name required
      if (!parameter.parameter_name) {
        obj.parameter_name = "必須項目です。";
      }
      //parameter name must be less than 100 characters
      if (parameter.parameter_name.length > 100) {
        obj.parameter_name = "100文字以内で入力して下さい";
      }
      errors["parameters"][i] = obj;
    });
    //end parameter validations

    //new parameter validations
    newParameters?.forEach((parameter, i) => {
      //create object
      var obj = {
        id: i,
        parameter_name: "",
        data_type: "",
        unit: "",
      };
      //parameter name required
      if (!parameter.parameter_name) {
        obj.parameter_name = "必須項目です。";
      }
      //parameter name must be less than 100 characters
      if (parameter.parameter_name.length > 100) {
        obj.parameter_name = "100文字以内で入力して下さい";
      }
      errors.new_parameters[i] = obj;

      //create error type object
      var errortype = {
        id: i,
        parameter_name: "",
        data_type: "",
        unit: "",
      };

      if (!parameter.data_type) {
        errortype.data_type = "required";
      }
      errorTypes["new_parameters"][i] = errortype;
    });
    //end new parameter validations

    //parameter errors?
    let has_error_in_parameters = false;
    errors.parameters.forEach((parameter) => {
      if (parameter.parameter_name !== "" || parameter.data_type !== "") {
        has_error_in_parameters = true;
      }
    });

    //new_parameter errors?
    let has_error_in_new_parameters = false;
    errors.new_parameters.forEach((parameter) => {
      if (parameter.parameter_name !== "" || parameter.data_type !== "") {
        has_error_in_new_parameters = true;
      }
    });

    //new_parameter error_types?
    let has_type_error_in_new_parameters = false;
    errorTypes.new_parameters.forEach((parameter) => {
      if (parameter.parameter_name !== "" || parameter.data_type !== "") {
        has_type_error_in_new_parameters = true;
      }
    });

    //display error
    setErrors(errors);
    setErrorTypes(errorTypes);

    //check validation error has or not
    if (
      has_error_in_parameters ||
      has_error_in_new_parameters ||
      has_type_error_in_new_parameters
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkNewParameterValidation = (index) => {
    if (
      errors &&
      errors.new_parameters &&
      errors.new_parameters.find((v, i) => i === index)
    ) {
      return errors.new_parameters.find((v, i) => i === index);
    } else {
      return false;
    }
  };

  const checkParameterValidation = (id) => {
    if (
      errors &&
      errors.parameters &&
      errors.parameters.find((v) => v.id === id)
    ) {
      return errors.parameters.find((v) => v.id === id);
    } else {
      return false;
    }
  };

  const getParameterJpName = (value) => {
    if (value === "integer") {
      return "数値";
    } else if (value === "string") {
      return "文字列";
    } else if (value === "float") {
      return "実数";
    } else {
      return "";
    }
  };

  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        title={
          <div style={{ marginLeft: "5%", marginTop: "3%" }}>グループ設定</div>
        }
        topSpace={
          <TopSpace
            group={group}
            parameters={parameters}
            newParameters={newParameters}
            setNewParameters={setNewParameters}
            refetch={refetch}
            hasValidateError={hasValidateError}
            // setValidationStatus={setValidationStatus}
            setErrors={setErrors}
            setAllselectboxclose={setAllselectboxclose}
            closeAllSelectBox={closeAllSelectBox}
          />
        }
      >
        {!isLoading && groupDetail && (
          <div
            className="text-white"
            style={{ marginLeft: "0.5%", marginTop: "1.3%" }}
          >
            <div className="d-flex mt-2" style={{ marginLeft: "0.5%" }}>
              <div className="w-50 d-flex">
                <div
                  className="fs-20-px"
                  style={{ marginTop: "1%", width: "28%" }}
                >
                  グループ名
                </div>
                <div className="w-60">
                  <TextInput
                    errorMessage={errors && errors.name && errors.name}
                    className="h-40-px"
                    isError={
                      errorTypes &&
                      errorTypes.name &&
                      errorTypes.name === "required" &&
                      true
                    }
                    required
                    width="231px"
                    inactive
                    inputClass="font-europa-regular pt-3-px fs-20-px"
                    value={group.name}
                    onChange={(e) =>
                      setGroup((value) => ({
                        ...value,
                        name: e.target.value,
                      }))
                    }
                    textboxClass="lesser-opacity"
                  />
                </div>
              </div>
              <div
                className="d-flex"
                style={{ width: "55%", marginLeft: "2%" }}
              >
                <div
                  className="fs-20-px"
                  style={{ marginTop: "1%", width: "35%" }}
                >
                  変更条件設定値
                </div>
                <div className="w-60">
                  <TextInput
                    className="h-40-px activate-on-hover"
                    required
                    //value={group.threshold}
                    errorMessage={
                      errors && errors.threshold && errors.threshold
                    }
                    width="231px"
                    inputClass="font-europa-regular fs-20-px pt-1"
                    textboxClass="lesser-opacity"
                    // value={group.threshold}
                    isError={
                      errorTypes &&
                      errorTypes.threshold &&
                      errorTypes.threshold === "required" &&
                      true
                    }
                    onChange={(e) =>
                      setGroup((value) => ({
                        ...value,
                        threshold: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "0.5%", marginTop: "35px" }}>
              <div className="w-50 d-flex">
                <div
                  className="fs-20-px"
                  style={{ width: "30%", marginTop: "1%" }}
                >
                  グループ番号
                </div>
                <div className="w-60">
                  <TextInput
                    // value="123456"
                    errorMessage={errors && errors.group_no && errors.group_no}
                    // errorMessage="既に使用されている番号です"
                    isError={
                      errorTypes &&
                      errorTypes.group_no &&
                      errorTypes.group_no === "required" &&
                      true
                    }
                    className="h-40-px"
                    required
                    width="231px"
                    value={group.group_no}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setGroup((value) => ({
                          ...value,
                          group_no: e.target.value,
                        }));
                        var olderror = errorTypes;
                        setErrorTypes((olderror) => ({
                          ...olderror,
                          group_no: "",
                        }));
                        setErrors((value) => ({
                          ...value,
                          group_no: "",
                        }));
                      } else {
                        var olderror = errorTypes;
                        setErrorTypes((olderror) => ({
                          ...olderror,
                          group_no: "required",
                        }));
                        // if (errors && errors.persona_type_no) {
                        setErrors((value) => ({
                          ...value,
                          group_no: "",
                        }));
                        // }
                      }
                    }}
                    inputClass="font-europa-regular pt-3-px fs-20-px"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5" style={{ marginLeft: "0.5%" }}>
              <div className="fs-20-px d-flex gap-3">
                <div>パラメーター</div>
                <div
                  onClick={() => handleAddNewParameter()}
                  className={
                    parameters.length + newParameters.length < 8
                      ? "random-plus"
                      : "random-plus-disable"
                  }
                ></div>
                {/* <div>
                  {errors &&
                    ((errors.parameters && errors.parameters.length > 0) ||
                      (errors.new_parameters &&
                        errors.new_parameters.length > 0)) && (
                      <div className="fst-italic text-danger fs-15-px">
                        無効な形式
                      </div>
                    )}
                </div> */}
              </div>
              <div className="mt-3 h-300-px overflow-auto hide-scroll">
                {/* Old Parameters */}
                {parameters.map((parameter, i) => (
                  <div
                    className="d-flex gap-4"
                    style={{ marginBottom: "21px" }}
                  >
                    <TextInput
                      errorMessage={
                        checkParameterValidation(parameter.id) &&
                        checkParameterValidation(parameter.id).parameter_name
                      }
                      width="230.46px"
                      className="h-40-px activate-on-hover text-input-big-border"
                      required
                      value={parameter.parameter_name}
                      onChange={(e) =>
                        handleParameterChange(
                          e,
                          i,
                          parameter.id,
                          "parameter_name"
                        )
                      }
                      errorClass="error opacity-100"
                    />
                    <div className="position-relative">
                      <TextInput
                        width="230.46px"
                        className="h-40-px group-text-input activate-on-hover"
                        placeholder="データ型を選択"
                        required
                        readonly="true"
                        // value={parameter.data_type}
                        value={getParameterJpName(parameter.data_type)}
                        inputClass="smaller-placeholder center-placeholder lighter-placeholder"
                        onClick={() => showParameterSelectBox(i)}
                        // onChange={(e) =>
                        //   handleParameterChange(e, i, "data_type")
                        // }
                      />
                      <div
                        className="dropdown-icon position-absolute end-0 top-50"
                        onClick={() => showParameterSelectBox(i)}
                        style={{
                          transform: "rotate(90deg) translate(-50%,80%)",
                        }}
                      ></div>
                      {parameters[i]["show_select_box"] &&
                        parameters[i]["show_select_box"] === true && (
                          <DataTypeSelectBox i={i} isOld={false} />
                        )}
                    </div>
                    <TextInput
                      className="h-40-px"
                      width="180.46px"
                      placeholder="単位（オプション）"
                      required
                      inputClass="hide-placeholder smaller-placeholder center-placeholder lighter-placeholder"
                      textboxClass="lesser-opacity"
                      value={parameter.unit}
                      onChange={(e) => handleParameterChange(e, i, "unit")}
                    />
                    <div
                      onClick={() => {
                        setDeleteConfirmModal(true);
                        setDeleteId(parameter.id);
                      }}
                      className="delete-icon mt-1"
                    >
                      <DeleteIcon></DeleteIcon>
                    </div>
                  </div>
                ))}
                {/* New Parameters */}
                {newParameters.map((parameter, i) => (
                  <div
                    className="d-flex gap-4"
                    style={{ marginBottom: "21px" }}
                  >
                    <TextInput
                      errorMessage={
                        checkNewParameterValidation(i) &&
                        checkNewParameterValidation(i).parameter_name
                      }
                      width="230.46px"
                      className="h-40-px activate-on-hover text-input-big-border"
                      required
                      value={parameter.parameter_name}
                      onChange={(e) =>
                        handleNewParameterChange(
                          e,
                          i,
                          parameter.id,
                          "parameter_name"
                        )
                      }
                    />
                    <div className="position-relative">
                      <TextInput
                        width="230.46px"
                        className="h-40-px group-text-input activate-on-hover"
                        placeholder="データ型を選択"
                        required
                        readonly="true"
                        errorMessage={
                          errorTypes?.new_parameters[i]?.data_type
                            ? true
                            : false
                        }
                        // errorMessage={checkNewParameterTypeValidation(i)}
                        // value={parameter.data_type}
                        value={getParameterJpName(parameter.data_type)}
                        inputClass="smaller-placeholder center-placeholder lighter-placeholder"
                        onChange={(e) =>
                          handleNewParameterChange(e, i, "data_type")
                        }
                        onClick={() => showParameterSelectBox(i, "true")}
                      />
                      <div
                        onClick={() => showParameterSelectBox(i, "true")}
                        className="dropdown-icon position-absolute end-0 top-50"
                        style={{
                          transform: "rotate(90deg) translate(-50%,80%)",
                        }}
                      ></div>
                      {newParameters[i]["show_select_box"] &&
                        newParameters[i]["show_select_box"] === true && (
                          <DataTypeSelectBox
                            i={i}
                            isNew={true}
                            isOld={true}
                            id={parameter.id}
                          />
                        )}
                    </div>
                    <TextInput
                      className="h-40-px"
                      width="180.46px"
                      placeholder="単位（オプション）"
                      required
                      inputClass="hide-placeholder smaller-placeholder center-placeholder lighter-placeholder"
                      textboxClass="lesser-opacity"
                      value={parameter.unit}
                      onChange={(e) => handleNewParameterChange(e, i, "unit")}
                    />
                    <div
                      onClick={() => handleDeleteNewParameter(i)}
                      className="delete-icon mt-1"
                    >
                      <DeleteIcon></DeleteIcon>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Modals */}
            {/*Delete Modals */}
            <ConfirmModal
              isOpen={deleteConfirmModal}
              onConfirm={() => handleDelete()}
              onCancel={() => setDeleteConfirmModal(false)}
            >
              パラメーターを削除しますか？
            </ConfirmModal>
            <InfoModal
              isOpen={deleteInfoModal}
              onAction={() => setDeleteInfoModal(false)}
            >
              パラメーターが正常に削除されました。
            </InfoModal>
            {/* End Delete Modals */}

            {/* End Modals */}
          </div>
        )}
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Top Space */
const TopSpace = (props) => {
  const navigate = useNavigate();
  const {
    group,
    parameters,
    newParameters,
    refetch,
    setNewParameters,
    hasValidateError,
    setErrors,
    closeAllSelectBox,
    // setValidationStatus,
  } = props;
  /** States */
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateInfoModal, setUpdateInfoModal] = useState(false);
  const { setUnexpectedErrorModal } = useContext(AppContext);

  /** Functions */
  const handleUpdate = async (id) => {
    // setValidationStatus(true);
    let validate_check = hasValidateError();
    console.log("Valid check", validate_check);
    if (validate_check) {
      setUpdateConfirmModal(false);
      // setValidationStatus(false);
      return;
    }

    //remove unuse property from array
    newParameters.forEach((v) => delete v.show_select_box);

    //request object
    let updateObject = {
      name: group.name,
      group_no: group.group_no,
      // threshold: group.threshold,
      parameters: parameters,
      new_parameters: newParameters,
    };
    // alert("action update");
    console.log("top space data:");
    console.log(updateObject);
    fetchData({
      url: "api/persona_group/" + group.id,
      method: "put",
      data: updateObject,
    })
      .then((res) => {
        setUpdateConfirmModal(false);
        setUpdateInfoModal(true);
        setNewParameters([]);
        refetch();
        navigate("/groups");
      })
      .catch((e) => {
        console.log(e);
        setUpdateConfirmModal(false);
        // hasValidateError();
        if (e.response.data.errors) {
          setErrors(e.response.data.errors);
        } else {
          setUnexpectedErrorModal(true);
        }
      });
  };

  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-end h-100">
      {/* Token Update Button */}
      <Button
        onClick={() => {
          closeAllSelectBox();
          setUpdateConfirmModal(true);
        }}
        text="保存"
        className="w-175-px"
      />

      {/*Update Modals */}
      <ConfirmModal
        isOpen={updateConfirmModal}
        onConfirm={() => handleUpdate()}
        onCancel={() => setUpdateConfirmModal(false)}
      >
        変更項目を保存しますか？
      </ConfirmModal>
      <InfoModal
        isOpen={updateInfoModal}
        onAction={() => setUpdateInfoModal(false)}
      >
        変更項目が正常に保存されました。
      </InfoModal>
      {/* End Update Modals */}
    </div>
  );
};

export default GroupSetting;
