import { React,useEffect, useState } from "react";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { Button } from "../components/Button";
import { TextInput } from "../components/Form";
import { ConfirmModal, InfoModal } from "../components/Modal";
import { DeleteIcon } from "../components/common/Icon";
import { fetchData } from "../services/fetch";
import { useQuery } from "react-query";
import { storeErrorModal } from "../services/storage";
import { strings, translations } from "../services/localization";

/** Content Body */
const Group = () => {
  
  const translation = translations;
  const content = translations.seedSetting;
  /** States */
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState(false);
  const [deletedID, setDeletedID] = useState(false);
  const [firstPercentage, setFirstPercentage] = useState("");
  
  const [errors, setErrors] = useState();
  const [totalPercentage, setTotalPercentage] = useState();
  const [remainPercentage, setRemainPercentage] = useState();
  const [expectedMultiple, setExpectedMultiple] = useState();
  const [newParameters, setNewParameters] = useState([]);
  const [paramcount, setParamCount] = useState(0);
  /** Functions */
  const handleDelete = (id) => {
    // let errorsfix = {};
    // errorsfix["new_parameters_probability"] = [];
    // errorsfix["new_parameters_ratio"] = [];
    // if(errors?.new_parameters_ratio?.length){
    //   let parametersratio=[...errors?.new_parameters_ratio];
    //   parametersratio.splice(parametersratio.findIndex(ratioarray => ratioarray.id == id),1);
    //   errorsfix["new_parameters_ratio"] = parametersratio;
    // }
    // if(errors?.new_parameters_probability?.length){
    //   let parametersprobability=[...errors?.new_parameters_probability];
    //   parametersprobability.splice(parametersprobability.findIndex(probabilityarray => probabilityarray.id == id),1);
    //   errorsfix["new_parameters_probability"] = parametersprobability;
    // }
    // if (errorsfix["new_parameters_probability"].length === 0) {
    //   delete errorsfix.new_parameters_probability;
    // }
    // if (errorsfix["new_parameters_ratio"].length === 0) {
    //   delete errorsfix.new_parameters_ratio;
    // }
    // setErrors(errorsfix);
    // debugger
    let parameters = [...newParameters];
    parameters.splice(id, 1);
    setNewParameters(parameters);
    setDeleteConfirmModal(false);
    setDeleteInfoModal(true);
    calculatePercent()
  };

  function createMarkup(value) {
    return { __html: translations.formatString(value) };
  }

  const { isLoading, refetch } = useQuery({
    queryKey: ["get-persona-group"],
    queryFn: () => fetchData({ url: `api/seed/seed_allocation_value_setting`, }),
    onSuccess: (response) => {
      if(response.data?.length){
        setParamCount(response.data?.length+1);
      }
      console.log(paramcount);
      setNewParameters(response.data);
      calculatePercent();
    },
    onError: (error) => {
      storeErrorModal(error.message);
    },
  });
  
  
  useEffect(() => {
    calculatePercent()
  }, [newParameters])

  const handleAddNewParameter = () => {
    let id = paramcount;
    let obj = {
      id : id,
      ratio: "",
      probability: "",
    };
    setParamCount(paramcount + 1);
    setNewParameters((parameters) => [...parameters, obj]);
  };

  const handleDeleteNewParameter = (id) => {
    setDeletedID(id);
    setDeleteConfirmModal(true);
  };

  const handleNewParameterChange = (e, index, field,i, value = null) => {
    let parameters = [...newParameters];
    if (value) {
      parameters[index][field] = value;
    } else {
      if (!isNaN(e.target.value)) {
        parameters[index][field] = e.target.value;
      }
    }
    setNewParameters(parameters);
    checkvalid(index,field,errors,i);
  };

  const checkNewParameterRadioValidation = (id) => {
    if (
      errors &&
      errors.new_parameters_ratio &&
      errors.new_parameters_ratio.find((v) => v.id === id)
    ) {
      return errors.new_parameters_ratio.find((v) => v.id === id);
    } else {
      return false;
    }
  };

  const checkNewParameterProbabilatyValidation = (id) => {
    if (
      errors &&
      errors.new_parameters_probability &&
      errors.new_parameters_probability.find((v) => v.id === id)
    ) {
      return errors.new_parameters_probability.find((v) => v.id === id);
    } else {
      return false;
    }
  };

  const calculatePercent = () => {
    let total=0;
    let remain=0;
    let expected=0;
    newParameters.forEach((parameter, i) => {
      if(parameter.probability){
        total+=parseFloat(parameter.probability);
        if(parameter.ratio){
          expected=expected+((parseFloat(parameter.probability)/100)*parseFloat(parameter.ratio));
        }
      }
    });
    if (expected != Math.floor(expected)) {
      expected=expected.toFixed(2);
    }
    setExpectedMultiple(expected);
    if(total<=100){
      remain=100-total;
    }
    else {
      remain=total-100;
    }
    if (remain != Math.floor(remain)) {
      remain=remain.toFixed(2);
    }
    if (total != Math.floor(total)) {
      total=total.toFixed(2);
    }
    setRemainPercentage(remain);
    setTotalPercentage(total);
  };

  const checkvalid = (index,field,olderrors,id) =>{
    //let olderrors=[...errors];
    let errors = {};
    let isvalid= false;
    let parameters = [...newParameters];
    errors["new_parameters_probability"] = [];
    errors["new_parameters_ratio"] = [];
    var obj = {
      id: "",
      parameter_name: "",
    };
    if(field=='probability'){
      if(olderrors?.new_parameters_ratio){ 
        errors["new_parameters_ratio"]=olderrors?.new_parameters_ratio;
      }
      if(olderrors?.new_parameters_probability){
        errors["new_parameters_probability"]=olderrors?.new_parameters_probability;
        if(!parameters[index][field]){
          obj.id = id;
          obj.parameter_name = "必須項目です。";
          errors["new_parameters_probability"].push(obj);
        }
        else {
          let errorprobarray=errors["new_parameters_probability"];
          errorprobarray.forEach((errorarray, i) => {
            if(errorarray.id==id){
              errorprobarray.splice(i,1);
            }
          })
          errors["new_parameters_probability"]=errorprobarray;
        }
      }
      else {
        if(!parameters[index][field]){
          obj.id = id;
          obj.parameter_name = "必須項目です。";
          errors["new_parameters_probability"].push(obj);
        }
      }
    }
    if(field=='ratio'){
      if(olderrors?.new_parameters_probability){ 
        errors["new_parameters_probability"]=olderrors?.new_parameters_probability;
      }
      if(olderrors?.new_parameters_ratio){
        errors["new_parameters_ratio"]=olderrors?.new_parameters_ratio;
        if(!parameters[index][field]){
          obj.id = id;
          obj.parameter_name = "必須項目です。";
          errors["new_parameters_ratio"].push(obj);
        }
        else {
          let errorprobarray=errors["new_parameters_ratio"];
          errorprobarray.forEach((errorarray, i) => {
            if(errorarray.id==id){
              errorprobarray.splice(i,1);
            }
          })
          errors["new_parameters_ratio"]=errorprobarray;
        }
      }
      else {
        if(!parameters[index][field]){
          obj.id = id;
          obj.parameter_name = "必須項目です。";
          errors["new_parameters_ratio"].push(obj);
        }
      }
    }
    
    if (errors["new_parameters_probability"].length === 0) {
      delete errors.new_parameters_probability;
    }
    if (errors["new_parameters_ratio"].length === 0) {
      delete errors.new_parameters_ratio;
    }
    setErrors(errors);
  }

  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        className="content-wide-title"
        // title={<Title />
        title={<div className="mt-2 ms-1">{strings.formatString(content.title)}</div>}
        topSpace={<TopSpace totalPercentage={totalPercentage} 
        setNewParameters={setNewParameters}
        newParameters={newParameters}
        setErrors={setErrors}
        />}
        smallMarginRight
      >
        <div className="text-white d-flex">
          {/* First Column */}
          <div className="px-2" style={{ width: "55%" }}>
            <div className="fs-20-px font-notosans-jp mt-20-px">
              {strings.formatString(content.labelconvert)}
            </div>
            <div className="d-flex mt-5 ms-1" style={{ gap: "2%" }}>
              <div className="fs-20-px font-notosans-jp">
                {strings.formatString(content.labelrandom)}
              </div>
              <div onClick={() => handleAddNewParameter()} className="random-plus mt-1"></div>
            </div>
            <div className="mt-3">
              <div className="d-flex fs-20-px">
                <div className="w-40">
                  <div className="ms-3">{strings.formatString(content.labelmagnification)}</div>
                </div>
                <div className="w-50">
                  <div style={{ marginLeft: "15%" }}>{strings.formatString(content.labelprobability)}</div>
                </div>
              </div>
              <div
                className="d-flex flex-column h-300-px overflow-auto hide-scroll mt-1"
                style={{ gap: "18px" }}
              >
                {isLoading ? (
                    // TODO: Implement a loading spinner
                    <></>
                  ) : (
                newParameters.map((parameter, i) => (
                  <div className="d-flex gap-10-px">
                    <div style={{ width: "165px" }}>
                      <TextInput 
                      value={parameter.ratio}
                      errorMessage={
                        checkNewParameterRadioValidation(parameter.id) &&
                        checkNewParameterRadioValidation(parameter.id).parameter_name
                      }
                      onChange={(e) =>{
                        handleNewParameterChange(e, i, "ratio",parameter?.id)
                        calculatePercent()
                      }}
                      className="w-100 h-40-px" 
                      required />
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: "10%",
                        paddingLeft: "2%",
                        paddingTop: "2%",
                      }}
                    >
                      <div className="arrow-icon"></div>
                    </div>
                    <div style={{ width: "205px" }}>
                      <TextInput
                      value={parameter.probability}
                      errorMessage={
                        checkNewParameterProbabilatyValidation(parameter.id) &&
                        checkNewParameterProbabilatyValidation(parameter.id).parameter_name
                      }
                      onChange={(e) =>{
                        handleNewParameterChange(e, i, "probability",parameter?.id)
                        calculatePercent()
                      }}
                      className="w-100 h-40-px" 
                      required />
                    </div>
                    <div className="w-15 d-flex align-items-center justify-content-center">
                      <div
                        style={{ marginLeft: "-15%", marginTop: "5%" }}
                        onClick={() => handleDeleteNewParameter(i)}
                        className="delete-icon"
                      >
                        <DeleteIcon></DeleteIcon>
                      </div>
                    </div>
                  </div>
                )))}
              </div>
            </div>
          </div>
          {/* End First Column */}

          {/* Second Column */}
          <div className="w-50 px-2 font-notosans-jp">
            <div className="fs-20-px font-notosans-jp mt-20-px ps-2">
              {strings.formatString(content.labelformat)}
            </div>
            <div className="mt-100-px">
              <div
                className="text-center fs-20-px"
                style={{ paddingLeft: "2%" }}
              >
                {strings.formatString(content.labelexpectedmultiple)}
              </div>
              <div className="mt-1 d-flex justify-content-center">
                <TextInput 
                  value={expectedMultiple}
                 className="w-220-px h-40-px" required />
              </div>
              <div
                className="fs-20-px text-center me-2"
                style={{ marginTop: "4%" }}
              >
                {strings.formatString(content.labelformula)}
              </div>
              <div className="mt-5 fs-20-px text-center" dangerouslySetInnerHTML={createMarkup(content.labeltotal)}>
              </div>
              <div className="d-flex justify-content-center position-relative">
                <div
                  className="d-flex w-70 gap-3"
                  style={{ marginTop: "8.5%" }}
                >
                  <div className="w-50">
                    <div
                      className="body-font fs-20-px mb-2"
                      style={{ paddingLeft: "8%" }}
                    >
                     {strings.formatString(content.labeltotalprobability)}
                    </div>
                    <div
                      className="mt-1 position-relative"
                    >
                      <TextInput
                        className="h-40-px activate-on-hover text-input-lesser-radius"
                        required
                        width="140px"
                        value={totalPercentage}
                      />
                      <div
                      className="position-absolute fs-20-px top-50 translate-middle"
                      style={{ right: 40 }}
                      >%</div>
                    </div>
                  </div>
                  <div className="w-50">
                    <div className="ms-0 body-font fs-20-px mb-2">
                    {strings.formatString(content.labellackprobability)}
                    </div>
                    <div className="mt-1 position-relative">
                      <TextInput
                        className="h-40-px text-input-lesser-radius"
                        value={remainPercentage}
                        required
                        width="140px"
                      />
                      <div
                      className="position-absolute fs-20-px top-50 translate-middle"
                      style={{ right: 40 }}
                      >%</div>
                    </div>
                  </div>
                </div>
                <div
                  className="position-absolute start-0 d-flex gap-3"
                  style={{ bottom: "6px", marginLeft: "-25px" }}
                >
                  <div className="arrow-icon"></div>
                  <div className="arrow-icon"></div>
                  <div className="arrow-icon"></div>
                </div>
              </div>
            </div>
          </div>
          {/* End Second Column */}
        </div>
        {/*Delete Modals */}
        <ConfirmModal
          isOpen={deleteConfirmModal}
          onConfirm={() => handleDelete(deletedID)}
          onCancel={() => setDeleteConfirmModal(false)}
        >
          要素を削除しますか？
        </ConfirmModal>
        <InfoModal
          isOpen={deleteInfoModal}
          onAction={() => setDeleteInfoModal(false)}
        >
          要素が正常に削除されました。
        </InfoModal>
        {/* End Delete Modals */}
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
// const Title = () => {
//   return <div className="d-flex">{/* Title Content */}</div>;
// };

/** Top Space */
const TopSpace = (props) => {
  const {
    totalPercentage,
    setNewParameters,
    newParameters,
    setErrors,
    refetch
  } = props;
  /** States */
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateInfoModal, setUpdateInfoModal] = useState(false);
 
  /** Functions */
  const handleUpdate = (id) => {
    let paramvalid=false;
    let parameters = [...newParameters];
    let errors = {};
    errors["new_parameters_probability"] = [];
    errors["new_parameters_ratio"] = [];
    newParameters.forEach((parameter, i) => {
      var obj = {
        id: "",
        parameter_name: "",
      };
      if(parameter.ratio&&parameter.probability){
        // ture
      }else{
        paramvalid=true;
        let index=i;
        if(parameter?.id){
          index=parameter.id;
        }
        if(!parameters[i]['probability']){
          obj.id = index;
          obj.parameter_name = "必須項目です。";
          errors["new_parameters_probability"].push(obj);
        }
        if(!parameters[i]['ratio']){
          obj.id = index;
          obj.parameter_name = "必須項目です。";
          errors["new_parameters_ratio"].push(obj);
        }
      }
    });
    if (errors["new_parameters_probability"].length === 0) {
      delete errors.new_parameters_probability;
    }
    if (errors["new_parameters_ratio"].length === 0) {
      delete errors.new_parameters_ratio;
    }
    setErrors(errors);
    if(!paramvalid){
      fetchData({
        url: "api/seed/seed_allocation_value_setting",
        method: "post",
        data: newParameters,
      })
      .then((res) => {
        setUpdateConfirmModal(false);
        setUpdateInfoModal(true);
        setNewParameters(res.data);
        refetch();
      })
      .catch((e) => {
        console.log(e);
        setUpdateConfirmModal(false);
        
      });
    }
    else{
      setNewParameters(parameters);
      setUpdateConfirmModal(false);
    }
  };

  return (
    <div className="text-white ps-2 pb-5-px d-flex justify-content-end h-100">
      {/* Token Update Button */}
      <Button
        onClick={() => totalPercentage>=100 ? setUpdateConfirmModal(true):""}
        text="アップデート"
        className="w-175-px"
        buttonClass={totalPercentage>=100 ? "button-primary" : "button-grey"}
        wdith="175px"
      />

      {/*Update Modals */}
      <ConfirmModal
        isOpen={updateConfirmModal}
        onConfirm={() => handleUpdate()}
        onCancel={() => setUpdateConfirmModal(false)}
      >
        シード設定をアップデートしますか？
      </ConfirmModal>
      <InfoModal
        isOpen={updateInfoModal}
        onAction={() => setUpdateInfoModal(false)}
      >
        シード設定は正常にアップデートされました。
      </InfoModal>
      {/* End Update Modals */}
    </div>
  );
};

export default Group;
