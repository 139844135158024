import { useInfiniteQuery, useQuery } from "react-query";
import { fetchData } from "../../services/fetch";
import { Modal } from "../Modal";
import { TextInput } from "../Form";
import ChooseGroupArrow from "../../assets/icons/choose-group-arrow.svg";
import React from "react";
import {  setTypeId } from "../../slices/publish";
import { useDispatch, useSelector } from "react-redux";
import { truncateString } from "../../utils/truncate";

export default function ChooseTypeModal({
  isOpen,
  isChooseType,
  setType,
  onClick,
  onClose,
}) {
  const dispatch = useDispatch();
  const listInnterRef = React.useRef();
  const [search, setSearch] = React.useState("");
  const [types, setTypes] = React.useState([]);
  const { groupId } = useSelector((state) => state.publish);
  const { isLoading, data } = useQuery({
    queryKey: ["get-persona-type", groupId],
    queryFn: async ({ listall = 1 }) => {
      const res = await fetchData({
        url: groupId
          ? `api/persona_type?listall=${listall}&group_id=${groupId}`
          : `api/persona_type?listall=${listall}`,
      });
      return res.data;
    },
    enabled: isOpen,
    onSuccess: (response) => {
      setTypes(response.persona_types);
    },
  });
  function onFilter(searchValue) {
    if (searchValue === "") {
      setTypes(data.persona_types);
    } else {
      const filtered = data.persona_types.filter(
        (type) =>
          type.persona_type_name.includes(searchValue) ||
          type.persona_type_no === +searchValue
      );
      setTypes(filtered);
    }
  }
  return (
    <Modal
      isChooseType={isChooseType}
      isOpen={isOpen}
      width="592px"
      height="473px"
      boxClass="group-select-message-box"
    >
      <div className="px-4" style={{ marginTop: "27px", position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: 20,
            top: -7,
            transform: "rotate(45deg)",
          }}
          className="plus-icon"
          onClick={onClose}
        ></div>
        <div
          className="fs-25-px text-center font-ethno"
          // style={{ fontWeight: "100" }}
        >
          タイプ選択
        </div>
        <div className="mt-10-px d-flex justify-content-center">
          <TextInput
            className="h-40-px w-400-px"
            placeholder="タイプ名やタイプ番号にて検索"
            inputClass="pb-1"
            required
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => (e.keyCode === 13 ? onFilter(search) : " ")}
          ></TextInput>
        </div>
        <div className="" style={{ marginTop: "35px" }}>
          <div className="d-flex justify-content-center">
            <div className="d-flex fs-20-px text-primary text-center font-notosans-jp-medium w-100 ms-20-px">
              <div className="w-50" style={{ fontWeight: 500 }}>
                タイプ名
              </div>
              <div className="w-50" style={{ fontWeight: 500 }}>
                タイプ番号
              </div>
            </div>
          </div>

          <div
            className=" d-flex justify-content-center ms-20-px"
            style={{ marginTop: "13px" }}
          >
            <div
              className="overflow-auto hide-scroll w-100 "
              style={{ height: "260px" }}
              //onScroll={() => onScroll()}
              ref={listInnterRef}
            >
              {!isLoading &&
                types?.map((type, i) => (
                  <div
                    key={i}
                    className="text-word-wrap d-flex fs-20-px align-items-center text-center choose-group-list position-relative"
                    style={{
                      cursor: "pointer",
                      marginBottom: "12.5px",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      onClick();
                      dispatch(setTypeId(type.id));
                      setType(type);
                    }}
                  >
                    <div
                          className=" choose-arrow"
                          style={{ left: "5px", bottom: "15px" }}
                        >
                          <img
                            src={ChooseGroupArrow}
                            alt="choose-group-arrow"
                          />
                    </div>
                    {/* Group list */}
                    <div className="name">{type.persona_type_name}</div>
                    <div className="groupno">{type.persona_type_no}</div>
                    <div
                          className="choose-arrow ms-20-px"
                          style={{
                            bottom: "15px",
                            transform: "scaleX(-1)",
                          }}
                        >
                          <img
                            src={ChooseGroupArrow}
                            alt="choose-group-arrow"
                          />
                    </div>
                    
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
