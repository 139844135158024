import React from "react";

export const SearchIcon = ({
  width = 16,
  height = 16,
  fill = "#9a9a9a",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        id="Path_6504"
        data-name="Path 6504"
        d="M98.8,71.847l-3.76-3.76a6.79,6.79,0,1,0-.956.956l3.76,3.76a.676.676,0,1,0,.956-.956Zm-5.627-3.716a5.513,5.513,0,1,1,.955-.955A5.48,5.48,0,0,1,93.176,68.131Z"
        transform="translate(-83 -57)"
        fill={fill}
      />
    </svg>
  );
};
