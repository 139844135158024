import { React, useState } from "react";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { TextInput } from "../components/Form";
import Button from "../components/Button";
import { ConfirmModal, InfoModal, ErrorModal } from "../components/Modal";
import { fetchData } from "../services/fetch";
import { DeleteIcon } from "../components/common/Icon";
import { useNavigate } from "react-router-dom";
import { current } from "@reduxjs/toolkit";

const GroupCreate = () => {
  /** States */
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState(false);
  const [group, setGroup] = useState();
  const [newParameters, setNewParameters] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState();
  const [errors, setErrors] = useState();
  const [errorTypes, setErrorTypes] = useState();
  const [paramcount, setParamCount] = useState(0);
  const [allselectboxclose, setAllselectboxclose] = useState(false);

  const handleAddNewParameter = () => {
    if (newParameters.length < 8) {
      let id = paramcount;
      let obj = {
        id: id,
        show_select_box: false,
        errorMessage: false,
        parameter_name: "",
        parameter_no: 0,
        min_value: 0,
        max_value: 0,
        data_type: "",
        unit: "",
      };
      setParamCount(paramcount + 1);
      setNewParameters((parameters) => [...parameters, obj]);
    }
    console.log(newParameters);
  };

  const handleNewParameterChange = (e, index, field, value = null, i) => {
    let olderrors = errors;
    let oldtypeerrors = errorTypes;
    let errs = {};
    let typeerrs = {};
    var obj = {
      id: "",
      parameter_name: "",
    };
    errs["new_parameters"] = [];
    if (field === "data_type") {
      var dataobj = {
        id: "",
      };
      if (errorTypes) {
        typeerrs = errorTypes;
      } else {
        typeerrs["new_parameters"] = [];
      }
      if (oldtypeerrors?.new_parameters?.length > 0) {
        typeerrs["new_parameters"] = oldtypeerrors?.new_parameters;

        let errortypearraylist = oldtypeerrors["new_parameters"];
        errortypearraylist.forEach((errortypearray, i) => {
          if (errortypearray.id == index) {
            errortypearraylist.splice(i, 1);
          }
        });
        typeerrs["new_parameters"] = errortypearraylist;
      }
      // if (typeerrs["new_parameters"].length === 0) {
      //   delete typeerrs.new_parameters;
      // }
      setErrorTypes(typeerrs);
    }
    else if (field === "parameter_name") {
      if (olderrors?.new_parameters?.length > 0) {
        errs["new_parameters"] = olderrors?.new_parameters;
        if (e != null) {
          if (!e.target.value) {
            obj.id = index;
            obj.parameter_name = "必須項目です。";
            errs.new_parameters.push(obj);
          } else {
            let errorarraylist = olderrors["new_parameters"];
            errorarraylist.forEach((errorarray, i) => {
              if (errorarray.id == index) {
                errorarraylist.splice(i, 1);
              }
            });
            errs["new_parameters"] = errorarraylist;
          }
        }
      } else {
        if (e != null) {
          if (!e.target.value) {
            obj.id = index;
            obj.parameter_name = "必須項目です。";
            errs.new_parameters.push(obj);
          }
        }
      }
      if (errs["new_parameters"].length === 0) {
        delete errs.new_parameters;
      }
      setErrors(errs);
    }
    let parameters = [...newParameters];
    if (value) {
      parameters[i][field] = value;
    } else {
      parameters[i][field] = e.target.value;
    }

    parameters[i]["show_select_box"] = false;
    setNewParameters(parameters);
    // console.log(newParameters);
    // alert("hello");
  };

  const handleDeleteNewParameter = (index) => {
    setNewParameters((currentParameter) =>
      currentParameter.filter((_, i) => i !== index)
    );
  };

  //Select Functions
  //show select box for old and new parameters
  const showParameterSelectBox = (i, isNewParameter = null) => {
    let parameter_array = isNewParameter ? [...newParameters] : [];
    //set show select field true to array
    parameter_array[i]["show_select_box"] =
      !parameter_array[i]["show_select_box"];

    if (isNewParameter) {
      setNewParameters(parameter_array);
    }
  };

  //click select each list
  const handleSelect = (i, isNew, value, index) => {
    if (isNew) {
      handleNewParameterChange(null, i, "data_type", value, index);
      //showParameterSelectBox(index, true);
    }
  };

  //select box component
  const DataTypeSelectBox = (props) => {
    const { i, isNew = false, id } = props;
    let oldparam = newParameters;
    oldparam.forEach((param, index) => {
      if (param.data_type) {
        oldparam[i]["show_select_box"] = false;
      }
      if (i != index) {
        oldparam[i]["show_select_box"] = false;
      }
    });
    setNewParameters(oldparam);
    if(allselectboxclose){
      return (<></>);
    }
    else {
      return (
        <>
          <div className="position-absolute top-0 mt-40-px select-box text-center">
            <div
              onClick={() => handleSelect(id, isNew, "integer", i)}
              className="select-row"
            >
              数値
            </div>
            <div
              onClick={() => handleSelect(id, isNew, "string", i)}
              className="select-row"
            >
              文字列
            </div>
            <div
              onClick={() => handleSelect(id, isNew, "float", i)}
              className="select-row"
            >
              実数
            </div>
          </div>
        </>
      );
    }
  };

  //validation
  const hasValidateError = () => {
    let errors = {};
    let error_types = {};
    if (!group?.name) {
      error_types["name"] = "required";
    }

    if (!group?.group_no) {
      // errors["group_no"] = "Group No is required";
      error_types["group_no"] = "required";
    }

    // if (!group?.threshold) {
    //   error_types["threshold"] = "required";
    // }
    //check parameter validations
    //new parameters
    errors["new_parameters"] = [];
    error_types["new_parameters"] = [];
    newParameters.forEach((parameter, i) => {
      //create object
      var obj = {
        id: "",
        parameter_name: "",
        parameter_no: "",
        unit: "",
      };
      var errortype = {
        id: "",
      };
      if (!parameter.data_type) {
        errortype.id = parameter.id;
        error_types["new_parameters"].push(errortype);
      }
      //parameter name required
      if (!parameter.parameter_name) {
        obj.id = parameter.id;
        obj.parameter_name = "必須項目です。";
        // obj.unit = "Unit is required";
        errors["new_parameters"].push(obj);
      }

      //parameter name must be less than 100 characters
      if (parameter.parameter_name.length > 100) {
        obj.id = parameter.id;
        obj.parameter_name = "100文字以内で入力して下さい";
        // obj.unit = "Unit is required";
        errors["new_parameters"].push(obj);
      }
    });

    if (errors["new_parameters"].length === 0) {
      delete errors.new_parameters;
    }
    if (error_types["new_parameters"].length === 0) {
      delete error_types.new_parameters;
    }
    setErrors(errors);
    setErrorTypes(error_types);
    if (Object.keys(errors).length > 0 || Object.keys(error_types).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkNewParameterValidation = (id) => {
    if (
      errors &&
      errors.new_parameters &&
      errors.new_parameters.find((v) => v.id === id)
    ) {
      return errors.new_parameters.find((v) => v.id === id);
    } else {
      return false;
    }
  };

  const checkNewParameterTypeValidation = (id) => {
    if (
      errorTypes &&
      errorTypes.new_parameters &&
      errorTypes.new_parameters.find((v) => v.id === id)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        title={
          <div style={{ marginLeft: "5%", marginTop: "3%" }}>グループ設定</div>
        }
        topSpace={
          <TopSpace
            group={group}
            setAllselectboxclose={setAllselectboxclose}
            newParameters={newParameters}
            setNewParameters={setNewParameters}
            setErrorModal={setErrorModal}
            hasValidateError={hasValidateError}
            setErrors={setErrors}
          />
        }
      >
        {
          <div
            className="text-white"
            style={{ marginLeft: "0.5%", marginTop: "1.3%" }}
          >
            <div className="d-flex mt-2" style={{ marginLeft: "0.5%" }}>
              <div className="w-50 d-flex">
                <div
                  className="fs-20-px"
                  style={{ marginTop: "1%", width: "28%" }}
                >
                  グループ名
                </div>
                <div className="w-60">
                  <TextInput
                    className="h-40-px"
                    errorMessage={errors && errors.name && errors.name}
                    isError={
                      errorTypes &&
                      errorTypes.name &&
                      errorTypes.name === "required" &&
                      true
                    }
                    required
                    width="231px"
                    inactive
                    inputClass="font-europa-regular pt-3-px fs-20-px"
                    onChange={(e) =>
                      setGroup((value) => ({
                        ...value,
                        name: e.target.value,
                      }))
                    }
                    textboxClass="lesser-opacity"
                  />
                </div>
              </div>
              <div
                className="d-flex"
                style={{ width: "55%", marginLeft: "2%" }}
              >
                <div
                  className="fs-20-px"
                  style={{ marginTop: "1%", width: "35%" }}
                >
                  変更条件設定値
                </div>
                <div className="w-60">
                  <TextInput
                    className="h-40-px activate-on-hover"
                    errorMessage={
                      errors && errors.threshold && errors.threshold
                    }
                    required
                    width="231px"
                    inputClass="font-europa-regular fs-20-px pt-1"
                    textboxClass="lesser-opacity"
                    // value={group.threshold}
                    isError={
                      errorTypes &&
                      errorTypes.threshold &&
                      errorTypes.threshold === "required" &&
                      true
                    }
                    onChange={(e) =>
                      setGroup((value) => ({
                        ...value,
                        threshold: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "0.5%", marginTop: "35px" }}>
              <div className="w-50 d-flex">
                <div
                  className="fs-20-px"
                  style={{ width: "30%", marginTop: "1%" }}
                >
                  グループ番号
                </div>
                <div className="w-60">
                  <TextInput
                    value={group?.group_no}
                    // value="123456"
                    errorMessage={errors && errors.group_no && errors.group_no}
                    isError={
                      errorTypes &&
                      errorTypes.group_no &&
                      errorTypes.group_no === "required" &&
                      true
                    }
                    className="h-40-px"
                    required
                    width="231px"
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setGroup((value) => ({
                          ...value,
                          group_no: e.target.value,
                        }));
                        var olderror = errorTypes;
                        setErrorTypes((olderror) => ({
                          ...olderror,
                          group_no: "",
                        }));
                        setErrors((value) => ({
                          ...value,
                          group_no: "",
                        }));
                      } else {
                        if (group) {
                          setGroup((value) => ({
                            ...value,
                            group_no: group.group_no,
                          }));
                        } else {
                          setGroup((value) => ({
                            ...value,
                            group_no: "",
                          }));
                        }
                        var olderror = errorTypes;
                        setErrorTypes((olderror) => ({
                          ...olderror,
                          group_no: "required",
                        }));
                        // if (errors && errors.persona_type_no) {
                        setErrors((value) => ({
                          ...value,
                          group_no: "",
                        }));
                        // }
                      }
                    }}
                    inputClass="font-europa-regular pt-3-px fs-20-px"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5" style={{ marginLeft: "0.5%" }}>
              <div className="fs-20-px d-flex gap-3">
                <div>パラメーター</div>
                {newParameters.length < 8 ? (
                  <div
                    onClick={() => handleAddNewParameter()}
                    className="random-plus"
                  ></div>
                ) : (
                  <div className="random-plus-disable"></div>
                )}
              </div>
              <div className="mt-3 h-300-px overflow-auto hide-scroll">
                {/* New Parameters */}
                {newParameters.map((parameter, i) => (
                  <div
                    className="d-flex gap-4"
                    style={{ marginBottom: "21px" }}
                  >
                    <TextInput
                      width="230.46px"
                      className="h-40-px activate-on-hover text-input-big-border"
                      required
                      value={parameter.parameter_name}
                      errorMessage={
                        checkNewParameterValidation(parameter.id) &&
                        checkNewParameterValidation(parameter.id).parameter_name
                      }
                      onChange={(e) =>
                        handleNewParameterChange(
                          e,
                          parameter.id,
                          "parameter_name",
                          null,
                          i
                        )
                      }
                      errorClass="error opacity-100"
                    />
                    <div className="position-relative">
                      <TextInput
                        width="230.46px"
                        className="h-40-px group-text-input activate-on-hover"
                        placeholder="データ型を選択"
                        errorMessage={checkNewParameterTypeValidation(
                          parameter.id
                        )}
                        required
                        readonly="true"
                        value={getParameterJpName(parameter.data_type)}
                        inputClass="smaller-placeholder center-placeholder lighter-placeholder"
                        onClick={() => showParameterSelectBox(i, "true")}
                        //  handleNewParameterChange(e, i, "data_type")
                        //}
                      />
                      <div
                        className="dropdown-icon position-absolute end-0 top-50"
                        onClick={() => showParameterSelectBox(i, "true")}
                        style={{
                          transform: "rotate(90deg) translate(-50%,80%)",
                        }}
                      ></div>

                      {newParameters[i]["show_select_box"] &&
                        newParameters[i]["show_select_box"] === true && (
                          <DataTypeSelectBox
                            i={i}
                            isNew={true}
                            id={parameter.id}
                          />
                        )}
                    </div>
                    <TextInput
                      className="h-40-px"
                      width="180.46px"
                      placeholder="単位（オプション）"
                      required
                      inputClass="hide-placeholder smaller-placeholder center-placeholder lighter-placeholder"
                      textboxClass="lesser-opacity"
                      value={parameter.unit}
                      onChange={(e) =>
                        handleNewParameterChange(
                          e,
                          parameter.id,
                          "unit",
                          null,
                          i
                        )
                      }
                    />
                    <div
                      onClick={() => handleDeleteNewParameter(i)}
                      className="delete-icon mt-1"
                    >
                      <DeleteIcon></DeleteIcon>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Modals */}
            {/*Delete Modals */}
            <ConfirmModal
              isOpen={deleteConfirmModal}
              onConfirm={() => handleDeleteNewParameter()}
              onCancel={() => setDeleteConfirmModal(false)}
            >
              パラメーターを削除しますか？
            </ConfirmModal>
            <InfoModal
              isOpen={deleteInfoModal}
              onAction={() => setDeleteInfoModal(false)}
            >
              パラメーターが正常に削除されました。
            </InfoModal>
            {/* End Delete Modals */}
            <ErrorModal
              isOpen={errorModal}
              onAction={() => setErrorModal(false)}
              children={ErrorMessage}
            ></ErrorModal>
            {/* End Modals */}
          </div>
        }
      </ContentWrapper>
    </AppWrapper>
  );
};

const getParameterJpName = (value) => {
  if (value === "integer") {
    return "数値";
  } else if (value === "string") {
    return "文字列";
  } else if (value === "float") {
    return "実数";
  }
};

/** Top Space */
const TopSpace = (props) => {
  const navigate = useNavigate();
  const {
    group,
    newParameters,
    setNewParameters,
    setAllselectboxclose,
    setErrorModal,
    setErrors,
    hasValidateError,
  } = props;
  /** States */
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateInfoModal, setUpdateInfoModal] = useState(false);

  /** Functions */
  const handleCreate = (id) => {
    setAllselectboxclose(false);
    let validate_check = hasValidateError();
    if (validate_check) {
      setUpdateConfirmModal(false);
      return;
    }
    // check new parameter length
    let createObject = {
      name: group?.name ? group.name : "",
      group_no: group?.group_no ? group.group_no : "",
      // threshold: group.threshold,
      parameters: newParameters,
    };
    fetchData({
      url: "api/persona_group",
      method: "post",
      data: createObject,
    })
      .then((res) => {
        setUpdateConfirmModal(false);
        setUpdateInfoModal(true);
        setNewParameters([]);
        navigate("/groups");
      })
      .catch((e) => {
        console.log(e);
        setUpdateConfirmModal(false);
        if (e.response.data.errors) {
          setErrors(e.response.data.errors);
        }
      });
  };
  const checkallselectboxclose =(valid) => {
    if(valid){
      setAllselectboxclose(true);
      setUpdateConfirmModal(true);
    }
    else {
      setAllselectboxclose(false);
      setUpdateConfirmModal(false);
    }
  }
  // const checkValidate = () => {
  //   let validate_check = hasValidateError();
  //   console.log(validate_check);

  //   if (!validate_check) {
  //     setUpdateConfirmModal(true);
  //   }
  // };
  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-end h-100">
      {/* Token Update Button */}
      <Button
        onClick={() => checkallselectboxclose(true)}
        text="保存"
        className="w-175-px"
      />

      {/*Update Modals */}
      <ConfirmModal
        isOpen={updateConfirmModal}
        onConfirm={() => handleCreate()}
        onCancel={() => checkallselectboxclose(false)}
      >
        変更項目を保存しますか？
      </ConfirmModal>
      <InfoModal
        isOpen={updateInfoModal}
        onAction={() => {
          setUpdateInfoModal(false);
          navigate("/groups");
        }}
      >
        変更項目が正常に保存されました。
      </InfoModal>
      {/* End Update Modals */}
    </div>
  );
};

export default GroupCreate;
