import { useEffect, React, useState } from "react";
import AppWrapper from "../layouts/AppWrapper";
import Dashboard from "../assets/images/dashboard.svg";
import DashboardMask from "../components/DashboardMask";
import { fetchData, getData } from "../services/fetch";
import { strings, translations } from "../services/localization";
import { useQuery } from "react-query";
import { getValue } from "../services/storage";
import { formatNumber } from "../utils/truncate";
const Home = () => {
  const translation = translations;
  const content = translations.dashboard;
  const [dashboarddata, setDashboarddata] = useState([]);
  // Migrate to react-query
  // useQuery({
  //   // This is a unique query key identifier, you can add a variable here to
  //   // refetch every time that variable changes
  //   queryKey: ["get-persona-type"],
  //   // Query Function,  function to be invoked for fetching
  //   queryFn: () => fetchData({ url: "api/dashboard" }),
  //   // Function to be excuted when the request is successful
  //   onSuccess: (response) => {
  //     console.log(content);
  //     console.log(translation);
  //     dashboarddata.total_group_count = response.data.total_group_count;
  //     dashboarddata.total_type_count = response.data.total_type_count;
  //   },
  //   // Function to be invoked when the request has failed.
  //   onError: (response) => {},
  // });

  const fetchDashboardData = async () => {
    // setDashboarddata({
    //   total_group_count: 500,
    //   total_type_count: 29
    // })
    const response = await getData("api/dashboard/");
    if (response.ok) {
      setDashboarddata(response.data);
      // dashboarddata.total_group_count = response.data.total_group_count;
      // dashboarddata.total_type_count = response.data.total_type_count;
    }
  };
  const language = getValue("language");   
  
  useEffect(() => {
    fetchDashboardData();
  }, []);
  return (
    <AppWrapper>
      <div className="d-flex justify-content-center text-white font-gkktt">
        <div
          className="position-relative"
          style={{
            height: "575px",
            width: "800px",
            marginTop: "0.5%",
            marginRight: "4%",
          }}
        >
          {/* Dashboard Layer */}
          <div
            className="position-absolute"
            style={{ top: "185px", left: "274px" }}
          >
            <DashboardMask width="206px" height="190px" maskHeight="180px" />
          </div>

          {/* Mask Layer */}
          <div className="position-absolute">
            <img src={Dashboard} alt="dashboard" />
          </div>

          {/* Box1 */}
          <div
            className="position-absolute w-150-px"
            style={{ left: "75px", top: "20px" }}
          >
            <div className="text-center" style={{ color: '#E8723C', fontSize: 23}}>
              {content.title}
            </div>
            <div
              className="fs-44-px text-center font-ethno-light"
              style={{ fontWeight: 300 }}
            >
              {dashboarddata.total_group_count
                ? formatNumber(dashboarddata.total_group_count)
                : ""}
            </div>
          </div>

          {/* Box2 */}
          <div
            className="position-absolute w-150-px"
            style={{ left: "634px", top: "25px" }}
          >
            <div className="d-flex flex-column align-items-center">
              <div className="text-center" style={{color: '#E8723C', fontSize: language === 'Japanese'? 23 : 21}}>{content.published}</div>
              <div
                className="fs-44-px text-center font-ethno-light"
                style={{ fontWeight: 300 }}
              >
                { formatNumber(dashboarddata?.total_publish_count) }
              </div>
            </div>
          </div>

          {/* Box3 */}
          <div
            className="position-absolute w-150-px"
            style={{ left: "24px", top: "400px" }}
          >
            <div
              className="text-center font-gkktt"
              style={{ marginBottom: "7%", color: '#E8723C', fontSize: 23 }}
            >
              {content.persona}
            </div>
            <div
              className="fs-44-px text-center font-ethno-light"
              style={{ fontWeight: 300 }}
            >
              {dashboarddata.total_type_count
                ? formatNumber(dashboarddata.total_type_count)
                : ""}
            </div>
          </div>

          {/* Box4 */}
          <div
            className="position-absolute w-150-px"
            style={{ 
              left: 608, 
              top: "400px" 
            }}
          >
            <div className="d-flex flex-column align-items-center">
              <div className="text-center" style={{
                color: '#E8723C', 
                fontSize: 23
                }}>{content.unpublished}</div>
              <div
                className="fs-44-px text-center font-ethno-light"
                style={{ fontWeight: 300 }}
              >
                { formatNumber(dashboarddata?.total_unpublish_count) }
              </div>
            </div>
          </div>

          {/* Market Place */}
          <div
            className="position-absolute font-ethno-light"
            style={{ right: "-100px", top: "40%" , color: '#E8723C'}}
          >
            <div className="fs-27-px">MARKET PLACE</div>
          </div>
        </div>
      </div>
    </AppWrapper>
  );
};

export default Home;
