import { TextInput } from "../../components/Form";
import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import image1 from "../../assets/images/code.svg";
import { CameraIcon, CopyIcon } from "../../assets/icons";
import { InfoModal } from "../../components/Modal";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchData, fetchFormData } from "../../services/fetch";
import Button from "../../components/Button";
import { AuthContext } from "../../context/authContext";
import { formatNumber } from "../../utils/truncate";


const Marketplace = () => {
  const contents = translations.marketplace;
  const { user, fetchAuthUser } = useContext(AuthContext);
  
  const [name, setName] = useState('ORANGE CODE')
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState({
    upload: null, local: null
  })
  const [validModal, setValidModal] = useState({ show: false, message: "" });

  const selectFile = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage({ local: imageUrl, upload: file });
    }
  }
  const fetchMarketPlace = () => fetchData({
    url: `api/offer-summary`,
    method: 'POST',
    data: {
      wallet_address: user?.tenant?.wallet_address
    }
  })

  const updateOrangeCode = () => {
    if(!name) {
      setValidModal({show: true, message: '必須項目を埋めてください。'})
      return;
    }
    const formData = new FormData();
    if(image.upload) {
      formData.set('file', image.upload)
    }
    formData.set('orange_code_name', name)
    setLoading(true)
    fetchFormData({
      url: 'api/update-orange-code',
      data: formData
    }).then(res => {
      setLoading(false)
      fetchAuthUser()
      setSuccessModal(true)
    }).catch(error => {
      setLoading(false)
    })
  }
  const {data: response} = useQuery(
    ["get-marketplace"], 
    () => fetchMarketPlace().then(res => res.data),
    {
      enabled: Boolean(user?.tenant?.wallet_address) // Only enables the query if wallet_address exists
    }
  )

  useEffect(()=> {
    setName(user?.tenant?.orange_code_name)
  }, [user])
  return (
    <AppWrapper bgMask={true}>
      <ContentWrapper
        titletopmarign="true"
        title={strings.formatString(contents.title)}
        marginLeft="12px"
        className="content marketplace-content"
        topSpace={
          <div className="d-flex justify-content-end">
            {/* Search Box */}
            <Button
              disabled={loading}
              onClick={updateOrangeCode}
              text={"保存"}
              width="173px"
              height="40px"
            />
          </div>
        }
      >
        <div className="h-100 ml-36-px mr-32-px my-10-px">
          <section className="d-flex my-4 ms-2">
            <div className="">
              <div className="marketplace-img-container img-container">
                <img src={image.local ? image.local : user?.tenant?.orange_code_icon_url} alt="" />
                <div className="icon-container">
                  <label htmlFor="input-file">
                    <CameraIcon className="camera-icon" />
                  </label>
                  <input onChange={(e)=> selectFile(e)} id="input-file" className="d-none" type="file" />
                </div>
              </div>
            </div>
            <div className="ml-35-px">
              <TextInput
                width="350px"
                inputClass="text-start text-gray left-placeholder fs-17-px"
                inactive
                value={name}
                onChange={(e) => {setName(e.target.value)}}
              />
            </div>
            <div className="d-flex align-items-center ml-40-px">
              <p className="label-text">{contents.address}</p>
              <p
                className="text-gray left-placeholder fs-16-px ml-20-px"
                style={{ lineBreak: "anywhere", cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    user?.tenant?.wallet_address
                  );
                  setShowCopyModal(true);
                }}
              >
                {user?.tenant?.wallet_address}
                <CopyIcon className="ml-10-px" />
              </p>
            </div>
          </section>
          <article className="my-5 text-light w-80">
            <h3 className="font-notosans-jp fs-18-px">
              {contents.public_status}
            </h3>
            <div className="fs-16-px my-20-px px-15-px d-flex">
              <p className="" style={{ width: 380 }}>
                {contents.primary_on_sale}
                <span className="fs-19-px text-highlight font-ethno-normal ml-30-px">
                  / {formatNumber(response?.primary_sell_offer_count)}
                </span>
              </p>
              <p className="">
                {contents.secondary_open}
                <span className="fs-19-px text-highlight font-ethno-normal ml-30-px">
                  / {formatNumber(response?.secondary_sell_offer_count)}
                </span>
              </p>
            </div>
            <div className="px-15-px fs-16-px d-flex">
              <p className="" style={{ width: 180 }}>
                {contents.total_selling_offer_amount}
              </p>
              <p
                className="fs-19-px font-ethno-normal text-end"
                style={{ width: 230 }}
              >
                <span className="text-highlight">{formatNumber(response?.sell_offer_value_sum_yen)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">JPY</span>
              </p>
              <p
                className="fs-19-px font-ethno-normal text-end"
                style={{ width: 280 }}
              >
                <span className="text-highlight">{formatNumber(response?.sell_offer_value_sum_anima)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">ANM</span>
              </p>
            </div>
          </article>
          <article className="my-5 text-light">
            <h3 className="font-notosans-jp fs-18-px">{contents.sales}</h3>
            <div className="px-15-px fs-16-px my-20-px d-flex">
              <p className="" style={{ width: 180 }}>
                {contents.this_month_sale}
              </p>
              <p
                className="fs-19-px font-ethno-normal text-end"
                style={{ width: 230 }}
              >
                <span className="text-highlight">{formatNumber(response?.sales_value.this_month?.total_match_yen)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">JPY</span>
              </p>
              <p
                className="fs-19-px font-ethno-normal text-end"
                style={{ width: 280 }}
              >
                <span className="text-highlight">{formatNumber(response?.sales_value.this_month?.total_match_anima)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">ANM</span>
              </p>
            </div>
            <div className="px-15-px fs-16-px my-20-px d-flex">
              <div className="" style={{ width: 180 }}>
                <p className="ml-25-px">{contents.primary_sales}</p>
              </div>
              <p className="font-ethno-normal text-end" style={{ width: 230 }}>
                <span className="text-highlight">{formatNumber(response?.sales_value.this_month?.total_primary_yen)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">JPY</span>
              </p>
              <p className="font-ethno-normal text-end" style={{ width: 280 }}>
                <span className="text-highlight">{formatNumber(response?.sales_value.this_month?.total_primary_anima)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">ANM</span>
              </p>
            </div>
            <div className="px-15-px fs-16-px my-20-px d-flex">
              <div className="" style={{ width: 180 }}>
                <p className="ml-25-px">{contents.secondary_commission}</p>
              </div>
              <p className="font-ethno-normal text-end" style={{ width: 230 }}>
                <span className="text-highlight">{formatNumber(response?.sales_value.this_month?.total_secondary_commission_yen)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">JPY</span>
              </p>
              <p className="font-ethno-normal text-end" style={{ width: 280 }}>
                <span className="text-highlight">{formatNumber(response?.sales_value.this_month?.total_secondary_commission_anima)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">ANM</span>
              </p>
            </div>
            <div className="px-15-px fs-16-px my-20-px d-flex">
              <p className="" style={{ width: 180 }}>
                {contents.total_so_far}
              </p>
              <p
                className="fs-19-px font-ethno-normal text-end"
                style={{ width: 230 }}
              >
                <span className="text-highlight">{formatNumber(response?.sales_value.total?.total_match_yen)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">JPY</span>
              </p>
              <p
                className="fs-19-px font-ethno-normal text-end"
                style={{ width: 280 }}
              >
                <span className="text-highlight">{formatNumber(response?.sales_value.total?.total_match_anima)}</span>
                <span className="fs-14-px ml-10-px font-ethno-light">ANM</span>
              </p>
            </div>
          </article>
        </div>
        <InfoModal
          isOpen={showCopyModal}
          onAction={() => setShowCopyModal(false)}
        >
          クリップボードにコピーしました。
        </InfoModal>
        <InfoModal
        isOpen={validModal.show}
        onAction={() => {
          setValidModal({ show: false, message: "" });
        }}
      >
        {validModal.message}
      </InfoModal>
      <InfoModal
        isOpen={successModal}
        onAction={() => {
          setSuccessModal(false);
        }}
      >
        {"保存しました。"}
      </InfoModal>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default Marketplace;
