import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorMsg: "",
  usernameErrorMessage: "",
  errorCode: "",
};

export const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setErrorCode: (state, action) => {
      state.errorCode = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMsg = action.payload;
    },
  },
});

export const { setErrorMessage, setErrorCode } = LoginSlice.actions;
export default LoginSlice.reducer;
