import React from "react";

export const EXPIcon = ({
  width = 14.353,
  height = 12.665,
  fill = "#fff",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14.353 12.665"
    >
      <path
        id="ハート"
        d="M69.184,447.332c.059-.1.113-.2.165-.289a7.852,7.852,0,0,1,.866-1.31,4.042,4.042,0,0,1,1.929-1.313,3.2,3.2,0,0,1,3.61,1.386,3.815,3.815,0,0,1,.58,1.688,5.537,5.537,0,0,1-.472,2.756,9.889,9.889,0,0,1-1.619,2.556,18.914,18.914,0,0,1-3.663,3.245c-.432.3-.879.582-1.319.872a.107.107,0,0,1-.135.013,24.237,24.237,0,0,1-3.744-2.816,13.164,13.164,0,0,1-2.288-2.733,7.526,7.526,0,0,1-.963-2.312,4.924,4.924,0,0,1-.113-1.4,3.7,3.7,0,0,1,1-2.384,3.112,3.112,0,0,1,1.805-.958,3.331,3.331,0,0,1,2.658.753,5.4,5.4,0,0,1,1.333,1.606Z"
        transform="translate(-62.007 -444.291)"
        fill={fill}
      />
    </svg>
  );
};
