// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dashboard: {
    title: "TITLE",
    persona: "PERSONA",
    unpublished: "UNPUBLISHED",
    published: "PUBLISHED",
  },
  seedSetting: {
    title: "Seed Setting",
    labelconvert:
      "When converting from persona to arcana, seed values are calculated from random numbers according to the following formula.",
    labelrandom: "Number of random number table elements",
    labelmagnification: "Magnification",
    labelprobability: "Probability",
    labelformat:
      "Seed value = multiplier drawn from random number table x total value of tokens",
    labelexpectedmultiple: "Expected Multiple",
    labelformula:
      "(Expected magnification = Σ[k=1, n]magnification x probability)",
    labeltotal: `The total probability must become <span class="text-primary fs-25-px">100%</span>`,
    labeltotalprobability: `Total Probability`,
    labellackprobability: `Lack of Probability`,
  },
  group: {
    title: "Group List",
    placeholdersearch: "Search by group name or group number",
    groupname: "Group Name",
    groupnumber: "Group Number",
    numberofpersonas: "Number of Personas",
    createddate: "Created date",
    action: "Action",
  },
  type: {
    title: "Type List",
    placeholdersearch: "Search by type name or type number",
    typename: "Type Name",
    typenumber: "Type Number",
    groupname: "Group Name",
    createddate: "Created date",
    numberofpersonas: "Number of Personas",
    action: "Action",
  },
  management: {
    title: "Persona Management",
    placeholdersearch: "Search by group name or group number",
    typename: "Type Name",
    typenumber: "Type Number",
    groupname: "Group Name",
    createddate: "Created date",
    numberofpersonas: "Number of Personas",
    action: "Action",
  },
  users: {
    title: "User management",
    user_name_number: "User No/User name",
    wallet_addres: "Wallet address",
    number_of_hold_personas: "Number of hold personas",
    amount_in_jp: "Amount/JPY",
    registration_date: "User Registration date",
  },
  button: {
    create: "Create",
  },
  menu: {
    dashboard: "Dashboard",
    title: "Title",
    persona: "Persona",
    update_history: "Update History",
    marketplace: "Marketplace",
    user_management: "User Management",
    setting: "Setting",
    signout: "Sign out",
  },
  titles: {
    title: "Title List",
    placeholdersearch: "Search by title name or title ID",
    button: "Create new title",
    id: "Title ID",
    name: "Title Name",
    no_of_type: "Number of persona type",
    no_of_persona: "Number of persona",
    updatedAt: "Title created at",
    action: "Action",
    setting: "Title setting",
  },
  titleSetting: {
    title: "タイトル設定",
    name: "タイトル名",
    id: "タイトルID",
    id_secondary: "自動出力",
    value: "AURA ／Gia Change",
    value_secondary: "アルカナジェネレーターを呼び出す値",
    value_placeholder: "数値を入力",
    custom_parameter: "カスタムパラメータ1",
    custom_parameter_secondary:
      "タイトルの配下となる全ペルリアに対して任意のパラメータを設定",
    parameter_type: "パラメータの型（整数, 実数, 文字列）",
    parameter_type_secondary: "（整数, 実数, 文字列）",
    parameter_type_placeholder: "データ型を選択",
    parameter_name: "パラメータ名",
    parameter_name_placeholder: "○○力など",
    parameter_unit: "パラメータの単位",
    parameter_unit_secondary: "単位は無くても可",
    parameter_unit_placeholder: "mile, kg など",
    cancel_btn: "キャンセル",
    keep_btn: "保存",
    delete_btn: "タイトル削除",
  },
  titleCreate: {
    title: "Create New Title",
    name: "Title name",
    name_placeholder: "Group name, service name, etc.",
    id: "Title ID",
    id_secondary: "auto output",
    value: "AURA ／Gia Change",
    value_secondary: "Value to call arcana generator ",
    value_placeholder: "Input Number",
    custom_parameter: "Custom parameter 1",
    custom_parameter_secondary:
      "Set arbitrary parameters for all personas under the title",
    parameter_type: "Parameter type",
    parameter_type_secondary: "(integer, real number, string)",
    parameter_type_placeholder: "データ型を選択",
    parameter_name: "Parameter name ",
    parameter_name_placeholder: "○○力など",
    parameter_unit: "Parameter unit",
    parameter_unit_secondary: "It is possible to have no unit.",
    parameter_unit_placeholder: "mile, kg など",
    cancel_btn: "Cancel",
    keep_btn: "Save",
  },
  personas: {
    title: "ペルリアリスト",
    placeholdersearch: "ペルリア名やペルリアNoで検索",
    button: "ペルリア新規作成",
    no: "ペルリアNo",
    name: "ペルリア名",
    type_name: "タイトル名",
    published: "公開数 / ペルリア数",
    price: "価格 / JPY",
    action: "アクション",
    detail: "ペルリア詳細",
  },
  personaDetail: {
    title: "ペルリア詳細",
    cancel_btn: "キャンセル",
    update_btn: "アップデート",
    title_name: "タイトル名",
    token_id: "トークンID",
    token_id_secondary: "自動出力",
    name: "ペルリア名",
    creator_name: "クリエイター名",
    quantity: "QTY（発行数）",
    published: "マーケットプレイス公開数",
    price: "現在の販売価格",
    image: "画像",
    parameter: "カスタムパラメータ 1",
    parameter_secondary: "※タイトルで定義したパラメータ",
    leadership: "統率力",
    political_power: "政治力",
    leg_strength: "脚力",
    charisma: "カリスマ性",
    description: "Description（ペルリアの説明文）",
    description_secondary:
      "ペルリアに対する説明文を記載。カード状の裏側に表示される",
    description_placeholder: "ペルリアに対する説明文を記載",
    status: "Status（ベースパラメータ）",
    status_secondary:
      "ペルリアの持つ基礎パラメータ。ANMコストやドローチェーンなどに影響",
    cost: "必要ANM（アニマ）コスト",
    cost_secondary: "※ベースパラメータによって必要なANM変化",
    absorb: {
      label: "Absorb（食べる）",
      contract: "Absorb contract",
    },
    absorb_secondary: "アルカナを吸収するスマートコントラクトのON／OFF",
    draw_chain: {
      label: "Draw chain（ドローチェーン）",
      contract: "Draw chain Contract",
      id: "Draw chain ID / code",
      name: "ドローチェーン名",
      name_placeholder: "チャッピーワンのフードボーナス",
      required: "ドローに必要な",
      required_secondary: "ドローチェーンが実行できるようになる FOR の値",
      no: "ドローできる回数",
      no_secondary: "回",
      description: "ドロー対象の説明",
      description_placeholder:
        "ネコダービーのアイテム「スーパーフード」が10個もらえる！",
      placeholder: "値を入力",
    },
    draw_chain_secondary:
      "ペルリアを使って特定のアイテムを引き出すスマートコントラクトのON／OFF",
    element: {
      label: "Elements（属性）",
    },
    element_secondary: "ペルリアの持つ属性・カードゲームなどに影響",
    place_of_birth: "Birthplace（出生地）",
    place_of_birth_secondary: "スクエアキーによって定義",
    peerage: "Peerage（爵位）",
    peerage_secondary: "ペルリアの爵位を選択",
    peerage_third:
      "Duke（公爵）> Marquess（侯爵）> Count（伯爵）> Viscount（子爵）> Baron（男爵）",
    parameter_ability: "カスタムパラメータ 2 / アビリティ",
    parameter_ability_secondary: "ペルリアの持つ特殊能力を自由に定義",
    parameter_title: "カスタムパラメータ 3 / 称号",
    parameter_title_secondary: "ペルリアに称号を自由に定義",
    parameter_placeholder: "文字列入力",
  },
  personaMarketPreview: {
    title: "マーケットプレイス公開設定",
    public_btn: "公開／売出",
    cancel_btn: "キャンセル",
    private_marketplace: "プライベートマーケットプレイス",
    published_on: "OCTILLION（オクティリオン） での公開",
    sale_volume: "販売数",
    sale_volume_placeholder: "販売数を入力",
    current_sale: "現在の販売数",
    disclosed: "未公開数",
    element: {
      label: "Elements（属性別）",
    },
    selling_price: {
      label: "販売価格（売りのオファー）",
      jp: "JPY（日本円）",
      anima: "ANIMA（アニマ）",
    },
    total: "合計",
  },
  personaUp: {
    title: "内部値アップデート",
    cancel_btn: "キャンセル",
    update_btn: "アップデート実行",
    title_name: "タイトル名",
    token_id: "トークンID",
    token_id_secondary: "自動出力",
    custom_parameter: {
      label: "カスタムパラメータ 1",
      current_value: "現在の値",
      changed_value: "変更後の値",
      leadership: "統率力",
      political_power: "政治力",
      leg_strength: "脚力",
      charisma: "カリスマ性",
    },
    peerage: "Peerage（爵位）",
    peerage_secondary: "ペルリアの爵位を選択",
    peerage_third:
      "Duke（公爵）> Marquess（侯爵）> Count（伯爵）> Viscount（子爵）> Baron（男爵）",
    parameter_ability: "カスタムパラメータ 2 / アビリティ",
    parameter_ability_secondary: "ペルリアの持つ特殊能力を自由に定義",
    parameter_placeholder: "文字列入力",
    parameter_title: "カスタムパラメータ 3 / 称号",
    parameter_title_secondary: "ペルリアに称号を自由に定義",
  },
  titleSelect: {
    title: "タイトル選択",
    search_placeholder: "タイトル名やタイトルIDで検索",
    cancel_btn: "キャンセル",
    id: "タイトルID",
    name: "タイトル名",
    no_of_type: "ペルリア種類数",
    no_of_persona: "ペルリア数",
    createdAt: "作成日",
    action: "アクション",
    select_btn: "このタイトルを選択",
  },
  personaCreate: {
    title: "ペルリア新規発行",
    search_placeholder: "タイトル名やタイトルIDで検索",
    cancel_btn: "キャンセル",
    keep_btn: "保存",
    issue_btn: "ペルリア発行",
    title_name: "タイトル名",
    name: "ペルリア名",
    name_placeholder: "ペルリア名を入力",
    token_id_secondary: "自動出力",
    creator_name: "クリエイター名",
    creator_name_placeholder: "クリエイター名を入力",
    quantity: "QTY（発行数）",
    quantity_placeholder: "発行数を入力",
    image: "画像",
    parameter: "カスタムパラメータ 1",
    parameter_secondary: "※タイトルで定義したパラメータ",
    // leadership: "統率力",
    // political_power: "政治力",
    // leg_strength: "脚力",
    // charisma: "カリスマ性",
    description: "Description（ペルリアの説明文）",
    description_secondary:
      "ペルリアに対する説明文を記載。カード状の裏側に表示される",
    description_placeholder: "ペルリアに対する説明文を記載",
    status: "Status（ベースパラメータ）",
    status_secondary: "ペルリアの持つ基礎パラメータ。ドローチェーンなどに影響",
    cost: "ANM（アニマ）コスト ",
    cost_secondary:
      "ベースパラメータの設定に必要なGas。ベースパラメータの合計値に影響",
    cost_current: "現在のANM残高",
    parameter_label: "パラメータ振り分け",
    parameter_label_secondary: "５つのパラメータの振り分けパーセンテージを設定",
    parameter_total: "パラメータ合計値",
    parameter_note:
      "５つのパラメータに割り当て可能な合計値。ANMコストにより自動算出",
    parameter_warning:
      "※ ベースパラメータの設定値は、実際には誤差が出ることがあります。",
    absorb: {
      label: "Absorb（食べる）",
      contract: "Absorb contract",
    },
    absorb_secondary: "アルカナを吸収するスマートコントラクトのON／OFF",
    draw_chain: {
      label: "Draw chain（ドローチェーン）",
      contract: "Draw chain Contract",
      id: "Draw chain ID / code",
      name: "ドローチェーン名",
      name_placeholder: "ドローチェーン名を入力",
      required: "ドローに必要な",
      required_secondary: "ドローチェーンが実行できるようになる FOR の値",
      no: "ドローできる回数",
      no_secondary: "回",
      description: "ドロー対象の説明",
      description_placeholder:
        "ネコダービーのアイテム「スーパーフード」が10個もらえる！",
      placeholder: "値を入力",
    },
    draw_chain_secondary:
      "ペルリアを使って特定のアイテムを引き出すスマートコントラクトのON／OFF",
    element: {
      label: "Elements（属性）",
    },
    element_secondary: "ペルリアの持つ属性・カードゲームなどに影響",
    place_of_birth: "Birthplace（出生地）",
    place_of_birth_secondary: "スクエアキーによって定義",
    peerage: "Peerage（爵位）",
    peerage_secondary: "ペルリアの爵位を選択",
    peerage_third:
      "Duke（公爵）> Marquess（侯爵）> Count（伯爵）> Viscount（子爵）> Baron（男爵）",
    parameter_ability: "カスタムパラメータ 2 / アビリティ",
    parameter_ability_secondary: "ペルリアの持つ特殊能力を自由に定義",
    parameter_title: "カスタムパラメータ 3 / 称号",
    parameter_title_secondary: "ペルリアに称号を自由に定義",
    upload_btn: "アップロード",
    parameter_placeholder: "文字列入力",
    validation: {
      imageSize: "The file size must be 2MB or smaller to upload successfully.",
    },
    tag: "Tag（検索タグ）",
    tag_secondary: "ペルリアが検索される際のキーワード設定",
  },
  marketplace: {
    title: "マーケットプレイス情報",
    address: "Address",
    public_status: "公開ステータス",
    sales: "売上高",
    primary_on_sale: "プライマリ販売中",
    total_selling_offer_amount: "売りオファーの総額",
    secondary_open: "セカンダリ公開中",
    this_month_sale: "今月の売上 ",
    primary_sales: "プライマリ販売",
    secondary_commission: "セカンダリ手数料",
    total_so_far: "これまでの累計",
  },
};
