import React from "react";

export const SolidRockIcon = ({
  width = 25.838,
  height = 26.56,
  fill = "#fff",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25.838 26.56"
    >
      <g id="Group_312" data-name="Group 312" transform="translate(0)">
        <path
          id="Path_4849"
          data-name="Path 4849"
          d="M27.147,138.088l-2.025,2.032,2.025,2.033,2.025-2.033Z"
          transform="translate(-22.716 -124.158)"
          fill={fill}
        />
        <path
          id="Path_4850"
          data-name="Path 4850"
          d="M27.829,140.177l-.5.5.5.5.5-.5Z"
          transform="translate(-21.527 -123.033)"
          fill={fill}
        />
        <path
          id="Path_4851"
          data-name="Path 4851"
          d="M28.86,139.231l-.973.977.973.978.973-.978Z"
          transform="translate(-21.228 -123.543)"
          fill={fill}
        />
        <path
          id="Path_4852"
          data-name="Path 4852"
          d="M30.1,140.29,27.9,142.5l2.2,2.215,2.2-2.215Z"
          transform="translate(-21.223 -122.972)"
          fill={fill}
        />
        <path
          id="Path_4853"
          data-name="Path 4853"
          d="M32.114,140.767l-1.035,1.038,1.035,1.04,1.035-1.04Z"
          transform="translate(-19.51 -122.716)"
          fill={fill}
        />
        <path
          id="Path_4854"
          data-name="Path 4854"
          d="M31.505,142.068,30.078,143.5l1.351,1.355,1.427-1.432Z"
          transform="translate(-20.049 -122.016)"
          fill={fill}
        />
        <path
          id="Path_4855"
          data-name="Path 4855"
          d="M30.906,139.419l-.814.817.766.769.814-.817Z"
          transform="translate(-20.041 -123.442)"
          fill={fill}
        />
        <path
          id="Path_4856"
          data-name="Path 4856"
          d="M32.709,143.439,30.452,145.7l2.125,2.133,2.258-2.266Z"
          transform="translate(-19.848 -121.279)"
          fill={fill}
        />
        <path
          id="Path_4857"
          data-name="Path 4857"
          d="M33.3,142.722l-.7.706.662.665.7-.706Z"
          transform="translate(-18.694 -121.664)"
          fill={fill}
        />
        <path
          id="Path_4858"
          data-name="Path 4858"
          d="M35.353,140.888l-2.086,2.095,1.965,1.973,2.086-2.095Z"
          transform="translate(-18.333 -122.651)"
          fill={fill}
        />
        <path
          id="Path_4859"
          data-name="Path 4859"
          d="M36.419,140.2l-.953.957.9.9.953-.957Z"
          transform="translate(-17.149 -123.019)"
          fill={fill}
        />
        <path
          id="Path_4860"
          data-name="Path 4860"
          d="M36.843,139.036l-.715.718.673.675.715-.718Z"
          transform="translate(-16.793 -123.648)"
          fill={fill}
        />
        <path
          id="Path_4861"
          data-name="Path 4861"
          d="M33.817,139.735l-1.415,1.42,1.332,1.337,1.415-1.42Z"
          transform="translate(-18.798 -123.272)"
          fill={fill}
        />
        <path
          id="Path_4862"
          data-name="Path 4862"
          d="M36.386,129.032,23.558,141.911l2.28,2.289,2.1-2.107,2.068,2.077.694-.7,1.713,1.72,1.362-1.367.44.441.319-.321,2.02,2.029,1.751-1.758.264.265,1.55-1.555.36.361-1.715,1.723,1.946,1.953,2.112-2.121L41.181,143.2l.327-.329,1.314,1.32.492-.494,1.535,1.54,1.528-1.534.625.628,2.393-2.4Zm9.831,12.778H42.09L36.47,136.3l-4.449,4.361H37.77l-1.3-1.274-.687.671h-2l1.686-1.647,1-.98,1,.98,2.3,2.25,1.17,1.144H28.828l6.631-6.5,1.011-.991,1.011.991,5.461,5.353h1.336l-6.128-6.007-1.011-.991-.668-.657-.668.657-1.011.991-7.3,7.151H25.47l8.31-8.142,1.011-.992.668-.655,1.011-.991,1.011.991.668.655,1.011.992,8.31,8.142H46.217Z"
          transform="translate(-23.558 -129.032)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
