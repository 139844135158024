import { useNavigate, useParams } from "react-router-dom";
import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import Button, { CancelButton } from "../../components/Button";
import { NumberInput, SelectInput, TextInput } from "../../components/Form";
import { useContext, useRef, useState } from "react";
import { PenIcon } from "../../assets/icons";
import { ConfirmModal, ErrorModal, InfoModal } from "../../components/Modal";
import { fetchData } from "../../services/fetch";
import { useMutation, useQuery } from "react-query";
import { storeErrorModal } from "../../services/storage";
import { queryClient } from "../../App";
import { AppContext } from "../../context/appContext";

const TitleSetting = () => {
  const contents = translations.titleSetting;
  const { id } = useParams();
  const navigate = useNavigate();
  const parameter_index = useRef(0);
  const { language, setUnexpectedErrorModal } = useContext(AppContext);

  const [parameters, setParameters] = useState([
    // {
    //   index: 0,
    //   data_type: "",
    //   parameter_name: "",
    //   unit: "",
    //   parameter_no: 0,
    //   min_value: 0,
    //   max_value: 0,
    // },
  ]);
  const [showKeepModal, setShowKeepModal] = useState(false);
  const [validModal, setValidModal] = useState({ show: false, message: "" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showParamDeleteModal, setShowParamDeleteModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [parameterIndex, setParameterIndex] = useState();
  const [values, setValues] = useState({
    name: "",
    threshold: 1,
    aura_amount: "",
    group_no: "",
    tenant_id: "",
  });

  const fetchTitle = (id) =>
    fetchData({
      url: `api/persona_group/${id}`,
    });

  const { isLoading } = useQuery(["get-title", id], () => fetchTitle(id), {
    onSuccess: async (response) => {
      const title = response.data.group;
      const parameters = response.data.parameters.map((p) => ({
        index: parameter_index.current++,
        data_type: p.data_type ?? "",
        parameter_name: p.parameter_name ?? "",
        unit: p.unit ?? "",
        parameter_no: p.paraeter_no,
        min_value: p.min_value,
        max_value: p.max_value,
      }));
      setValues({
        name: title.name ?? "",
        threshold: 1,
        aura_amount: title.aura_amount ?? "",
        group_no: title.group_no ?? "",
        tenant_id: title.tenant_id,
      });
      setParameters(parameters);
    },
    onError: (error) => {
      storeErrorModal(error.message);
      if (error.response.status === 404) {
        navigate("/titles");
      }
    },
  });

  const putTitle = (data) => {
    const response = fetchData({
      url: `api/persona_group/${id}`,
      method: "put",
      data: data,
    });
    return response;
  };

  const { mutate: updateTitle, isLoading: updateIsLoading } = useMutation(
    async (data) => putTitle(data),
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
          storeErrorModal(err.response.data.errors);
        }
        setUnexpectedErrorModal(true);
      },
      onSuccess: async (data) => {
        queryClient.cancelQueries(["get-title", id]);
        queryClient.setQueryData(["get-title", id], () => data);
        setShowKeepModal(true);
      },
    }
  );

  const handleUpdate = () => {
    const title = {
      ...values,
      aura_amount: Number(values.aura_amount),
      parameters: parameters.map((p) => ({ ...p, index: undefined })),
    };
    if (!values.name || !values.aura_amount) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      return;
    }
    if (
      parameters.length > 0 &&
      !parameters.every((p) => p.data_type && p.parameter_name)
    ) {
      setValidModal({ show: true, message: "必須項目を埋めてください。" });
      return;
    }
    updateTitle(title);
  };

  const { mutate: removeTitle, isLoading: removeIsLoading } = useMutation(
    async (id) => {
      return fetchData({
        url: `api/persona_group/${id}`,
        method: "delete",
      });
    },
    {
      onSuccess: async () => {
        navigate("/titles");
      },
      onError: async (err) => {
        if (err?.response?.status === 409) {
          setShowDeleteModal(true);
        } else {
          setUnexpectedErrorModal(true);
        }
      },
    }
  );

  const handleChange = (prop) => (event) => {
    if (prop === "aura_amount") {
      if (isNaN(event)) {
        return;
      }
      if (event == "0") {
        setValues({ ...values, [prop]: "" });
        return;
      }
      if (event > 2147483647) {
        setValidModal({
          show: true,
          message: "AURA ／Gia Changeは最大2147483647まで設定可能です",
        });
        return;
      }
    }
    setValues({ ...values, [prop]: event });
  };

  const handleParameterChange = (index, prop) => (event) => {
    const parameter = parameters[index];
    setParameters((oldParameters) =>
      oldParameters.map((p) =>
        p.index === parameter.index
          ? { ...parameter, [prop]: event.target.value }
          : p
      )
    );
  };

  // const handleAddParameter = () => {
  //   if (parameters.length < 8) {
  //     parameter_index.current += 1;
  //     let obj = {
  //       index: parameter_index.current,
  //       data_type: "",
  //       parameter_name: "",
  //       unit: "",
  //       parameter_no: 0,
  //       min_value: 0,
  //       max_value: 0,
  //     };
  //     setParameters((parameters) => [...parameters, obj]);
  //   }
  // };

  const handleDeleteParameter = (index) => {
    setParameters((currentParameters) =>
      currentParameters.filter((parameter) => parameter.index !== index)
    );
  };

  return (
    <AppWrapper bgMask={true}>
      <ContentWrapper
        titletopmarign="true"
        title={strings.formatString(contents.title)}
        topSpace={
          !isLoading && (
            <div className="d-flex justify-content-end mb-6-px gap-4">
              <CancelButton
                text={contents.cancel_btn}
                width="173px"
                height="40px"
                onClick={() => navigate("/titles")}
              />
              <Button
                text={contents.keep_btn}
                width="173px"
                height="40px"
                onClick={handleUpdate}
                disabled={updateIsLoading}
              />
              <Button
                text={contents.delete_btn}
                width="173px"
                height="40px"
                onClick={() => setShowDeleteConfirmModal(true)}
                disabled={removeIsLoading}
              />
            </div>
          )
        }
        marginLeft="12px"
      >
        {isLoading ? (
          <em>Loading...</em>
        ) : (
          <div className="h-100 mx-36-px my-10-px">
            <div className="d-flex w-100">
              <div className="d-flex flex-grow-1" style={{ gap: 50 }}>
                <p className="font-notosans-jp label-text">{contents.name}</p>
                <p className="fs-17-px text-gray">{values.name}</p>
              </div>
              <div className="d-flex flex-grow-1" style={{ gap: 70 }}>
                <p className="font-notosans-jp label-text">{contents.id}</p>
                <p>
                  <span className="font-ethno-light fs-19-px text-gray">
                    {values.group_no}
                  </span>
                  <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                    {contents.id_secondary}
                  </span>
                </p>
              </div>
            </div>
            <div
              className="d-flex align-items-center mt-20-px"
              style={{ gap: 46 }}
            >
              <label className="font-notosans-jp label-text">
                {contents.value}
                <span className="text-danger required-label ml-10-px fs-18-px">
                  *
                </span>
              </label>
              <div className="d-flex align-items-center" style={{ gap: 15 }}>
                <NumberInput
                  width="266.28px"
                  inputClass="text-form-control-value left-placeholder"
                  inactive
                  isRightIcon={true}
                  rightIcon={<PenIcon />}
                  placeholder={contents.value_placeholder}
                  value={values.aura_amount}
                  onChange={handleChange("aura_amount")}
                />
                <span className="text-light-primary fs-14-px">
                  {contents.value_secondary}
                </span>
              </div>
            </div>
            <div className="d-flex my-39-px" style={{ gap: 30 }}>
              <p className="label-text">{contents.custom_parameter}</p>
              <div>
                {/* <button
                  className="button-plus"
                  onClick={handleAddParameter}
                  // disabled={parameters.length >= 8}
                  disabled
                >
                  <PlusIcon />
                </button> */}
                <span className="font-notosans-jp fs-14-px text-light-primary ml-10-px">
                  {contents.custom_parameter_secondary}
                </span>
              </div>
            </div>
            <section className="mb-10-px">
              <div className="d-flex" style={{ gap: 20 }}>
                <p
                  className="parameter-label-text"
                  style={{
                    width: 196,
                    letterSpacing:
                      language === "English" ? "-0.1em" : "-0.086em",
                  }}
                >
                  {contents.parameter_type}
                  <span className="fs-12-px">
                    {contents.parameter_type_secondary}
                  </span>
                </p>
                <p
                  className="parameter-label-text fs-14-px"
                  style={{ width: 269.77 }}
                >
                  {contents.parameter_name}
                </p>
                <p
                  className="parameter-label-text fs-15-px"
                  style={{
                    // width: 180.46,
                    letterSpacing: "-0.034em",
                    position: "relative",
                  }}
                >
                  {contents.parameter_unit}
                  <span className="text-light-primary fs-14-px ml-10-px">
                    {contents.parameter_unit_secondary}
                  </span>
                </p>
              </div>
              {parameters.map((parameter, key) => (
                <div
                  className="d-flex mt-10-px"
                  style={{ gap: 20 }}
                  key={parameter.index}
                >
                  <div className="">
                    <SelectInput
                      containerClassName="select-disabled"
                      id={parameter.index}
                      value={parameter.data_type}
                      onChange={handleParameterChange(key, "data_type")}
                      disabled
                    />
                  </div>
                  <div className="">
                    <TextInput
                      width="269.77px"
                      inactive
                      placeholder={contents.parameter_name_placeholder}
                      inputClass="left-placeholder fs-14-px"
                      value={parameter.parameter_name}
                      onChange={handleParameterChange(key, "parameter_name")}
                      disabled
                    />
                  </div>
                  <div className="">
                    <TextInput
                      width="180.46px"
                      inactive
                      placeholder={contents.parameter_unit_placeholder}
                      inputClass="left-placeholder fs-14-px"
                      value={parameter.unit}
                      onChange={handleParameterChange(key, "unit")}
                      disabled
                    />
                  </div>
                  {/* <div
                    onClick={() => {
                      setParameterIndex(parameter.index);
                      setShowParamDeleteModal(true);
                    }}
                    className="delete-icon"
                  >
                    <DeleteIcon />
                  </div> */}
                </div>
              ))}
            </section>
          </div>
        )}
        <InfoModal
          isOpen={showKeepModal}
          onAction={() => setShowKeepModal(false)}
        >
          保存しました。
        </InfoModal>
        <InfoModal
          isOpen={validModal.show}
          onAction={() => {
            setValidModal({ show: false, message: "" });
          }}
        >
          {validModal.message}
        </InfoModal>
        <ErrorModal
          isOpen={showDeleteModal}
          onAction={() => {
            setShowDeleteModal(false);
            setTimeout(() => {
              navigate("/titles");
            }, 500);
          }}
        >
          ペルリアが発行されているタイトルは
          <br />
          削除できません。
        </ErrorModal>
        <ConfirmModal
          isOpen={showParamDeleteModal}
          onConfirm={() => {
            handleDeleteParameter(parameterIndex);
            setParameterIndex(null);
            setShowParamDeleteModal(false);
          }}
          onCancel={() => setShowParamDeleteModal(false)}
        >
          パラメータを削除しますか？
        </ConfirmModal>
        <ConfirmModal
          isOpen={showDeleteConfirmModal}
          onConfirm={() => {
            removeTitle(id);
            setShowDeleteConfirmModal(false);
          }}
          onCancel={() => setShowDeleteConfirmModal(false)}
        >
          {values.name} を削除しますか？
        </ConfirmModal>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default TitleSetting;
