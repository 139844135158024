export default function Pagination({ onChange, paginate_data }) {
  const handleChange = (type) => {
    if (onChange) {
      if (type === 0) {
        //previous
        if (paginate_data.current_page !== 1) {
          paginate_data.current_page--;
          onChange(paginate_data);
        }
      } else if (type === 1) {
        //next
        if (paginate_data.page_count !== paginate_data.current_page) {
          paginate_data.current_page++;
          onChange(paginate_data);
        }
      }
    }
  };
  return (
    <div
      className="text-white d-flex justify-content-between"
      style={{ margin: "0 9%" }}
    >
      {paginate_data.current_page !== 1 ? (
        <div className="left-arrow" onClick={() => handleChange(0)} />
      ) : (
        <div className="left-arrow" />
      )}
      <div className="font-size font-ethno-light" style={{ marginTop: "10px" }}>
        <span className="text-primary">
          {paginate_data.current_page_amount}
        </span>{" "}
        / <span>{paginate_data.total_count}</span>
      </div>
      {paginate_data.total_count > paginate_data.current_page_amount ? (
        <div className="right-arrow" onClick={() => handleChange(1)}></div>
      ) : (
        <div className="right-arrow"> </div>
      )}
    </div>
  );
}
