import React from "react";

export const DeleteIcon = ({
  width = 16.3,
  height = 30,
  fill = "#f634f2",
  className = "",
  ...rest
}) => {
  return (
    <svg
      id="Component_5_1"
      data-name="Component 5 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16.3 18.691"
    >
      <path
        className="trash-can-solid"
        d="M1.664,6.657H15.636V18.3a2.331,2.331,0,0,1-2.329,2.329H3.993A2.331,2.331,0,0,1,1.664,18.3ZM5.157,8.986a.584.584,0,0,0-.582.582v8.15a.582.582,0,0,0,1.164,0V9.568A.584.584,0,0,0,5.157,8.986Zm3.493,0a.584.584,0,0,0-.582.582v8.15a.582.582,0,0,0,1.164,0V9.568A.584.584,0,0,0,8.65,8.986Zm3.493,0a.584.584,0,0,0-.582.582v8.15a.582.582,0,0,0,1.164,0V9.568A.584.584,0,0,0,12.143,8.986Z"
        transform="translate(-0.5 -1.938)"
        fill={fill}
      />
      <path
        className="trash-can-solid-2"
        data-name="trash-can-solid"
        d="M4.919.644A1.16,1.16,0,0,1,5.96,0H10.34a1.16,1.16,0,0,1,1.041.644l.262.52h3.493a1.164,1.164,0,1,1,0,2.329H1.164a1.164,1.164,0,1,1,0-2.329H4.657Z"
        fill={fill}
      />
    </svg>
  );
};
