import React from "react";

export const GoldenLightIcon = ({
  width = 25.842,
  height = 25.829,
  fill = "#fff",
  className = "",
  ...rest
}) => {
  return (
    <svg
      id="Group_314"
      data-name="Group 314"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25.842 25.829"
    >
      <path
        id="Path_4863"
        data-name="Path 4863"
        d="M37.519,192.7a9.981,9.981,0,0,1-1.695,3.375c-1.23,1.437-2.2,2.707-2.2,2.707s-1.527,1.99-1.544,3.412a6.425,6.425,0,0,1,1.449-2.8C34.937,198.014,37.347,195.359,37.519,192.7Z"
        transform="translate(-18.965 -191.657)"
        fill={fill}
      />
      <path
        id="Path_4864"
        data-name="Path 4864"
        d="M37.011,194.3a9.263,9.263,0,0,1-2.16,3.222,11.5,11.5,0,0,0-1.667,1.875,6.618,6.618,0,0,0-1.1,2.646,7.049,7.049,0,0,1,1.128-2.212,15.735,15.735,0,0,1,1.464-1.512,7.942,7.942,0,0,0,2.33-4.018Z"
        transform="translate(-18.963 -190.8)"
        fill={fill}
      />
      <path
        id="Path_4865"
        data-name="Path 4865"
        d="M36.39,195.689a5.564,5.564,0,0,1-1.608,1.984,7.178,7.178,0,0,0-2.46,2.953s.116-.494,1.534-1.607a7.575,7.575,0,0,0,2.535-3.33Z"
        transform="translate(-18.837 -190.05)"
        fill={fill}
      />
      <path
        id="Path_4866"
        data-name="Path 4866"
        d="M36.322,196.471a3.438,3.438,0,0,1-1.863,2.075,4.828,4.828,0,0,0-2.264,2.527,3.633,3.633,0,0,1,1.921-1.84,3.381,3.381,0,0,0,.758-.509,6.165,6.165,0,0,0,1.447-2.252Z"
        transform="translate(-18.906 -189.629)"
        fill={fill}
      />
      <path
        id="Path_4867"
        data-name="Path 4867"
        d="M30.374,192.026a10,10,0,0,1,.607,3.73c-.153,1.889-.191,3.484-.191,3.484s-.071,2.512.748,3.672a6.441,6.441,0,0,1-.467-3.121c.326-1.946.722-5.516-.694-7.768Z"
        transform="translate(-19.886 -192.023)"
        fill={fill}
      />
      <path
        id="Path_4868"
        data-name="Path 4868"
        d="M31.08,193.508a9.307,9.307,0,0,1,.139,3.882,11.564,11.564,0,0,0-.25,2.5,6.62,6.62,0,0,0,.661,2.784,7.05,7.05,0,0,1-.382-2.455,15.982,15.982,0,0,1,.3-2.089,7.945,7.945,0,0,0-.467-4.625Z"
        transform="translate(-19.567 -191.225)"
        fill={fill}
      />
      <path
        id="Path_4869"
        data-name="Path 4869"
        d="M31.795,194.822a5.587,5.587,0,0,1-.139,2.553,7.226,7.226,0,0,0-.262,3.841s-.2-.466.3-2.2a7.58,7.58,0,0,0,.1-4.187Z"
        transform="translate(-19.428 -190.517)"
        fill={fill}
      />
      <path
        id="Path_4870"
        data-name="Path 4870"
        d="M32.26,195.509a3.452,3.452,0,0,1-.29,2.778,4.83,4.83,0,0,0-.352,3.379,3.64,3.64,0,0,1,.477-2.621,3.335,3.335,0,0,0,.314-.861A6.149,6.149,0,0,0,32.26,195.509Z"
        transform="translate(-19.3 -190.148)"
        fill={fill}
      />
      <path
        id="Path_4871"
        data-name="Path 4871"
        d="M42.906,196.4a9.929,9.929,0,0,1-3.362,1.7c-1.839.421-3.366.867-3.366.867s-2.408.689-3.262,1.823a6.366,6.366,0,0,1,2.823-1.389c1.945-.275,5.456-.972,7.165-3.007Z"
        transform="translate(-18.519 -189.67)"
        fill={fill}
      />
      <path
        id="Path_4872"
        data-name="Path 4872"
        d="M41.19,197.48a9.178,9.178,0,0,1-3.645,1.3,11.488,11.488,0,0,0-2.45.514,6.563,6.563,0,0,0-2.446,1.472,6.993,6.993,0,0,1,2.217-1.1,15.7,15.7,0,0,1,2.073-.343A7.881,7.881,0,0,0,41.19,197.48Z"
        transform="translate(-18.662 -189.086)"
        fill={fill}
      />
      <path
        id="Path_4873"
        data-name="Path 4873"
        d="M39.287,198.41a5.538,5.538,0,0,1-2.467.635,7.148,7.148,0,0,0-3.727.908s.385-.329,2.186-.377A7.491,7.491,0,0,0,39.287,198.41Z"
        transform="translate(-18.423 -188.586)"
        fill={fill}
      />
      <path
        id="Path_4874"
        data-name="Path 4874"
        d="M38.7,198.984a3.416,3.416,0,0,1-2.727.56,4.794,4.794,0,0,0-3.317.683,3.6,3.6,0,0,1,2.634-.335,3.293,3.293,0,0,0,.911.042A6.121,6.121,0,0,0,38.7,198.984Z"
        transform="translate(-18.657 -188.277)"
        fill={fill}
      />
      <path
        id="Path_4875"
        data-name="Path 4875"
        d="M44.078,202.544a9.875,9.875,0,0,1-3.711-.634c-1.73-.758-3.222-1.307-3.222-1.307s-2.344-.88-3.7-.478a6.373,6.373,0,0,1,3.092.566C38.261,201.629,41.5,203.163,44.078,202.544Z"
        transform="translate(-18.236 -187.72)"
        fill={fill}
      />
      <path
        id="Path_4876"
        data-name="Path 4876"
        d="M41.826,202.7a9.17,9.17,0,0,1-3.7-1.126,11.506,11.506,0,0,0-2.276-1.047,6.542,6.542,0,0,0-2.84-.272,7.018,7.018,0,0,1,2.439.432,15.471,15.471,0,0,1,1.87.96,7.866,7.866,0,0,0,4.511,1.052Z"
        transform="translate(-18.469 -187.639)"
        fill={fill}
      />
      <path
        id="Path_4877"
        data-name="Path 4877"
        d="M39.383,202.774a5.523,5.523,0,0,1-2.361-.96,7.122,7.122,0,0,0-3.536-1.49s.5-.035,1.981,1a7.531,7.531,0,0,0,3.914,1.454Z"
        transform="translate(-18.211 -187.558)"
        fill={fill}
      />
      <path
        id="Path_4878"
        data-name="Path 4878"
        d="M38.508,202.972a3.428,3.428,0,0,1-2.524-1.177,4.788,4.788,0,0,0-3.071-1.429,3.611,3.611,0,0,1,2.316,1.3,3.326,3.326,0,0,0,.708.575A6.143,6.143,0,0,0,38.508,202.972Z"
        transform="translate(-18.52 -187.532)"
        fill={fill}
      />
      <path
        id="Path_4879"
        data-name="Path 4879"
        d="M40.412,209.417a9.993,9.993,0,0,1-2.585-2.75c-.928-1.649-1.787-2.993-1.787-2.993s-1.346-2.121-2.67-2.618a6.41,6.41,0,0,1,2.129,2.32c.817,1.794,2.483,4.974,4.912,6.039Z"
        transform="translate(-18.274 -187.161)"
        fill={fill}
      />
      <path
        id="Path_4880"
        data-name="Path 4880"
        d="M38.518,208.186a9.256,9.256,0,0,1-2.283-3.138,11.571,11.571,0,0,0-1.19-2.21,6.567,6.567,0,0,0-2.1-1.935,7.015,7.015,0,0,1,1.688,1.818,16.067,16.067,0,0,1,.918,1.9,7.926,7.926,0,0,0,2.97,3.567Z"
        transform="translate(-18.504 -187.244)"
        fill={fill}
      />
      <path
        id="Path_4881"
        data-name="Path 4881"
        d="M36.5,206.793a5.6,5.6,0,0,1-1.31-2.192,7.193,7.193,0,0,0-1.929-3.329s.423.278.982,2A7.554,7.554,0,0,0,36.5,206.8Z"
        transform="translate(-18.33 -187.044)"
        fill={fill}
      />
      <path
        id="Path_4882"
        data-name="Path 4882"
        d="M35.686,206.42a3.442,3.442,0,0,1-1.31-2.464,4.82,4.82,0,0,0-1.6-3A3.648,3.648,0,0,1,33.85,203.4a3.355,3.355,0,0,0,.22.889,6.156,6.156,0,0,0,1.617,2.132Z"
        transform="translate(-18.591 -187.213)"
        fill={fill}
      />
      <path
        id="Path_4883"
        data-name="Path 4883"
        d="M33.491,212.635a10.035,10.035,0,0,1-.453-3.752c.231-1.88.333-3.472.333-3.472s.172-2.507-.6-3.7a6.456,6.456,0,0,1,.34,3.138c-.4,1.932-.947,5.482.375,7.788Z"
        transform="translate(-18.66 -186.808)"
        fill={fill}
      />
      <path
        id="Path_4884"
        data-name="Path 4884"
        d="M32.765,210.517a9.272,9.272,0,0,1,.021-3.884,11.5,11.5,0,0,0,.352-2.489,6.6,6.6,0,0,0-.548-2.81,7.073,7.073,0,0,1,.281,2.469,15.717,15.717,0,0,1-.385,2.075A7.941,7.941,0,0,0,32.765,210.517Z"
        transform="translate(-18.865 -187.012)"
        fill={fill}
      />
      <path
        id="Path_4885"
        data-name="Path 4885"
        d="M32.152,208.2a5.575,5.575,0,0,1,.245-2.544,7.186,7.186,0,0,0,.418-3.829s.177.474-.389,2.19a7.6,7.6,0,0,0-.274,4.181Z"
        transform="translate(-19.026 -186.748)"
        fill={fill}
      />
      <path
        id="Path_4886"
        data-name="Path 4886"
        d="M31.794,207.41a3.452,3.452,0,0,1,.4-2.763,4.84,4.84,0,0,0,.491-3.364,3.634,3.634,0,0,1-.585,2.6,3.316,3.316,0,0,0-.349.846,6.208,6.208,0,0,0,.038,2.681Z"
        transform="translate(-19.212 -187.039)"
        fill={fill}
      />
      <path
        id="Path_4887"
        data-name="Path 4887"
        d="M27.947,211.136a10.036,10.036,0,0,1,1.855-3.29c1.3-1.374,2.323-2.595,2.323-2.595s1.622-1.915,1.709-3.332a6.436,6.436,0,0,1-1.582,2.727c-1.468,1.314-4,3.847-4.3,6.493Z"
        transform="translate(-21.192 -186.697)"
        fill={fill}
      />
      <path
        id="Path_4888"
        data-name="Path 4888"
        d="M28.353,208.958a9.274,9.274,0,0,1,2.313-3.115,11.566,11.566,0,0,0,1.756-1.792,6.613,6.613,0,0,0,1.223-2.587,7.031,7.031,0,0,1-1.235,2.153,15.537,15.537,0,0,1-1.537,1.44A7.92,7.92,0,0,0,28.353,208.958Z"
        transform="translate(-20.974 -186.942)"
        fill={fill}
      />
      <path
        id="Path_4889"
        data-name="Path 4889"
        d="M28.74,206.617a5.558,5.558,0,0,1,1.7-1.9,7.163,7.163,0,0,0,2.6-2.829s-.139.488-1.61,1.531a7.57,7.57,0,0,0-2.694,3.2Z"
        transform="translate(-20.765 -186.714)"
        fill={fill}
      />
      <path
        id="Path_4890"
        data-name="Path 4890"
        d="M28.868,205.723a3.437,3.437,0,0,1,1.959-1.983,4.818,4.818,0,0,0,2.384-2.413,3.627,3.627,0,0,1-2.009,1.744,3.342,3.342,0,0,0-.781.474,6.139,6.139,0,0,0-1.553,2.181Z"
        transform="translate(-20.697 -187.015)"
        fill={fill}
      />
      <path
        id="Path_4891"
        data-name="Path 4891"
        d="M24.39,205.941a9.931,9.931,0,0,1,3.371-1.686c1.841-.409,3.371-.846,3.371-.846s2.412-.675,3.272-1.8a6.393,6.393,0,0,1-2.831,1.374C29.626,203.242,26.111,203.917,24.39,205.941Z"
        transform="translate(-23.107 -186.866)"
        fill={fill}
      />
      <path
        id="Path_4892"
        data-name="Path 4892"
        d="M25.6,204.478a9.17,9.17,0,0,1,3.652-1.28,11.418,11.418,0,0,0,2.453-.5,6.6,6.6,0,0,0,2.455-1.458,7.046,7.046,0,0,1-2.224,1.094,15.5,15.5,0,0,1-2.075.329,7.889,7.889,0,0,0-4.263,1.817Z"
        transform="translate(-22.454 -187.063)"
        fill={fill}
      />
      <path
        id="Path_4893"
        data-name="Path 4893"
        d="M26.693,202.951a5.532,5.532,0,0,1,2.469-.62,7.119,7.119,0,0,0,3.732-.888s-.387.328-2.186.366A7.481,7.481,0,0,0,26.693,202.951Z"
        transform="translate(-21.867 -186.953)"
        fill={fill}
      />
      <path
        id="Path_4894"
        data-name="Path 4894"
        d="M27.233,202.273a3.423,3.423,0,0,1,2.729-.543,4.79,4.79,0,0,0,3.319-.661,3.6,3.6,0,0,1-2.637.318,3.294,3.294,0,0,0-.911-.048,6.167,6.167,0,0,0-2.5.934Z"
        transform="translate(-21.578 -187.154)"
        fill={fill}
      />
      <path
        id="Path_4895"
        data-name="Path 4895"
        d="M23.558,199.232a9.9,9.9,0,0,1,3.709.657c1.726.768,3.213,1.329,3.213,1.329s2.339.9,3.7.5a6.411,6.411,0,0,1-3.09-.586c-1.723-.951-4.948-2.5-7.533-1.9Z"
        transform="translate(-23.555 -188.215)"
        fill={fill}
      />
      <path
        id="Path_4896"
        data-name="Path 4896"
        d="M25.172,199.059a9.176,9.176,0,0,1,3.7,1.149,11.506,11.506,0,0,0,2.269,1.061,6.549,6.549,0,0,0,2.838.292,7.008,7.008,0,0,1-2.434-.448,15.874,15.874,0,0,1-1.865-.972A7.866,7.866,0,0,0,25.172,199.059Z"
        transform="translate(-22.686 -188.26)"
        fill={fill}
      />
      <path
        id="Path_4897"
        data-name="Path 4897"
        d="M26.59,198.953a5.531,5.531,0,0,1,2.354.974,7.152,7.152,0,0,0,3.527,1.515s-.505.032-1.976-1.012A7.5,7.5,0,0,0,26.59,198.953Z"
        transform="translate(-21.922 -188.296)"
        fill={fill}
      />
      <path
        id="Path_4898"
        data-name="Path 4898"
        d="M27.364,198.814a3.422,3.422,0,0,1,2.516,1.192,4.8,4.8,0,0,0,3.062,1.449,3.6,3.6,0,0,1-2.309-1.318,3.287,3.287,0,0,0-.706-.581,6.135,6.135,0,0,0-2.566-.741Z"
        transform="translate(-21.508 -188.372)"
        fill={fill}
      />
      <path
        id="Path_4899"
        data-name="Path 4899"
        d="M25.944,194.4a10,10,0,0,1,2.6,2.738c.932,1.647,1.8,2.986,1.8,2.986s1.353,2.115,2.679,2.61a6.426,6.426,0,0,1-2.139-2.313C30.056,198.632,28.377,195.459,25.944,194.4Z"
        transform="translate(-22.27 -190.742)"
        fill={fill}
      />
      <path
        id="Path_4900"
        data-name="Path 4900"
        d="M27.326,195.252a9.233,9.233,0,0,1,2.295,3.129,11.476,11.476,0,0,0,1.2,2.206,6.59,6.59,0,0,0,2.111,1.927,7.017,7.017,0,0,1-1.7-1.814,15.443,15.443,0,0,1-.925-1.893A7.915,7.915,0,0,0,27.326,195.252Z"
        transform="translate(-21.527 -190.285)"
        fill={fill}
      />
      <path
        id="Path_4901"
        data-name="Path 4901"
        d="M28.525,196.023a5.578,5.578,0,0,1,1.317,2.189,7.2,7.2,0,0,0,1.941,3.319s-.425-.275-.991-1.99a7.581,7.581,0,0,0-2.269-3.518Z"
        transform="translate(-20.883 -189.871)"
        fill={fill}
      />
      <path
        id="Path_4902"
        data-name="Path 4902"
        d="M29.228,196.372a3.442,3.442,0,0,1,1.32,2.46,4.83,4.83,0,0,0,1.608,2.992,3.643,3.643,0,0,1-1.079-2.436,3.222,3.222,0,0,0-.224-.886,6.175,6.175,0,0,0-1.624-2.126Z"
        transform="translate(-20.503 -189.683)"
        fill={fill}
      />
      <path
        id="Path_4903"
        data-name="Path 4903"
        d="M32.474,202.47a1.82,1.82,0,1,0-1.813-1.821A1.817,1.817,0,0,0,32.474,202.47Z"
        transform="translate(-19.732 -188.36)"
        fill={fill}
      />
      <path
        id="Path_4904"
        data-name="Path 4904"
        d="M34.541,201.614a2.105,2.105,0,1,1-3.529-2.3c.024-.037.05-.071.076-.106a2.648,2.648,0,0,0-.595.649,2.661,2.661,0,1,0,4.115-.426,2.116,2.116,0,0,1-.068,2.18Z"
        transform="translate(-20.052 -188.154)"
        fill={fill}
      />
    </svg>
  );
};
