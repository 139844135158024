import React from "react";
import MessageBox from "../layouts/MessageBox";
import GuideImg from "../assets/images/guide-img.png";

const GuidanceMsg = () => {
  return (
    <div
      className="h-100-vh d-flex justify-content-center"
      style={{ backgroundColor: "#343536", paddingTop: "300px" }}
    >
      <MessageBox className="mt-2" width="572px" height="345px">
        <div class="container-box mt-4 px-2">
          <div class="row text-white fs-25-px">
            <div class="px-5">
              <img src={GuideImg} alt="Guideance " />
              Title of guidance
            </div>
            <p class="px-5 mt-3 fs-20-px">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit ame
            </p>
          </div>
        </div>
      </MessageBox>
    </div>
  );
};
export default GuidanceMsg;
