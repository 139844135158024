import React from "react";

export const UpdateIcon = ({
  width = 19,
  height = 18.957,
  fill = "#e45a1b",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 19 18.957"
    >
      <g id="Group_42200" data-name="Group 42200" transform="translate(-22 -9)">
        <path
          id="pen-to-square-solid_1_"
          data-name="pen-to-square-solid (1)"
          d="M16.753,5.858a1.987,1.987,0,0,0-2.813,0L12.87,6.924,16.348,10.4l1.069-1.069a1.987,1.987,0,0,0,0-2.813ZM6.124,13.674a1.963,1.963,0,0,0-.48.778L4.593,17.606a.857.857,0,0,0,.206.874.846.846,0,0,0,.874.206l3.154-1.051a2.057,2.057,0,0,0,.778-.48l5.943-5.947L12.067,7.727Z"
          transform="translate(23 3.725)"
          fill={fill}
        />
        <path
          id="pen-to-square-solid_1_2"
          data-name="pen-to-square-solid (1)"
          d="M3.41,7.361A3.411,3.411,0,0,0,0,10.771v9.094a3.411,3.411,0,0,0,3.41,3.41H12.5a3.411,3.411,0,0,0,3.41-3.41v-3.41a1.137,1.137,0,0,0-2.273,0v3.41A1.136,1.136,0,0,1,12.5,21H3.41a1.136,1.136,0,0,1-1.137-1.137V10.771A1.136,1.136,0,0,1,3.41,9.635H6.82a1.137,1.137,0,1,0,0-2.273Z"
          transform="translate(22 4.682)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
