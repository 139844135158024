import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  typeId: "",
  groupId: "",
  group: {},
  type: {},
};

export const publishSlice = createSlice({
  name: "publish",
  initialState,
  reducers: {
    setTypeId: (state, action) => {
      state.typeId = action.payload;
    },
    setGroupId: (state, action) => {
      state.groupId = action.payload;
    },
    setGroup: (state, action) => {
      state.group = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const { setTypeId, setGroupId, setType, setGroup } =
  publishSlice.actions;
export default publishSlice.reducer;
