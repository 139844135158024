import React from "react";

function HexagonCheckBoxes(props) {
  const { onSelect, isCheck } = props;
  const [checked, setChecked] = React.useState(false);
  return (
    <div
      className="ms-3"
      onClick={() => {
        onSelect();
        setChecked(!checked);
      }}
    >
      <div className={isCheck ? "check-icon" : "uncheck-icon"} />
    </div>
  );
}

export default HexagonCheckBoxes;
