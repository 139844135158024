import React from "react";

export const CrossIcon = ({
  width = 13.799,
  height = 13.8,
  stroke = "#e45a1b",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13.799 13.8"
    >
      <g
        id="Group_42119"
        data-name="Group 42119"
        transform="translate(-751.358 -115.567)"
      >
        <path
          id="Path_5003"
          data-name="Path 5003"
          d="M0,0H17.514"
          transform="translate(764.449 116.275) rotate(135)"
          fill="none"
          stroke={stroke}
          strokeWidth="2"
        />
        <path
          id="Path_5004"
          data-name="Path 5004"
          d="M0,0H17.513"
          transform="translate(752.066 116.274) rotate(45)"
          fill="none"
          stroke={stroke}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
