import React from "react";

export const CameraIcon = ({
  width = 18.682,
  height = 13.206,
  fill = "#f17b1b",
  className = "",
  ...rest
}) => {
  return (
    <svg
      id="ホバー枠"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18.682 13.206"
    >
      <g id="Group_42199" data-name="Group 42199">
        <path
          id="Path_5095"
          data-name="Path 5095"
          d="M905.631,160.168h-15.5a1.6,1.6,0,0,0-1.593,1.616v9.975a1.6,1.6,0,0,0,1.593,1.615h15.5a1.6,1.6,0,0,0,1.593-1.615v-9.975A1.6,1.6,0,0,0,905.631,160.168Zm-9.51,11.553a4.469,4.469,0,1,1,4.407-4.468A4.439,4.439,0,0,1,896.122,171.721Zm8.865-7.151h-1.972v-2h1.972Z"
          transform="translate(-888.542 -160.168)"
          fill={fill}
        />
        <ellipse
          id="Ellipse_1575"
          data-name="Ellipse 1575"
          cx="2.594"
          cy="2.63"
          rx="2.594"
          ry="2.63"
          transform="translate(4.985 4.454)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
