import React, { useEffect, useState } from "react";
import "../assets/scss/videoBackground.scss";

const videoUrls = [
  process.env.PUBLIC_URL + "/videos/chip.mp4",
  process.env.PUBLIC_URL + "/videos/cybercube.mp4",
  process.env.PUBLIC_URL + "/videos/fire.mp4",
  process.env.PUBLIC_URL + "/videos/greennova.mp4",
];

const VideoBackground = ({ className, startVideo }) => {
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    if (startVideo) {
      const randomVideo =
        videoUrls[Math.floor(Math.random() * videoUrls.length)];
      setVideoUrl(randomVideo);
    }
  }, [startVideo]);

  return (
    <div className={`video-background ${className}`}>
      {videoUrl && (
        <video src={videoUrl} autoPlay muted className="background-video" />
      )}
    </div>
  );
};

export default VideoBackground;
