import { React, useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { TextInput } from "../components/Form";
import Button from "../components/Button";
import { ConfirmModal, InfoModal } from "../components/Modal";
import { fetchData, fetchFormData } from "../services/fetch";
import { useQuery } from "react-query";
import ChooseGroupModal from "../components/common/ChooseGroupModal";
import { AppContext } from "../context/appContext";
import { useDispatch, useSelector } from "react-redux";
import { setGroupId, setTypeId } from "../slices/publish";
//import { setGroup as setTypeGroup } from "../slices/type";

const GroupSetting = () => {
  // React
  const params = useParams();
  const dispatch = useDispatch();
  const [typeDetail, setTypeDetail] = useState();
  const [chooseGroupModal, setChooseGroupModal] = useState(false);
  const [errors, setErrors] = useState();
  const [errorTypes, setErrorTypes] = useState({});
  //const { group } = useSelector((state) => state.type);
  const [group, setGroup] = useState([]);

  const blank_src =
    "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";

  // Migrate to react-query
  const { isLoading, refetch } = useQuery({
    // This is a unique query key identifier, you can add a variable here to
    // refetch every time that variable changes
    queryKey: ["get-persona-type-detail"],
    // Query Function,  function to be invoked for fetching
    queryFn: () => fetchData({ url: "api/persona_type/" + params.id }),
    // Function to be excuted when the request is successful
    onSuccess: (response) => {
      setTypeDetail(response.data);
      // console.log(response.data);
    },
    // Function to be invoked when the request has failed.
    onError: (response) => {},
  });

  const handleUpload = () => {
    // setuploadImage(!uploadImage);
    document.getElementById("input_file").click();
  };

  const handleChangeImage = () => {
    const img = document.getElementById("img");
    const [file] = document.getElementById("input_file").files;
    if (file) {
      img.src = URL.createObjectURL(file);
    }
  };

  const hasValidateError = () => {
    let errors = {};
    let error_types = {};
    // if (!group) {
    //   errors["group_id"] = "Group is required";
    // }

    if (!typeDetail.persona_type_name) {
      // errors["persona_type_name"] = "Type Name is required";
      error_types["persona_type_name"] = "required";
    }

    if (!typeDetail.creator) {
      // errors["creator"] = "Creator is required";
      error_types["creator"] = "required";
    }

    if (!typeDetail.persona_type_no) {
      // errors["persona_type_no"] = "Type No is required";
      error_types["persona_type_no"] = "required";
    } else {
      if (isNaN(typeDetail.persona_type_no)) {
        errors["persona_type_no"] = "Type No must be number";
      }
    }

    setErrors(errors);
    setErrorTypes(error_types);
    console.log(errors);
    if (Object.keys(errors).length > 0 || Object.keys(error_types).length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        title={<Title />}
        topSpace={
          <TopSpace
            typeDetail={typeDetail}
            refetch={refetch}
            group={group}
            setErrors={setErrors}
            hasValidateError={hasValidateError}
          />
        }
      >
        {!isLoading && typeDetail && (
          <div className="text-white " style={{ marginLeft: "12px" }}>
            <div className="d-flex" style={{ marginTop: "10px" }}>
              <div className="w-50 d-flex" style={{ gap: "30px" }}>
                <div className="fs-20-px font-notosans-jp d-flex align-items-center">
                  所属グループ {typeDetail ? typeDetail.group_id : ""}
                </div>
                <div className="ms-2">
                  <div className="position-relative">
                    <TextInput
                      onClick={() => setChooseGroupModal(true)}
                      placeholder="グループ選択"
                      required
                      width="230px"
                      height="40px"
                      inputClass="w-87 fs-18"
                      value={group.name ? group.name : typeDetail.group_name}
                      // defaultValue={typeDetail.group_name}
                      // onChange={(e) =>
                      //   setTypeDetail((value) => ({
                      //     ...value,
                      //     group_id: selectedGroupId,
                      //   }))
                      // }
                    />
                    <div
                      onClick={() => setChooseGroupModal(true)}
                      className="dropdown-icon position-absolute top-50 translate-middle"
                      style={{ right: 15 }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="w-50 d-flex" style={{ gap: "30px" }}>
                <div className=" fs-20-px font-notosans-jp d-flex align-items-center ms-10-px">
                  タイプ名
                </div>
                <div className="">
                  <TextInput
                    errorMessage={
                      errors &&
                      errors.persona_type_name &&
                      errors.persona_type_name
                    }
                    isError={
                      errorTypes &&
                      errorTypes.persona_type_name &&
                      errorTypes.persona_type_name === "required" &&
                      true
                    }
                    width="230px"
                    height="40px"
                    value={typeDetail.persona_type_name}
                    inputClass="ms-2 fs-18-px"
                    required
                    onChange={(e) =>
                      setTypeDetail((value) => ({
                        ...value,
                        persona_type_name: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="d-flex mt-40-px">
              <div className="w-50">
                <div className="d-flex" style={{ width: "300.46px" }}>
                  <div
                    className="fs-20-px font-notosans-jp d-flex align-items-center"
                    style={{ width: "27%" }}
                  >
                    イメージ
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ width: "73%" }}
                  >
                    <Button
                      onClick={() => handleUpload()}
                      height="40px"
                      width="173px"
                      text="アップロード"
                    />
                    <input
                      onChange={() => handleChangeImage()}
                      type="file"
                      name=""
                      id="input_file"
                      hidden
                    ></input>
                  </div>
                </div>
                <div style={{ marginTop: "12px" }}>
                  <div
                    className={`image-box h-350-px`}
                    style={{ width: "300.46px", height: "350px" }}
                  >
                    <div className={`w-100 h-100 upload-image`}>
                      <img
                        id="img"
                        width="100%"
                        height="100%"
                        src={
                          typeDetail.image_uri !== ""
                            ? typeDetail.image_uri
                            : blank_src
                        }
                        alt="type"
                      />
                    </div>
                    {/* {uploadImage && (
                      <div
                        className={`w-100 h-100 ${
                          uploadImage ? "upload-image" : ""
                        }`}
                      >
                        <img
                          width="100%"
                          height="100%"
                          src={typeDetail.image_uri}
                          alt="type"
                        />
                      </div>
                    )} */}

                    <div className={`dark-bg `} />
                  </div>
                </div>
              </div>
              <div className="w-50 d-flex flex-column">
                {/* Type No */}
                <div className=" d-flex" style={{ gap: "30px" }}>
                  <div className=" h-45-px fs-20-px font-notosans-jp d-flex align-items-center ms-10-px">
                    タイプ番号
                  </div>
                  <div className="">
                    <TextInput
                      value={typeDetail.persona_type_no}
                      errorMessage={
                        errors &&
                        errors.persona_type_no &&
                        errors.persona_type_no
                      }
                      isError={
                        errorTypes &&
                        errorTypes.persona_type_no &&
                        errorTypes.persona_type_no === "required" &&
                        true
                      }
                      width="230px"
                      height="40px"
                      required
                      inputClass="ms-2 fs-18-px"
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setTypeDetail((value) => ({
                            ...value,
                            persona_type_no: e.target.value,
                          }));
                          setErrors((value) => ({
                            ...value,
                            persona_type_no: "",
                          }));
                        } else {
                          setErrorTypes({
                            persona_type_no: "required",
                          });
                          // if (errors && errors.persona_type_no) {
                          setErrors((value) => ({
                            ...value,
                            persona_type_no: "",
                          }));
                          // }
                        }
                      }}
                    />
                  </div>
                </div>
                {/* Creator */}
                <div
                  className="d-flex mt-35-px"
                  style={{ gap: "30px", marginLeft: "-10px" }}
                >
                  <div className="h-45-px fs-20-px font-notosans-jp d-flex align-items-center">
                    クリエター名
                  </div>
                  <div className="">
                    <TextInput
                      value={typeDetail.creator}
                      // errorMessage="既に使用されている番号です"
                      errorMessage={errors && errors.creator && errors.creator}
                      isError={
                        errorTypes &&
                        errorTypes.creator &&
                        errorTypes.creator === "required" &&
                        true
                      }
                      width="230px"
                      height="40px"
                      required
                      inputClass="ms-2 fs-18-px"
                      onChange={(e) => {
                        setTypeDetail((value) => ({
                          ...value,
                          creator: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Choose Group Modal */}
            <ChooseGroupModal
              isChooseGroup={true}
              isOpen={chooseGroupModal}
              setGroup={setGroup}
              onClick={() => {
                setChooseGroupModal(false);
              }}
              onchangetype={() => {}}
              onClose={() => setChooseGroupModal(false)}
            />
            {/* End Choose Group Modal */}
          </div>
        )}
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
const Title = () => {
  return <div className="ms-2 mt-5-px">タイプ設定</div>;
};

/** Top Space */
const TopSpace = (props) => {
  const { typeDetail, refetch, setErrors, hasValidateError,group } = props;
  //const { group } = useSelector((state) => state.type);
  /** react */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setUnexpectedErrorModal } = useContext(AppContext);

  /** States */
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateInfoModal, setUpdateInfoModal] = useState(false);

  const [publishConfirmModal, setPublishConfirmModal] = useState(false);
  const [publishInfoModal, setPublishInfoModal] = useState(false);

  /** Functions */
  const handleUpdate = async (id, ispublic) => {
    // upload Image and get url
    let validate_check = hasValidateError();
    if (validate_check) {
      setUpdateConfirmModal(false);
      setPublishConfirmModal(false);
      return;
    }
    let formData = new FormData();
    let img_url = typeDetail.image_uri;
    let hash = "";
    let hasUploadError = false;
    const [img_file] = document.getElementById("input_file").files;
    if (img_file) {
      formData.append("file", img_file);
      formData.append("type", "ipfs");
      await fetchFormData({
        url: "api/upload",
        data: formData,
      })
        .then((res) => {
          img_url = res.data.url;
          hash = res.data.ipfs.Hash;
        })
        .catch(() => {
          setUpdateConfirmModal(false);
          setUnexpectedErrorModal(true);
          hasUploadError = true;
        });
    }

    if (hasUploadError) {
      return;
    }

    // end upload image
    // console.log("Check Type ===========");
    // console.log(
    //   group && Object.keys(group).length > 0 ? group : typeDetail.group_id
    // );
    // console.log("End Check Type ===========");

    let updateObject = {
      group_id:
        group && Object.keys(group).length > 0 ? group.id : typeDetail.group_id,
      persona_type_no: typeDetail.persona_type_no,
      persona_type_name: typeDetail.persona_type_name,
      creator: typeDetail.creator,
      image_uri: img_url,
      hash,
    };
    // console.log("Check Update Object ===========");
    console.log(group.id);
    // console.log("End Check Update Object ===========");
    fetchData({
      url: "api/persona_type/" + typeDetail.id,
      method: "put",
      data: updateObject,
    })
      .then((res) => {
        setUpdateConfirmModal(false);
        if (!ispublic) {
          setUpdateInfoModal(true);
        } else {
          setPublishConfirmModal(false);
          setPublishInfoModal(true);
        }
        //setUpdateInfoModal(true);
        setErrors(null);
        refetch();
      })
      .catch((e) => {
        console.log(e);
        setUpdateConfirmModal(false);
        setPublishConfirmModal(false);
        if (e.response.data.error) {
          setErrors(e.response.data.errors);
        } else {
          setUnexpectedErrorModal(true);
        }
      });
  };
  const handlePublish = (id) => {
    handleUpdate(null, true);
    //setPublishConfirmModal(false);
    //setPublishInfoModal(true);
    dispatch(setTypeId(typeDetail.id));
    dispatch(
      setGroupId(
        group && Object.keys(group).length > 0 ? group.id : typeDetail.group_id
      )
    );
  };

  const redirectPublish = () => {
    setPublishInfoModal(false);
    //dispatch(setTypeGroup(""));
    navigate("/publish");
  };

  const redirectList = () => {
    setUpdateInfoModal(false);
    //dispatch(setTypeGroup(""));
    navigate("/types");
  };

  // const checkValidate = () => {
  //   let validate_check = hasValidateError();
  //   console.log(validate_check);

  //   if (!validate_check) {
  //     setUpdateConfirmModal(true);
  //   }
  // };

  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-end h-100 gap-4">
      {/* Token Update Button */}
      <Button
        onClick={() => setUpdateConfirmModal(true)}
        text="保存"
        width="173px"
        // height="40px"
      />
      <Button
        onClick={() => setPublishConfirmModal(true)}
        text="発行"
        width="173px"
        // height="40px"
      />

      {/*Update Modals */}
      <ConfirmModal
        isOpen={updateConfirmModal}
        onConfirm={() => handleUpdate()}
        onCancel={() => setUpdateConfirmModal(false)}
      >
        変更項目を保存しますか？
      </ConfirmModal>
      <InfoModal isOpen={updateInfoModal} onAction={() => redirectList()}>
        変更項目が正常に保存されました。
      </InfoModal>
      {/* End Update Modals */}

      {/*Publish Modals */}
      <ConfirmModal
        isOpen={publishConfirmModal}
        onConfirm={() => handlePublish()}
        onCancel={() => setPublishConfirmModal(false)}
      >
        一部の変更は保存されていません。
        <br /> 変更を保存しますか？
      </ConfirmModal>
      <InfoModal isOpen={publishInfoModal} onAction={() => redirectPublish()}>
        変更項目が正常に保存されました。
      </InfoModal>
      {/* End Publish Modals */}
    </div>
  );
};

export default GroupSetting;
