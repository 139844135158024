import React from "react";

const MessageBox = (props) => {
  const { width, height, className, children, isChooseGroup, boxClass } = props;
  return (
    <div
      className={`${boxClass ? boxClass : "message-box"} ${className}`}
      style={{ width: width, height: height }}
    >
      {isChooseGroup ? (
        <div>
          <div className="middle-choose-group-right-box " />
          <div className="middle-choose-group-left-box " />
        </div>
      ) : (
        <div className="middle-box"></div>
      )}

      <div className="outside-box-top"></div>
      <div className="outside-box-bottom"></div>

      {/* <div className="inside-box"> */}
      {/* <div className="h-100"> */}
      {/* <div className=" h-100"> */}
      <div className="container">{children}</div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}

      {/* Side path */}
      <div
        className="position-absolute h-100"
        style={isChooseGroup ? { top: "51%" } : { top: "45%" }}
      >
        {/* <img src={PathLd} alt="Path Ld" /> */}
        <div
          className="side-path"
          style={isChooseGroup ? { height: "36%" } : {}}
        />
      </div>
      <div
        className="position-absolute h-100"
        style={
          isChooseGroup ? { top: "51%", right: 0 } : { top: "45%", right: 0 }
        }
      >
        <div
          className="side-path"
          style={
            isChooseGroup
              ? { height: "36%", transform: "scaleX(-1)" }
              : { transform: "scaleX(-1)" }
          }
        />
        {/* <img src={PathRd} alt="Path Rd" /> */}
      </div>
    </div>
  );
};

export default MessageBox;
