import React from "react";

export const BottomLessAbyssIcon = ({
  width = 25.838,
  height = 25.531,
  fill = "#fff",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25.838 25.531"
    >
      <g id="Group_313" data-name="Group 313" transform="translate(0 0)">
        <path
          id="Path_4845"
          data-name="Path 4845"
          d="M33,172.841a2.979,2.979,0,1,0-2.968-2.979A2.973,2.973,0,0,0,33,172.841Z"
          transform="translate(-20.076 -157.107)"
          fill={fill}
          stroke="#010101"
          strokeMiterlimit="10"
          strokeWidth="0.21"
        />
        <path
          id="Path_4846"
          data-name="Path 4846"
          d="M33.282,173.931a3.782,3.782,0,1,0-3.767-3.782A3.775,3.775,0,0,0,33.282,173.931Z"
          transform="translate(-20.352 -157.385)"
          fill={fill}
          stroke="#010101"
          strokeMiterlimit="10"
          strokeWidth="0.21"
        />
        <path
          id="Path_4847"
          data-name="Path 4847"
          d="M32.642,171.479a1.966,1.966,0,1,0-1.958-1.966A1.962,1.962,0,0,0,32.642,171.479Z"
          transform="translate(-19.723 -156.75)"
          fill={fill}
          stroke="#010101"
          strokeMiterlimit="10"
          strokeWidth="0.21"
        />
        <path
          id="Path_4848"
          data-name="Path 4848"
          d="M34.578,181.7c5.114,2.916,12.462.072,14.817-6.869,0,0-2.569,7.04-9.053,7.546-6.327.494-8.056-4.275-8.007-5.357a6.873,6.873,0,0,0,7.507,4.225c5.846-.66,10.125-7.3,7.964-14.3,0,0,2.043,7.212-2.907,11.447s-9.227,1.149-9.654.328L35.2,178.7c.048.012.1.025.145.034l-.075.008a6.862,6.862,0,0,0,8.6-.974c4.344-3.984,3.919-11.879-1.929-16.269,0,0,5.875,4.627,4.351,10.976s-6.793,6.376-7.619,5.962l-.1.009c.024-.009.046-.017.07-.028a6.881,6.881,0,0,0,6.351-5.86c1.183-5.785-3.784-11.922-11.088-12.025,0,0,7.464.28,9.946,6.313,2.427,5.9-1.57,9.021-2.606,9.3.036-.062.072-.125.109-.188a6.92,6.92,0,0,0,1.574-8.38c-2.429-5.379-10.041-7.414-16.01-3.186,0,0,6.2-4.179,11.743-.763,5.4,3.327,4.018,8.194,3.344,9.054a.4.4,0,0,0-.007-.054,6.913,6.913,0,0,0-3.624-7.748c-5.114-2.916-12.463-.072-14.817,6.871,0,0,2.569-7.04,9.053-7.546,6.324-.5,8.053,4.268,8.007,5.357,0,0-.01-.012-.014-.017a6.876,6.876,0,0,0-7.512-4.242c-5.846.658-10.125,7.3-7.964,14.3,0,0-2.043-7.211,2.907-11.447s9.227-1.149,9.654-.328l.017.011-.063-.015h.041a6.856,6.856,0,0,0-8.6.972c-4.344,3.984-3.919,11.879,1.929,16.269,0,0-5.875-4.628-4.351-10.976s6.793-6.376,7.618-5.962l.094-.009c-.041.017-.082.034-.121.054a6.883,6.883,0,0,0-6.324,5.862c-1.183,5.784,3.784,11.922,11.088,12.024,0,0-7.464-.278-9.946-6.313-2.453-5.963,1.664-9.091,2.639-9.312l-.044.075a6.922,6.922,0,0,0-1.685,8.472c2.429,5.381,10.04,7.414,16.01,3.186,0,0-6.2,4.179-11.743.764s-3.941-8.457-3.291-9.115v0c0,.031.007.06.012.091a6.918,6.918,0,0,0,3.61,7.8Zm-2.241-4.727v0a.026.026,0,0,1,.007.009Zm4.146-7.971a4.267,4.267,0,1,1-4.25,4.267A4.263,4.263,0,0,1,36.484,169.006Z"
          transform="translate(-23.558 -160.528)"
          fill={fill}
        />
      </g>
    </svg>
  );
};
