import React, { createContext, useMemo } from "react";

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
  // We can added more modals states in the future. Feel free to edit this
  const [state, setState] = React.useState({
    group: "",
  });

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state, setState]
  );
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
