import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { Button } from "../components/Button";
import { SearchInput } from "../components/Form";
import { ConfirmModal, InfoModal } from "../components/Modal";
import Pagination from "../components/common/Pagination";
import { fetchData } from "../services/fetch";
import { DeleteIcon } from "../components/common/Icon";
import { useQuery } from "react-query";
import { storeErrorModal } from "../services/storage";
import GridTableHeader from "../components/common/table/GridTableHeader";
import { useDispatch } from "react-redux";
import { setTypeId } from "../slices/publish";
import { strings, translations } from "../services/localization";

/** Content Body */
const Group = () => {
  const navigate = useNavigate();

  const translation = translations;
  const content = translations.type;
  const button = translations.button;
  /** States */
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [types, setTypes] = useState([]);
  const [paginatedata, setPaginatedata] = useState([]);
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  // Migrate to react-query
  const { isLoading, refetch } = useQuery({
    // This is a unique query key identifier, you can add a variable here to
    // refetch every time that variable changes
    queryKey: ["get-persona-type"],
    // Query Function,  function to be invoked for fetching
    queryFn: () =>
      fetchData({
        url: `api/persona_type?${filter ? "filter=" + filter : ""}`,
      }),
    // Function to be excuted when the request is successful
    onSuccess: (response) => {
      paginatedata.total_count = response.data.total_count;
      paginatedata.page_count = response.data.page_count;
      if (response.data.current_page * 10 > response.data.total_count) {
        paginatedata.current_page_amount = response.data.total_count;
      } else {
        paginatedata.current_page_amount = response.data.current_page * 10;
      }
      paginatedata.current_page = response.data.current_page;
      setTypes(response.data.persona_types);
    },
    // Function to be invoked when the request has failed.
    onError: (response) => {},
  });

  const enterSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      refetch();
    }
  };

  const handlepagenateChange = (paginateData) => {
    fetchData({
      url: filter
        ? "api/persona_type?page=" +
          paginateData.current_page +
          "&filter=" +
          filter
        : "api/persona_type?page=" + paginateData.current_page,
      //url: "api/persona_type?page=" + paginateData.current_page,
      method: "get",
    })
      .then((response) => {
        paginatedata.total_count = response.data.total_count;
        paginatedata.page_count = response.data.page_count;
        if (response.data.current_page * 10 > response.data.total_count) {
          paginatedata.current_page_amount = response.data.total_count;
        } else {
          paginatedata.current_page_amount = response.data.current_page * 10;
        }
        paginatedata.current_page = response.data.current_page;
        setTypes(response.data.persona_types);
      })
      .catch((error) => {
        console.log(error);
        storeErrorModal(error.message); //remove error dialog
      });
  };

  /** Functions */
  const handlePublishClick = (id) => {
    navigate(`/publish`);
    dispatch(setTypeId(id));
  };

  const handleSettingClick = (id) => {
    navigate(`/types/${id}`);
  };

  const handleDelete = () => {
    // alert("action delete");
    fetchData({ url: "api/persona_type/" + deleteId, method: "delete" })
      .then(() => {
        setDeleteConfirmModal(false);
        setDeleteInfoModal(true);
        refetch();
      })
      .catch((error) => {
        console.log(error);
        storeErrorModal(error.message); //remove error dialog
      });
  };
  const tableHeaderItem = [
    {
      headerName: strings.formatString(content.typename),
      width: "132px",
    },
    {
      headerName: strings.formatString(content.typenumber),
      width: "160px",
      className: "pe-2",
    },
    {
      headerName: strings.formatString(content.groupname),
      width: "200px",
    },
    {
      headerName: strings.formatString(content.createddate),
      width: "135px",
      className: "ps-3",
    },
    {
      headerName: strings.formatString(content.numberofpersonas),
      width: "152px",
      className: "ps-4",
    },
    {
      headerName: strings.formatString(content.action),
      width: "160px",
      className: "ps-2",
    },
  ];

  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        // title={<Title />
        titletopmarign="true"
        title={strings.formatString(content.title)}
        topSpace={
          <TopSpace
            enterSearch={enterSearch}
            placeholdersearch={strings.formatString(content.placeholdersearch)}
            setFilter={setFilter}
            create={strings.formatString(button.create)}
          />
        }
        bottomSpace={
          <Pagination
            onChange={handlepagenateChange}
            paginate_data={paginatedata}
          />
        }
        noOverFlow="ture"
        smallMarginRight="true"
        marginLeft="12px"
      >
        <div className="row h-100" style={{ marginTop: "0.5%" }}>
          <div className="table-responsive  hide-scroll gird-table">
            <table className="w-100">
              <GridTableHeader tableHeaderItem={tableHeaderItem} />
              <tbody
                className="text-white body-font overflow-auto hide-scroll h-80 text-center"
                style={{}}
              >
                <tr>
                  <td colSpan="6" style={{ height: 12 }}></td>
                </tr>
                {isLoading ? (
                  // TODO: Implement a loading spinner
                  <></>
                ) : (
                  types.map((type, i) => (
                    <tr className="grid-table-body" key={i}>
                      <td className="col" style={{ width: 132 }}>
                        {type.persona_type_name}
                      </td>
                      <td className="col" style={{ width: 160 }}>
                        {type.persona_type_no}
                      </td>
                      <td className="col" style={{ width: 200 }}>
                        {type.group_name}
                      </td>
                      <td className="col ps-3" style={{ width: 135 }}>
                        {type.create_date.split("T")[0]}
                      </td>
                      <td className="col ps-4" style={{ width: 152 }}>
                        {type.threshold}
                      </td>
                      <td
                        className="col ps-2  align-items-center justify-content-center "
                        style={{ width: 160 }}
                      >
                        <div className=" d-flex">
                          <div
                            onClick={() => handlePublishClick(type.id)}
                            className="publish-icon mt-20-px"
                            style={{ width: 32, marginLeft: "30px" }}
                          >
                            <div className="publish-up-arrow publish-up-arrow1" />
                            <div className="publish-up-arrow publish-up-arrow2" />
                          </div>
                          <div
                            onClick={() => handleSettingClick(type.id)}
                            className="edit-icon"
                            style={{ width: 40, marginLeft: "18px" }}
                          ></div>
                          <div
                            onClick={() => {
                              setDeleteConfirmModal(true);
                              setDeleteId(type.id);
                              setDeleteName(type.persona_type_name);
                            }}
                            className="delete-icon"
                            style={{ width: 45 }}
                          >
                            <DeleteIcon
                              style={{ marginLeft: "10px" }}
                            ></DeleteIcon>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {/*Delete Modals */}
          <ConfirmModal
            isOpen={deleteConfirmModal}
            onConfirm={() => handleDelete()}
            onCancel={() => setDeleteConfirmModal(false)}
          >
            {deleteName}タイプを削除しますか？
          </ConfirmModal>
          <InfoModal
            isOpen={deleteInfoModal}
            onAction={() => setDeleteInfoModal(false)}
          >
            {deleteName}グループが正常に削除されました。
          </InfoModal>
          {/* End Delete Modals */}
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
// const Title = () => {
//   return <div className="d-flex">{/* Title Content */}</div>;
// };

/** Top Space */
const TopSpace = (props) => {
  const navigate = useNavigate();
  const { setFilter, enterSearch, placeholdersearch, create } = props;
  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-between h-100">
      {/* Search Box */}
      {/* <form className="text-white px-2 pb-5-px d-flex justify-content-between h-100"> */}
      <div onKeyDown={enterSearch}>
        <SearchInput
          placeholder={placeholdersearch}
          // className="w-600-px"
          width="551.52px"
          height="100%"
          onChange={(e) => setFilter(e)}
          required
        />
      </div>
      {/* </form> */}
      {/* Create Button */}
      <Button
        text={create}
        width="173px"
        onClick={() => navigate("/types/create")}
      />
    </div>
  );
};

export default Group;
