import React from "react";

export const FORIcon = ({
  width = 15.026,
  height = 15.072,
  fill = "#fdfffe",
  ...rest
}) => {
  return (
    <svg
      id="FOR"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15.026 15.072"
    >
      <path
        id="Path_12937"
        data-name="Path 12937"
        d="M87.342,0a4.333,4.333,0,0,1-1.433,3.044c-.6.639-1.237,1.253-1.8,1.925a7.269,7.269,0,0,0-.651,1.162.446.446,0,0,1-.181.2,8.37,8.37,0,0,0-2.817,1.958,10.352,10.352,0,0,0-1.765,2.245L76.844,8.67c.45-.332.945-.658,1.395-1.037a9.384,9.384,0,0,0,2.554-3.1,1.546,1.546,0,0,1,.858-.829A7.09,7.09,0,0,0,83.682,2.05,7.5,7.5,0,0,1,85.514.511,13.777,13.777,0,0,1,86.814,0Z"
        transform="translate(-72.316)"
        fill={fill}
      />
      <path
        id="Path_12938"
        data-name="Path 12938"
        d="M0,109.657a4.321,4.321,0,0,0,.774.063.916.916,0,0,0,.509-.228c.479-.446.934-.918,1.4-1.383a1.207,1.207,0,0,0,.007-1.777,2.5,2.5,0,0,0-2.554-.63,1.609,1.609,0,0,1,.032-.273,4.408,4.408,0,0,1,1.958-2.435c.2-.13.334-.071.382.159A6.378,6.378,0,0,0,4.5,106.529c.406.381.79.786,1.184,1.18a6.168,6.168,0,0,0,2.95,1.657c.379.092.419.171.207.485a4.447,4.447,0,0,1-2.225,1.826,4.518,4.518,0,0,1-.447.1,2.5,2.5,0,0,0-.3-2.21,1.329,1.329,0,0,0-1.247-.733,1.379,1.379,0,0,0-.759.308c-.533.473-1.022,1-1.522,1.5a.99.99,0,0,0-.152.94c.013.081.041.16.089.345-.354-.083-.629-.154-.907-.21a3.357,3.357,0,0,0-.41-.037A.212.212,0,0,0,.9,111.7c-.636.139-.876-.073-.7-.7.1-.374-.131-.626-.2-.936Z"
        transform="translate(0 -96.862)"
        fill={fill}
      />
    </svg>
  );
};
