import React from "react";
export const DropOfHeavenIcon = ({
  width = 25.884,
  height = 25.837,
  fill = "#fff",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25.884 25.837"
    >
      <g id="Group_311" data-name="Group 311" transform="translate(0)">
        <path
          id="Path_4827"
          data-name="Path 4827"
          d="M30.187,5.149A6.76,6.76,0,0,0,28.451,14.5a5.37,5.37,0,0,1-2.179-5.959S26.84,6.2,30.187,5.149Z"
          transform="translate(-20.611 -5.149)"
          fill={fill}
        />
        <path
          id="Path_4828"
          data-name="Path 4828"
          d="M26.37,6.186a7,7,0,0,0,.115,6.113S22.659,9.392,26.37,6.186Z"
          transform="translate(-21.303 -4.593)"
          fill={fill}
        />
        <path
          id="Path_4829"
          data-name="Path 4829"
          d="M23.817,7.4s0,3.641,4.263,5.9,4.972,4.517,4.972,4.517,1.3,2.757-.5,4.444a1.7,1.7,0,0,1-1.432.449c-.409-.069-.8-.277-.729-.84.151-1.215,1.831-.524,1.831-.524s.821-1.037-.072-3.025c-.985-2.193-4.844-4.052-4.844-4.052S22.8,11.917,23.814,7.393Z"
          transform="translate(-21.886 -3.942)"
          fill={fill}
        />
        <path
          id="Path_4830"
          data-name="Path 4830"
          d="M23.024,8.326a8.961,8.961,0,0,0,3.941,6.044A37.346,37.346,0,0,0,31.651,17.3s-1.65.6-5.056-1.882S22.7,9.969,23.024,8.326Z"
          transform="translate(-22.278 -3.44)"
          fill={fill}
        />
        <path
          id="Path_4831"
          data-name="Path 4831"
          d="M31.882,17.139a10.938,10.938,0,0,1-9.373-6.853A7.386,7.386,0,0,0,25.422,15.9a7.063,7.063,0,0,0,6.46,1.232Z"
          transform="translate(-22.509 -2.385)"
          fill={fill}
        />
        <path
          id="Path_4832"
          data-name="Path 4832"
          d="M22.62,13.024s3.55,5.171,8.918,3.618c0,0-5.522,3.494-8.961-3.53"
          transform="translate(-22.472 -0.911)"
          fill={fill}
        />
        <path
          id="Path_4833"
          data-name="Path 4833"
          d="M29.91,16.339a7.517,7.517,0,0,1-5.729-.424S28.1,19.022,29.91,16.339Z"
          transform="translate(-21.609 0.644)"
          fill={fill}
        />
        <path
          id="Path_4834"
          data-name="Path 4834"
          d="M28.491,17s.238.644.66.791c0,0-1.905.588-2.673-.239C26.478,17.555,28.033,17.573,28.491,17Z"
          transform="translate(-20.373 1.229)"
          fill={fill}
        />
        <path
          id="Path_4835"
          data-name="Path 4835"
          d="M33.109,5.149A6.76,6.76,0,0,1,34.846,14.5a5.371,5.371,0,0,0,2.179-5.959S36.457,6.2,33.109,5.149Z"
          transform="translate(-16.804 -5.149)"
          fill={fill}
        />
        <path
          id="Path_4836"
          data-name="Path 4836"
          d="M36.081,6.186a7,7,0,0,1-.115,6.113S39.792,9.392,36.081,6.186Z"
          transform="translate(-15.267 -4.593)"
          fill={fill}
        />
        <path
          id="Path_4837"
          data-name="Path 4837"
          d="M41.472,7.4s0,3.641-4.263,5.9-4.972,4.517-4.972,4.517-1.3,2.757.5,4.444a1.7,1.7,0,0,0,1.432.449c.409-.069.8-.277.729-.84-.151-1.215-1.831-.524-1.831-.524s-.821-1.037.072-3.025c.985-2.193,4.844-4.052,4.844-4.052s4.509-2.344,3.494-6.868Z"
          transform="translate(-17.519 -3.942)"
          fill={fill}
        />
        <path
          id="Path_4838"
          data-name="Path 4838"
          d="M41.869,8.326a8.883,8.883,0,0,1-3.941,5.947C34.448,16.65,33.243,17.3,33.243,17.3s1.65.6,5.056-1.882S42.2,9.969,41.869,8.326Z"
          transform="translate(-16.732 -3.44)"
          fill={fill}
        />
        <path
          id="Path_4839"
          data-name="Path 4839"
          d="M33.243,17.139a10.938,10.938,0,0,0,9.373-6.853A7.386,7.386,0,0,1,39.7,15.9a7.063,7.063,0,0,1-6.46,1.232Z"
          transform="translate(-16.732 -2.385)"
          fill={fill}
        />
        <path
          id="Path_4840"
          data-name="Path 4840"
          d="M42.359,13.024s-3.55,5.171-8.918,3.618C33.441,16.642,39.054,20.137,42.359,13.024Z"
          transform="translate(-16.626 -0.911)"
          fill={fill}
        />
        <path
          id="Path_4841"
          data-name="Path 4841"
          d="M33.94,16.339a7.517,7.517,0,0,0,5.729-.424S35.751,19.022,33.94,16.339Z"
          transform="translate(-16.357 0.644)"
          fill={fill}
        />
        <path
          id="Path_4842"
          data-name="Path 4842"
          d="M34.29,17s-.238.644-.66.791c0,0,1.905.588,2.673-.239C36.3,17.555,34.748,17.573,34.29,17Z"
          transform="translate(-16.524 1.229)"
          fill={fill}
        />
        <path
          id="Path_4843"
          data-name="Path 4843"
          d="M29.108,23.4c.21.781,1.35.4,1.023-.282-.8-1.664.593-4.029,1.261-5.592-.941,1.63-2.811,3.951-2.284,5.877m1.425.747a.46.46,0,0,0-.614,0,.379.379,0,0,0,0,.565.46.46,0,0,0,.614,0A.379.379,0,0,0,30.533,24.145Zm1.639,1.82a3.435,3.435,0,0,0,3.57-3.281c0-2.622-3.171-5.364-3.57-8.2-.4,2.84-3.57,5.582-3.57,8.2A3.435,3.435,0,0,0,32.172,25.965Z"
          transform="translate(-19.23 -0.128)"
          fill={fill}
          fillRule="evenodd"
        />
        <path
          id="Path_4844"
          data-name="Path 4844"
          d="M29.841,11.948c.125.467.806.236.611-.17-.478-.994.355-2.4.752-3.338C30.641,9.413,29.526,10.8,29.841,11.948Zm.849.447a.276.276,0,0,0-.366,0,.225.225,0,0,0,0,.336.276.276,0,0,0,.366,0A.223.223,0,0,0,30.69,12.394Zm.979,1.086a2.049,2.049,0,0,0,2.13-1.959c0-1.564-1.893-3.2-2.13-4.9-.238,1.695-2.131,3.33-2.131,4.9A2.051,2.051,0,0,0,31.669,13.48Z"
          transform="translate(-18.726 -4.356)"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
