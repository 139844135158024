import React from "react";

const ContentWrapper = (props) => {
  const {
    children,
    title,
    topSpace,
    bottomSpace,
    className,
    smallMarginRight,
    noOverFlow,
    titletopmarign,
    marginLeft,
    setheighttitle,
    setpaddingtop,
  } = props;
  let contentBorderClass = bottomSpace
    ? "content-border"
    : "content-border-none-bt-space";

  let bottomPadding = bottomSpace ? "pb-30-px" : "pb-0-px";
  return (
    <>
      <div
        className={`${className ? className : "content"} position-relative`}
        style={{ marginLeft: "1%" }}
      >
        <div
          className="position-absolute top-0 left-0 w-100 content-bg-darker"
          style={{ height: "605px", zIndex: -1 }}
        ></div>
        <div className="position-absolute top-0 left-0 w-100">
          <div className={`d-flex ${setheighttitle ? "h-70-px" : ""}`}>
            {/* Title */}
            <div
              className={`title ps-4 ${
                titletopmarign ? "pt-3" : "pt-2"
              } h-50-px ml-5`}
              style={{ marginLeft: `${marginLeft}` }}
            >
              <p className="text-primary">{title}</p>
            </div>

            {/* Top Space */}
            <div className={"top-space"}>{topSpace}</div>
          </div>

          {/* Content Body */}
          <div
            className={`mt-${setpaddingtop ? setpaddingtop : 20}-px mb-${
              setpaddingtop ? setpaddingtop : 45
            }-px ${smallMarginRight ? "ps-4 pe-0" : "px-2-px"} body ${
              noOverFlow ? "" : "overflow-auto"
            } hide-scroll`}
            style={{
              height: "502px",
            }}
          >
            {children}
          </div>

          {/* Bottom Space */}
          {bottomSpace && (
            <div
              className="position-absolute w-100 d-flex justify-content-center bottom"
              style={{ bottom: "5px" }}
            >
              <div className="space-width font-ethno-light">{bottomSpace}</div>
            </div>
          )}

          {/* Content Border */}
          <div
            className={`position-absolute top-0 left-0 ${contentBorderClass}`}
          ></div>

          {/* Margin Bottom */}
          <div className="position-absolute w-100 h-20-px" />
        </div>
      </div>
    </>
  );
};

export default ContentWrapper;
