import React from "react";

export const PlusIcon = ({
  width = 24.264,
  height = 24.198,
  fill = "#dcc97e",
  className = "",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.264"
      height="24.198"
      viewBox="0 0 24.264 24.198"
    >
      <g id="_btn" data-name="+btn" transform="translate(0.7 0.701)">
        <g id="Group_42122" data-name="Group 42122" transform="translate(0 0)">
          <g
            id="Group_42120"
            data-name="Group 42120"
            transform="translate(9.701 0.576)"
          >
            <path
              id="Path_4779"
              data-name="Path 4779"
              d="M4992.207,7162.965v21.616l1.4-.95v-19.759Z"
              transform="translate(-4990.694 -7162.965)"
              fill="#dcc97e"
            />
            <path
              id="Path_4780"
              data-name="Path 4780"
              d="M4993.607,7162.965v21.616l-1.4-.95v-19.759Z"
              transform="translate(-4992.207 -7162.965)"
              fill="#dcc97e"
            />
          </g>
          <g
            id="Group_42121"
            data-name="Group 42121"
            transform="translate(22.242 9.652) rotate(90)"
          >
            <path
              id="Path_4781"
              data-name="Path 4781"
              d="M0,0V21.617l1.4-.951V.907Z"
              transform="translate(1.513 0)"
              fill="#dcc97e"
            />
            <path
              id="Path_4782"
              data-name="Path 4782"
              d="M1.4,0V21.617L0,20.666V.907Z"
              transform="translate(0 0)"
              fill="#dcc97e"
            />
          </g>
          <path
            id="Path_37470"
            data-name="Path 37470"
            d="M5003.444,7156.828l11.686,11.229-11.7,11.556-11.15-11.556Z"
            transform="translate(-4992.281 -7156.828)"
            fill="none"
            stroke="#dcc97e"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};
